import React from 'react';
import contextProvider from '../../contextProvider';
import Scrollspy from 'react-scrollspy';
import { NavLink, Redirect } from 'react-router-dom';

import deepEqual from 'deep-equal';
import jump from 'jump.js';

import _ReactOBaseComponent, { withDefinedContext } from '../_ReactOBaseComponent';
import { LoadingSpin } from '../../_L0_components/Widgets/_LoadingKit';

const context_exposed_methods = [
];

class _NavigationMenu extends _ReactOBaseComponent {
	constructor(props) {
		super(props);
		this.state = {
			_REDIR: null
		}
		this._JUMP_TOUT = null;
		this.lastHash = '';
		this._forceScroll = null;
		this._actionShelf = null;
		
		this.trigger = this.trigger.bind(this);
	}
	
	trigger(link) {
		this._setForceScroll('#' + link.split('#')[1]);
		this.setState({_REDIR: link})
	}
	
	onComponentDidMount() {
	//	console.log("DEBUG :: _NavigationMenu -> onComponentDidMount" + (this.props.sidebar ? ' SIDEBAR' : ''));
		this._actionShelf = this.getAppContext().getActionsShelfService().createNewShelf('navigationMenu');
		this._actionShelf.registerAction({
			key: 'triggerNavMenuItem',
			call: this.trigger
		});
	}
	
	onComponentWillUnmount() {
	//	console.log("DEBUG :: _NavigationMenu -> onComponentWillUnmount" + (this.props.sidebar ? ' SIDEBAR' : ''));
		if (this._actionShelf) {
			this._actionShelf.unregister();
		}
	}
	
	_setForceScroll(hash) {
		this._forceScroll = hash;
	}
	
	_resetRedir() {
		this.setState({_REDIR: null});
	}
	
	_getRouteMatchParam(key) {
		return this.getAppContext().activeRouteData.match.params[key];
	}
	
	getEntry(data, entry_path) {
		var BreakException = {};
		let e_path_array = entry_path.split('.');
		let _TMP = data;
		let ret = true;
		try {
			e_path_array.forEach((entry, idx) => {
				if (_TMP[entry] === undefined) {
					throw BreakException;
				}
				_TMP = _TMP[entry];
				ret = _TMP;
			});
		} catch(e) {
			if (e !== BreakException) throw e;
			ret = undefined;
		}
		return ret;
	}
	
	render() {
		let menu_data = this.props.menu;
		if (!menu_data) {return null;}
		
		let sidebar_title = "";
		let _processTitle = () => {
			if (this.state._ADV_TITLE && !this._adv_title_preload_triggered) {
				this._adv_title_preload_triggered = true;
				this.setState((prev_state) => {
					prev_state._ADV_TITLE.loading = true;
					prev_state._ADV_TITLE.triggered = true;
					return prev_state;
				}, () => {
					let pre_load_data = this.state._ADV_TITLE.definition.preload;
					this.getAppContext().getApiStoreService().fire(typeof pre_load_data == 'object' ? pre_load_data.apiId : pre_load_data, {
						...(pre_load_data.params ? pre_load_data.params : {}),
						___onSuccess: (status, data) => {
							if (deepEqual(((this.state._ADV_TITLE && this.state._ADV_TITLE.definition) || {}), menu_data.title)) {
								this.setState((prev_state) => {
									prev_state._ADV_TITLE.loaded_data = data;
									prev_state._ADV_TITLE.loading = false;
									prev_state._ADV_TITLE.loaded = true;
									return prev_state;
								});
							}
						},
						___onError: (status, response) => {
							console.error(" :: _ReactOApp _ render [ERROR LOADING SIDEBAR PRE LOAD]");
						}
					})
				});
			}
			
			if (menu_data.title && typeof menu_data.title == 'object') {
				sidebar_title = !this.state._ADV_TITLE || !this.state._ADV_TITLE.definition ? (<div className="d-flex justify-content-center"><LoadingSpin loadingPlug={true} className="d-flex align-items-center" bounceSpinner/></div>) : (() => {
					return this.state._ADV_TITLE.definition.output(this.state._ADV_TITLE.loaded_data, !this.state._ADV_TITLE.loaded, this.getAppContext().activeRouteData);
				})()
				
				/*sidebar_title = !this.state._ADV_TITLE || !this.state._ADV_TITLE.definition || !this.state._ADV_TITLE.loaded ? (<div className="d-flex justify-content-center"><LoadingSpin loadingPlug={true} className="d-flex align-items-center" bounceSpinner/></div>) : (() => {
					let _TMP_COMPILED = this.state._ADV_TITLE.definition.output;
					let re = RegExp('___LOAD_\-(.[^\-]*)\-___', 'g');
					let match = true;
					while (match) {
						match = re.exec(_TMP_COMPILED);
						if (match) {
							let value = this.getEntry(this.state._ADV_TITLE.loaded_data, match[1]);
							if (value) {
								_TMP_COMPILED = _TMP_COMPILED.replace(new RegExp(match[0], 'g'), value);
							} else {
								_TMP_COMPILED = _TMP_COMPILED.replace(new RegExp(match[0], 'g'), '');
								console.error(" :: Sidebar _ render [MISSING MATCHING ROUTE PARAM WHILE FORMATTING PATH] -- needed: ");
								console.error(match[1]);
							}
						}
					}
					return _TMP_COMPILED;
				})()*/
			} else {
				sidebar_title = menu_data.title;
			}
		}
		
		if (menu_data.title && typeof menu_data.title == 'object') {
			if (!deepEqual((this.state._ADV_TITLE && this.state._ADV_TITLE.definition ? this.state._ADV_TITLE.definition : {}), menu_data.title)) {
				this.setState((prev_state) => {
					this._adv_title_preload_triggered = false;
					prev_state._ADV_TITLE = {
						definition: menu_data.title,
						loaded: false,
						loading: false,
						triggered: false
					};
					return prev_state;
				}, _processTitle);
			} else {
				_processTitle();
			}
		} else {
			if (this.state._ADV_TITLE !== undefined) {
				this.setState((prev_state) => {
					prev_state._ADV_TITLE = undefined;
					return prev_state;
				}, _processTitle);
			} else {
				_processTitle();
			}
		}
		
		
		
	//	
	//	
	//	if (this._ACTIVE_ROUTE_DATA._COMPILED_sidebarMenu.preLoadCommons && this._ACTIVE_ROUTE_DATA._COMPILED_sidebarMenu.preLoadCommons._LOADED) {
	//		console.log("_LOADED:");
	//		console.log(this._ACTIVE_ROUTE_DATA._COMPILED_sidebarMenu.preLoadCommons._LOADED);
	//		let _stringified_menu = JSON.stringify(this._ACTIVE_ROUTE_DATA._COMPILED_sidebarMenu);
	//		console.log("STRINGY 2: " + _stringified_menu);
	//		
	//		let re = RegExp('___LOAD_\-(.[^\-]*)\-___', 'g');
	//		let match = true;
	//		while (match) {
	//			match = re.exec(_stringified_menu);
	//			console.log("MATCH:");
	//			console.log(match);
	//			if (match) {
	//				let value = this.getEntry(this._ACTIVE_ROUTE_DATA._COMPILED_sidebarMenu.preLoadCommons._LOADED, match[1]);
	//				if (value) {
	//					_stringified_menu = _stringified_menu.replace(new RegExp(match[0], 'g'), value);
	//				} else {
	//					console.error(" :: Sidebar _ render [MISSING MATCHING ROUTE PARAM WHILE FORMATTING PATH] -- needed: ");
	//					console.error(match[1]);
	//				}
	//			}
	//		}
	//		console.log("STRINGY 2 OUT: " + _stringified_menu);
	//		this._ACTIVE_ROUTE_DATA._COMPILED_sidebarMenu = JSON.parse(_stringified_menu);
	//	}
		
		
		
		
		let menuItems = this.props.sidebar ? menu_data.items.filter((_C_menuItem) => {return _C_menuItem.privileges ? (this.getAppContext().authHandler.getAccountData().type == _C_menuItem.privileges) : true}) : menu_data;
		
		let registeredAnchorPoints = this.context._LayoutPage.getRegisteredAnchorPoints();
		if (this.state._REDIR) {
			this._resetRedir();
			return (
				<Redirect push to={this.getAppContext().validatePath(this.state._REDIR)}/>
			);
		}
		
		let scrollspyItems = [];
		menuItems.forEach((_C_menu, _C_idx) => {
			scrollspyItems.push(_C_menu.type == 'anchor' ? _C_menu.anchor_id : null);
		});
		for (var i = scrollspyItems.length-1 ; i >= 0 ; i--) {
			if (!scrollspyItems[i]) {
				scrollspyItems.pop();
			} else {
				break;
			}
		}
	//	console.log("this._JUMP_TOUT: " + this._JUMP_TOUT);
	//	console.log("this.lastHash: " + this.lastHash);
	//	console.log("this.getAppContext().router.location.hash: " + this.getAppContext().router.location.hash);
	//	console.log("this._forceScroll: " + this._forceScroll);
		if (!this._JUMP_TOUT && (this.lastHash != this.getAppContext().router.location.hash || this._forceScroll == this.lastHash)) {
	//		console.log("______________JUMPING!");
			this._JUMP_TOUT = setTimeout(() => {
				let _C_hash = this.getAppContext().router.location.hash;
				if (_C_hash == '' || (registeredAnchorPoints[_C_hash.split('#')[1]] && scrollspyItems.find((value) => {return value == _C_hash.split('#')[1];}))) {
					jump(document.querySelector(_C_hash != '' ? _C_hash : 'body'), {
						offset: ( this.context._LayoutPage.getSafeBoundaries().top * -1 ) + 1,
						callback: () => {
	//						console.log("CB 1");
							this._JUMP_TOUT = null;
							this.lastHash = _C_hash;
							this._forceScroll = null;
						}
					});
				} else {
					this._JUMP_TOUT = null;
					this.lastHash = _C_hash;
					this._forceScroll = null;
				}
			}, 200);
		}
		
		return (
			<Scrollspy className={this.props.className} items={scrollspyItems} currentClassName="is-current" offset={-80}>

				{(() => {
					let _TMP = [
						...([
							this.props.sidebar && sidebar_title ? (() => {
								let _TMP_sidebar_title = (
									<div className="sidebar-title">
										{menu_data.titleLabel ? (
											<div className="sidebar-title-label">{menu_data.titleLabel}</div>
										) : null}
										<div className=" sidebar-title-text">
											{typeof sidebar_title != 'string' ? (
												<strong className='text-truncate'>{sidebar_title}</strong>
											) : (
												<strong dangerouslySetInnerHTML={{__html: (sidebar_title || "")}}/>
											)}
										</div>
										
									</div>
								);
								if (this.state._ADV_TITLE.definition.link) {
									return (
										<NavLink to={this.state._ADV_TITLE.definition.link} className="sidebar-title-nav-link">
											{_TMP_sidebar_title}
										</NavLink>
									);
								} else {
									return _TMP_sidebar_title;
								}
							})() : null
						].filter((value) => {return value !== null})),
						...(menuItems.map((_C_menu, _C_idx) => {
							if (_C_menu.type == 'back') {
								return (
									<li className="nav-item" key={'1.' + _C_idx}>
										<NavLink to={this.getAppContext().validatePath(_C_menu.to)} exact={true} className="nav-link">
											<span className={"icon-arrow-back"}/><span>{_C_menu.name}</span>
										</NavLink>
									</li>
								);
							} else {
								return null;
							}
						}).filter((value) => {return value !== null})),
						...(menuItems.map((_C_menu, _C_idx) => {
						
							// TODO - Explandable sub menu
							//let _sub_menu = _C_menu.sub_menu ? _C_menu.sub_menu.map((_CURR_subMenu, _CURR_idx) => {
							//	
							//}) : null;
							
							if (_C_menu.type == 'home') {
								return (
									<li className="nav-item" key={'2.' + _C_idx}>
										<NavLink to={this.getAppContext().validatePath(_C_menu.to)} exact={_C_menu.exact} isActive={_C_menu.isActive || null} className="nav-link" onClick={() => {
											this._setForceScroll('');
		// 									jump(document.querySelector('body'));
										}}>
											{_C_menu.icon ? (<span className={"icon-" + _C_menu.icon}/>) : null}<span>{_C_menu.name}</span>
										</NavLink>
									</li>
								);
							} else if (_C_menu.type == 'page') {
								return (
									<li className="nav-item" key={'2.' + _C_idx}>
										<NavLink to={this.getAppContext().validatePath(_C_menu.to)} exact={_C_menu.exact} isActive={_C_menu.isActive || null} className="nav-link">
											{_C_menu.icon ? (<span className={"icon-" + _C_menu.icon}/>) : null}<span>{_C_menu.name}</span>
										</NavLink>
									</li>
								);
							} else if (_C_menu.type == 'anchor') {
								return (
									<li className="nav-item" key={'2.' + _C_idx}>
										<NavLink to={this.getAppContext().validatePath(_C_menu.to) + '#' + _C_menu.anchor_id} exact={_C_menu.exact} isActive={_C_menu.isActive || null} className="nav-link" onClick={() => {
											this._setForceScroll('#' + _C_menu.anchor_id);
		/*
											setTimeout(() => {
												jump(document.querySelector('#' + _C_menu.anchor_id));
											}, 100);
		*/
										}}>
											{_C_menu.icon ? (<span className={"icon-" + _C_menu.icon}/>) : null}<span>{_C_menu.name}</span>
										</NavLink>
									</li>
								);
							} else if (_C_menu.type == 'action') {
								return (
									<li className="nav-item" key={'2.' + _C_idx}>
										<span style={{cursor: 'pointer'}} className="nav-link" onClick={() => {
											this.getAppContext().getActionsShelfService().do(..._C_menu.action_params);
										}}>
											{_C_menu.icon ? (<span className={"icon-" + _C_menu.icon}/>) : null}<span>{_C_menu.name}</span>
										</span>
									</li>
								);
							} else if (_C_menu.type == 'custom' && _C_menu.render && typeof _C_menu.render == 'function') {
								return _C_menu.render('2.' + _C_idx, this.getAppContext());
							} else {
								return null;
							}
						}).filter((value) => {return value !== null}))
					];
					return _TMP;
					
				})()}
				
			
			</Scrollspy>
		);
	}
}

export default contextProvider(
	withDefinedContext(_NavigationMenu, '_NavigationMenu', context_exposed_methods),
	{
		retrieve: ['_LayoutPage']
	}
);