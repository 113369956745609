import PropTypes from "prop-types";

export default {
	app: PropTypes.object,
	_LayoutPage: PropTypes.object,
	_LayoutHeader: PropTypes.object,
	_LayoutSidebar: PropTypes.object,
	_NavigationMenu: PropTypes.object,
	_LayoutBody: PropTypes.object,
	_Prompter: PropTypes.object,
	Gallery: PropTypes.object,
	ReactOModal: PropTypes.object,
	ReactOCollapse: PropTypes.object,
	ReactOForm: PropTypes.object,
	ReactOUploader: PropTypes.object,
	ReactOTabs: PropTypes.object,
	ReactOCarousel: PropTypes.object,
	GoogleMaps: PropTypes.object
}