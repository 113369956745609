import Cookies from 'universal-cookie';
//import moment from 'moment';

class _AuthenticationHandler {
	constructor(props) {
		this.props = props;
		this.accountData = {};
		this.isAuthenticated = false;
		this.view = undefined;
		
		this._AUTH_COOKIE_NAME = 'reacto_auth';
		
		this.cookies = new Cookies();
		
		this.checkAuthentication = this.checkAuthentication.bind(this);
		this.getToken = this.getToken.bind(this);
		this.logout = this.logout.bind(this);
		
		this._actionShelf = this.props.actionsShelfService.createNewShelf('authHandlerActions');
		this._actionShelf.registerAction({
			key: 'logout',
			call: this.logout
		});
	}
	
	authenticate(credentials, cb) {
		var POST = {
//			tz: moment().format('Z'),
//			now: parseInt(moment().format('X'))
		};
		if (credentials.token) {POST.token = credentials.token;}
		if (credentials.user) {POST.user = credentials.user;}
		if (credentials.email) {POST.email = credentials.email;}
		if (credentials.password) {POST.pwd = credentials.password;}
		
		this.props.apiStoreService.fire('login', {
			data: POST,
			___onSuccess: (status, data) => { cb(status, data) },
			___onError: (status, data) => { cb(status, data) },
			___onUnauthorized: (status, data) => { cb(status, data) }
		});
	}
	
	login(credentials, cb) {
		this.authenticate(credentials, (status, data) => {
			if (status === 'OK') {
				this.accountData = data.accountData;
				this.cookies.set(this._AUTH_COOKIE_NAME, data.authRegistry.auth_token, {path: '/'});
				//this.cookies.set(this._AUTH_COOKIE_NAME, data.authRegistry.auth_token, {path: '/', maxAge: 10});
				this.isAuthenticated = true;
				this.props.onLoginSuccess(this.getAccountData());
				if (cb) cb(status, data);
			} else {
				this.isAuthenticated = false;
				if (cb) cb(status, data);
			}
		});
	}
	
	logout(cb) {
		this.props.apiStoreService.fire('logout', {
			___onSuccess: (status, response) => {
				this.invalidateToken(cb);				
				this.props.onLogoutSuccess();
				this.isAuthenticated = false;
				if (cb) {
					cb(status, response)
				}
			},
			___onError: (status, response) => {
				if (cb) { cb(status, response.error) }
			}
		});
	}
	
	invalidateToken(cb) {
	//	this.cookies.remove(this._AUTH_COOKIE_NAME, {path: '/'});
		this.isAuthenticated = false;
		if (cb) {cb()};
	}
	
	checkAuthentication(cb) {
		var curr_cookie = this.cookies.get(this._AUTH_COOKIE_NAME);
		if (curr_cookie) {
			this.authenticate({token: curr_cookie}, (status, data) => {
				if (status === 'OK') {
					this.accountData = data.accountData;
					this.isAuthenticated = true;
					cb('OK');
				} else {
					this.isAuthenticated = false;
					cb('KO', data);
				}
			});
		} else {
			cb('KO', 'NO_COOKIE');
		}
	}
	
	isAuthorized() {
		return this.isAuthenticated;
	}
	
	getToken(opt) {
		return opt && opt === 'x_header' ? this._AUTH_COOKIE_NAME + '=' + this.cookies.get(this._AUTH_COOKIE_NAME) : this.cookies.get(this._AUTH_COOKIE_NAME);
	}
	
	getAccountData() {
		return this.accountData;
	}

	signIn() {
		this.view.showSignIn()
	}
}

export default _AuthenticationHandler;