import React from 'react';

import _ReactOBaseComponent from '../../_L0_components/_ReactOBaseComponent';

class Sidebar extends _ReactOBaseComponent {
	constructor(props) {
		super(props);
		this.state = {}
	}
	
	onAppMounted() {
	//	console.log("DEBUG :: Sidebar -> onAppMounted");
	}
	
	onComponentDidMount() {
	//	console.log("DEBUG :: Sidebar -> onComponentDidMount");
	}
	
	getRouteMatchParam(key) {
		return this.getAppContext().activeRouteData.match.params[key];
	}
	
	render() {
		let sidebarMenu = [];
		if (this.props.data) {
			this.props.data.items.forEach((_C_menu, _C_idx) => {
				let re = RegExp('___-(.[^-]*)-___', 'g');
				let url_params = [];
				let match = true;
				let _formattedUrl = _C_menu.to;
				while (match) {
					match = re.exec(_C_menu.to);
					if (match) {
						url_params.push(match[1]);
					}
				}
				let _TMP = _C_menu;
				if (url_params.length > 0) {
					url_params.forEach((_C_param, _C_idx) => {
						let matchedRouteParam = this.getRouteMatchParam(_C_param);
						if (matchedRouteParam) {
							_formattedUrl = _formattedUrl.replace('___-' + _C_param + '-___', matchedRouteParam);
							_TMP._to_formatted = _formattedUrl;
						} else {
							console.error(" :: Sidebar _ render [MISSING MATCHING ROUTE PARAM WHILE FORMATTING PATH] -- needed: ");
							console.error(url_params);
						//	return;
						}
					});
				}
				sidebarMenu[_C_idx] = _TMP;
			});
		}
		
		return (
			<aside className="sidebar">
				<nav className="navbar navbar-light align-items-start">
						{this.props.children}
						{/*sidebarMenu.map((_C_menu, _C_idx) => {
							if (_C_menu.type === 'link') {
								return (
									<li className={ (_C_menu.isActive ? 'active ' : '') + 'nav-item' } key={_C_idx}>
								        <NavLink to={_C_menu._to_formatted} title={_C_menu.name} exact={_C_menu.exact} className="nav-link">
								            {_C_menu.icon ? (<span className={"icon-" + _C_menu.icon}/>) : null}<span>{_C_menu.name}</span>
								        </NavLink>
								    </li>
								);
							} else if (_C_menu.type === 'action') {
								return (
									<li className="nav-item text-left" key={_C_idx}>
										<a href="#" className="nav-link" onClick={() => {
											this.getAppContext().getActionsShelfService().do(..._C_menu.action_params);
										}}>
											{_C_menu.icon ? (<span className={"icon-" + _C_menu.icon}/>) : null}<span>{_C_menu.name}</span>
										</a>
									</li>
								);
							}
						})*/}
				</nav>
			</aside>
		);
	}
}

export default Sidebar;
