import React from 'react';
import contextProvider from 'core/contextProvider';

import _ReactOBaseComponent, { withDefinedContext } from 'core/_L0_components/_ReactOBaseComponent';

import ReactOCarousel from 'core/_L1_components/Elements/ReactOCarousel';
import Section from '../Layout/Section';

const context_exposed_methods = [
	'goNext',
	'goPrev'
];

class Gallery extends _ReactOBaseComponent {
	constructor(props) {
		super(props);
		this.state = {
			cursor: 0
		}
		this.defaultGalleryFolderPath = 'images/gallery/';
	}
	
	
	//
	//	Lifecycle methods
	//
	
	onAppMounted() {
		this.goNext();
		this.defaultGalleryFolderPath = this.getAppContext().conf.PUBLIC_PATH + 'images/gallery/';
	}
	
	onComponentDidMount() {
	}
	
	onComponentWillUnmount() {
	}
	
	onGetChildContext() {
		return {};
	}
	
	goNext() {
		this.setState((prev_state) => {
			prev_state.cursor += 1;
			prev_state.cursor = prev_state.cursor < 0 ? (this.props.images.length - 1) : (prev_state.cursor >= this.props.images.length ? 0 : prev_state.cursor )
			return prev_state;
		});
	}
	
	goPrev() {
		this.setState((prev_state) => {
			prev_state.cursor -= 1;
			prev_state.cursor = prev_state.cursor < 0 ? (this.props.images.length - 1) : (prev_state.cursor >= this.props.images.length ? 0 : prev_state.cursor )
			return prev_state;
		});
	}
	
	showImage(image_idx) {
		this._carousel.open(this.props.images.map((_C_image, _C_idx) => {
			return {
				src: encodeURI(this.props.absPath ? _C_image : this.defaultGalleryFolderPath + _C_image)
			}
		}), image_idx);
	//	this.setState({
	//		cursor: image_idx
	//	}, () => {
	//		this.context._LayoutPage.getRegisteredModals()['imageGalleryViewer'].toggle()
	//	});
	}
	
	onRender() {
		return (
			<div className="gallery">
				{/*<ReactOModal
					modalId="imageGalleryViewer"
					title="Test"
					footer={(this.state.cursor + 1) + " di " + this.props.images.length}
					body={(data, controller) => {
						return (
							<div
							 	className="gallery-image-viewer"
								style={{
									position: 'relative',
									margin: '-0.5rem'
								}
							}>
								<img src={encodeURI(this.defaultGalleryFolderPath + this.props.images[this.state.cursor])} style={{width: '100%'}}/>
								<div style={{
									position: 'absolute',
									top: '0',
									bottom: '0',
									left: '0',
									right: '0'
								}}>
										<div className="row no-gutters" style={{height: '100%'}}>
											<div className="col-5 gallery-ctrl gallery-ctrl-left d-flex align-items-stretch" onClick={() => {this.goPrev()}}>
												<div className="row no-gutters align-items-center" style={{width: '100%'}}>
													<div className="col-12">
														<span className="icon-circle-left"/>
													</div>
												</div>
											</div>
											<div className="col-2">
											</div>
											<div className="col-5 gallery-ctrl gallery-ctrl-right d-flex align-items-stretch" onClick={() => {this.goNext()}}>
												<div className="row no-gutters align-items-center" style={{width: '100%'}}>
													<div className="col-12">
														<span className="icon-circle-right"/>
													</div>
												</div>
											</div>
										</div>
								</div>
							</div>
						)
					}}
					controller={{
						next: (context) => {
						//	this.context._LayoutPage.getRegisteredModals()['imageGalleryViewer'].setData({image: bg_2})
						},
						prev: () => {
						//	this.context._LayoutPage.getRegisteredModals()['imageGalleryViewer'].setData({image: bg_4})
						}
					}}
				/>*/}
				<ReactOCarousel
					modal
					modalTitle={this.props.carouselTitle ? this.props.carouselTitle : "Gallery"}
					modalCentered
					onControllerReady={(carousel_context) => {
						this._carousel = carousel_context;
					}}
				/>
				<Section
				//	VStretch={true}
					designType="wide"
					layoutManager="grid"
					elementsPerRow={this.state.isMobile ? 3 : this.props.elementsPerRow ? this.props.elementsPerRow : 6}
					gridSpacing={2}
				//	backgroundColor={this.getAppContext().colors.lightGray}
				//	backgroundStyle='parallax'
				//	parallaxStrenght={200}
				//	backgroundImage={wall1}
				//	backgroundFixed={true}
				>
					{this.props.images.slice(0, this.props.max ? this.props.max : 36).map((_C_image, _C_idx) => {
						return _C_idx < (this.props.max ? (this.props.max - 1) : 35) ? (
							<Section key={_C_idx}
								className="gallery-item"
								style={{
									position: 'relative',
									paddingBottom: '100%',
									margin: '0.1px'
								}}
								backgroundColor="#FFF"
								backgroundImage={encodeURI(this.props.absPath ? _C_image : this.defaultGalleryFolderPath + _C_image)}
								onClick={() => {this.showImage(_C_idx)}}
							>
							</Section>
						) : (
							<Section key={_C_idx}
								className="gallery-item d-flex"
								style={{
									position: 'relative',
									paddingBottom: '100%',
									margin: '0.1px'
								}}
								backgroundColor="#FFF"
								backgroundImage={encodeURI(this.props.absPath ? _C_image : this.defaultGalleryFolderPath + _C_image)}
								onClick={() => {this.showImage(_C_idx)}}
							>
								<Section
									className="text-center h-100"
									VStretch={true}
									style={{
										color: '#444',
										position: 'absolute',
										width: '100%'
									}}
									backgroundColor="rgba(255, 255, 255, 0.5)"
									onClick={() => {this.showImage(_C_idx)}}
								>
									<div
										style={{
											marginTop: 'calc(50% - 0.5rem)',
											fontSize: '1.2rem'
										}}
									><strong>VEDI ALTRE</strong></div>
								</Section>
							</Section>
						);
					})}
				</Section>
				
				{/*React.Children.map(this.props.children, (_C_child, _C_idx) => {
					return React.cloneElement(_C_child, {  });
				})*/}
			</div>
		);
	}
}

export default contextProvider(
	withDefinedContext(Gallery, 'Gallery', context_exposed_methods),
	{
		retrieve: ['_LayoutPage']
	}
);


