var moment = require('moment');
var download = require('downloadjs');

class _ApiStoreService {
	constructor(props) {
		this.props = props;
		
		this.authHandler = null;
		
		this._APIs = {};
		
		this._LOADING_SETS = {};
	}
	
	setAuthHandler(auth_handler) {
		this.authHandler = auth_handler;
	}
	
	setAPIStore(APIs) {
		this._APIs = APIs;
	}
	
	fire(api_id, params) {
		if (this._APIs[api_id]) {
			let re = RegExp('___-(.[^-]*)-___', 'g');
			let url_params = [];
			let match = true;
			let _formattedUrl = this._APIs[api_id].url;
			while (match) {
				match = re.exec(this._APIs[api_id].url);
				if (match) {
					url_params.push(match[1]);
				}
			}
			
			if (url_params.length > 0) {
				url_params.forEach((_C_param, _C_idx) => {
					if (params.data && params.data[_C_param] && params.data[_C_param] !== '') {
						_formattedUrl = _formattedUrl.replace('___-' + _C_param + '-___', params.data[_C_param]);
					} else {
						console.error(" :: _ApiStoreService _ fire [MISSING DATA WHILE FORMATTING URL] -- needed: ");
						console.error(url_params);
						return;
					}
				});
			}
			
			if (this._APIs[api_id].cache) {
				params.___onSuccess(this._APIs[api_id].cache.status, this._APIs[api_id].cache.data);
			} else {				
				let _url = (/^http.*/.test(_formattedUrl) ? '' : this.props.apiUrl) + _formattedUrl + (this._APIs[api_id].method == 'GET' ? '?bt=' + moment().unix() + '&btz=' + moment().format('Z') : '');
				let _authenticated = this._APIs[api_id].authenticated;
				let _method = this._APIs[api_id].method;
				let _data = this._APIs[api_id].method != 'GET' ? (() => {params.data._service = {bt: moment().unix(), btz: moment().format('Z')}; return params.data;})() : {};

//				console.log('_ApiStoreService: fire > Calling ' + _url);
				fetch(_url, {
					credentials: 'same-origin',
					method: _method || 'GET',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						...(_authenticated ? {'Authorization': 'Bearer ' + this.authHandler.getToken()} : {})
					},
					...(_method !== 'GET' && _method !== 'HEAD' ? {body: JSON.stringify(_data)} : {})
				})
				.then((response) => {
					if (response.ok) {
						return this._APIs[api_id].binary ? response.blob() : response.json();
					} else {
						throw response;
					}
				})
				.then((response_data) => {
					if (this._APIs[api_id].binary) {
						download(response_data, params.filename ? params.filename : 'file');
					} else {
						if (response_data.do_cache) {
							this._APIs[api_id].cache = {status: response_data.status, data: response_data.data};
						}
						if (params.___onSuccess) { params.___onSuccess(response_data.status, response_data.data) }
					}
				}).catch((_e_response) => {
					console.error('_ApiStoreService: fire ERROR');
					console.error(_e_response);
					console.error(_e_response.status);
					console.error(_e_response.message);					
					if (_e_response.status == 502) {
					//	this.props.appContext.alert("ERROR 502");
						this.props.appContext.alert({
							title: "Server error",
							message: "We are very sorry. Something went wrong on our server. If the problem persists please contact WiFiProbe's customer support.",
							cb: () => {
								this.props.appContext.redirectTo("_HOME_");
								if (params.___onError) { params.___onError(_e_response.status, _e_response.message) }
							}
						});
					} else if (_e_response.status == 409 || _e_response.status == 422) {
					//	this.props.appContext.alert("ERROR " + _e_response.status);
						_e_response.json().then((responseError) => {
							if (params.___onError) { params.___onError(responseError.error, responseError.data) }
						});
						
					} else if (_e_response.status == 401) {
						_e_response.json().then((responseError) => {
							if (_authenticated) this.authHandler.invalidateToken();
							this.props.appContext.setPostLoginRedirect(this.props.appContext.router.location.pathname);
							this.props.appContext.redirectTo("/login");
							if (params.___onError) { params.___onError(responseError.error, responseError.data) }
						});
						
					} else if (_e_response.status == 403) {
						_e_response.json().then((responseError) => {
							this.props.appContext.alert({
								title: responseError.error,
								message: responseError.error,
								cb: () => {
									this.props.appContext.redirectTo("_HOME_");
									if (params.___onError) { params.___onError(responseError.error, responseError.data) }
								}
							});
						});
						
					} else {
						_e_response.text().then((responseMessage) => {
							let _TMP_parsed = JSON.parse(responseMessage);
							if (params.___onError) { params.___onError(_TMP_parsed.status, _TMP_parsed.data) }
						});
					}
					
				})/*.finally(() => {
					if (params.___onEnd) { params.___onEnd() }
				});*/
				
			}
		}
	}
	
	_makeid() {
		var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		for (var i = 0; i < 5; i++)
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		return text;
	}
	
	newCallsSet(set_data) {
		return {
			call: (to_call, opts) => {
				opts = opts ? opts : {};
				Promise.all( (Array.isArray(to_call) ? to_call : [to_call]).map((_CURR_to_call, _CURR_idx) => {
					return new Promise((resolve, reject) => {
						let call_params = set_data.calls_set[_CURR_to_call];
						if (!call_params) {
							console.warn("Call set not found (" + _CURR_to_call + ")");
							return;
						}
						if (call_params.set_state === undefined || call_params.set_state === true) {
							set_data.state_setter((_prev_state) => {
								_prev_state[call_params.loading_key ? call_params.loading_key : 'loading_' + _CURR_to_call] = true;
								return _prev_state;
							});
						}
						
						this.fire(call_params.api_id, {
							...({
								data: {...call_params.static_data, ...(opts.data_input ? opts.data_input : {})}
							}),
							___onSuccess: (status, data) => {
								let _cb = () => {
									resolve({
										call_id: _CURR_to_call,
										status: status,
										data: data
									})
									if (call_params.cb) {call_params.cb(status, data)}
								//	if (set_data.cb) {set_data.cb(status, data)}
								}
								if (call_params.set_state === undefined || call_params.set_state === true) {
									set_data.state_setter((_prev_state) => {
										_prev_state[call_params.datastore_key ? call_params.datastore_key : _CURR_to_call] = call_params.pre_datastore ? call_params.pre_datastore(data) : data;
										_prev_state[call_params.loading_key ? call_params.loading_key : 'loading_' + _CURR_to_call] = false;
										return _prev_state;
									}, _cb)
								} else {
									_cb();
								}
							},
							___onError: (error, data) => {
								let _cb = () => {
									resolve({
										call_id: _CURR_to_call,
										status: error,
										data: data
									})
									if (call_params.cb) {call_params.cb(error, data)}
								//	if (set_data.cb) {set_data.cb(error, data)}
								}
								if (call_params.set_state === undefined || call_params.set_state === true) {
									set_data.state_setter((_prev_state) => {
										_prev_state[call_params.loading_key ? call_params.loading_key : 'loading_' + _CURR_to_call] = false;
										return _prev_state;
									}, _cb)
								} else {
									_cb();
								}
							}
						});
					});
				})
				).then((values) => {
					let _fireSuccessful = true;
					values.forEach((_C_value, _C_idx) => {
						if (_C_value.status != 'OK') {
							_fireSuccessful = false;
						}
					});
					if (_fireSuccessful) {
						if (opts.on_success) {opts.on_success('OK', values)}
					} else {
						if (opts.on_error) {opts.on_error('KO', values)}
					}
					if (opts.cb) {opts.cb(values)}
				});
			}
		};
	}
}

export default _ApiStoreService;