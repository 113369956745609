import React from 'react';
import contextProvider from '../contextProvider';
import { Route } from 'react-router-dom';

class _ReactOBaseComponent extends React.Component {
	constructor(props, initState, context_exposed_methods) {
	//	console.log("DEBUG :: _ReactOBaseComponent -> constructor");
		super(props);
		this.state = initState ? initState : {};
		this.state.pre_loading = false;
		this._on_ready_called = false;
		this.sharedContextID = '';
		this._PROMPTS = [];
		
		if (this.ctx_definitions) {
			let ctx = {};
			this.ctx_definitions.forEach((_CURR_def, _CURR_idx) => {
				if (_CURR_def.ctx_id) {
					ctx[_CURR_def.ctx_id] = this.onGetChildContext ? (this.onGetChildContext.bind(this))() : {};
				}
				if (_CURR_def.exposed_methods) {
					let _tmp = {};
					_CURR_def.exposed_methods.forEach((entry, idx) => {
						if (this[entry]) {
							this[entry] = this[entry].bind(this);
							_tmp[entry] = this[entry];
						}
					});
					ctx[_CURR_def.ctx_id] = { ...ctx[_CURR_def.ctx_id], ..._tmp }
				}
			});
			this._contextObj = ctx;
			this.getChildContext = () => {
				return this._contextObj;
			}
			this.getContext = this.getChildContext.bind(this);
		}
	}
	
	definePromptingFunction(prompting_methods) {
		//console.log("DEBUG :: _ReactOBaseComponent -> definePromptingFunction");
		(Array.isArray(prompting_methods) ? prompting_methods : [prompting_methods]).forEach((_CURR_method, _CURR_idx) => {
			this[_CURR_method.name] = (...args) => {
				if (this.getContext()._Prompter) {
					if (this.checkPrompt(this[_CURR_method.name], args)) {
						_CURR_method.func.apply(this, args);
					}
				} else {
					_CURR_method.func.apply(this, args);
				}
			}
			this[_CURR_method.name] = this[_CURR_method.name].bind(this);
			if (this.ctx_definitions) {
				this.ctx_definitions.forEach((_CURR_def, _CURR_idx) => {
					if (_CURR_def.exposed_methods && _CURR_def.exposed_methods.find((value) => {return value == _CURR_method.name})) {
						this._contextObj[_CURR_def.ctx_id][_CURR_method.name] = this[_CURR_method.name];
					}
				})
			}
		});
	}
	
	hasPrompts() {
		return this._PROMPTS.length > 0;
	}
	
	definePrompter(action) {
		this._PROMPTS.push(action);
	}
	
	deleteDefinedPrompter(action) {
		this._PROMPTS = [];
	}
	
	checkPrompt(after_func, after_args) {
		//console.log("DEBUG :: _ReactOBaseComponent -> checkPrompt");
		if (this._PROMPTS.length > 0) {
			let _prompts_ok = 0;
			this._PROMPTS.forEach((_CURR_prompt, _CURR_idx) => {
				let _TMP = _CURR_prompt(() => {return after_func.apply(null, after_args)});
				_prompts_ok = _TMP ? (_prompts_ok + 1) : _prompts_ok;
			});
			if (_prompts_ok == this._PROMPTS.length) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}
	
	_getStateSetter() {
		return this.setState.bind(this);
	}
	
	componentDidUpdate() {
		if (this.onComponentDidUpdate) {
			this.onComponentDidUpdate();
		}
	}

	componentDidMount() {
		//console.log("DEBUG :: _ReactOBaseComponent -> componentDidMount");
		this.getAppContext().media({ minWidth: 576 }, () => {
//			console.log("IS NOT MOBILE");
			this.setState({
				isMobile: false
			});
		});
		this.getAppContext().media({ maxWidth: 576 }, () => {
//			console.log("IS MOBILE");
			this.setState({
				isMobile: true
			});
		});
		this.getAppContext().media({ minWidth: 768 }, () => {
//			console.log("IS NOT MOBILE");
			this.setState({
				isTablet: false
			});
		});
		this.getAppContext().media({ maxWidth: 768 }, () => {
//			console.log("IS MOBILE");
			this.setState({
				isTablet: true
			});
		});
		this.getAppContext().media({ minWidth: 992 }, () => {
//			console.log("IS NOT MOBILE");
			this.setState({
				isDesktop: false
			});
		});
		this.getAppContext().media({ maxWidth: 992 }, () => {
//			console.log("IS MOBILE");
			this.setState({
				isDesktop: true
			});
		});
		this.getAppContext().media({ minWidth: 1200 }, () => {
//			console.log("IS NOT MOBILE");
			this.setState({
				isDesktopXl: false
			});
		});
		this.getAppContext().media({ maxWidth: 1200 }, () => {
//			console.log("IS MOBILE");
			this.setState({
				isDesktopXl: true
			});
		});
		
		if (this.props.shareContextWithName) {
			this.sharedContextID = this.context.app.registerContext(this.props.shareContextWithName, this.getContext ? this.getContext() : {});
		}

		//let preLoadCommonsWrappedRenderer = this.getAppContext().activeRouteData && this.getAppContext().activeRouteData.preLoadCommons && this instanceof this.getAppContext().activeRouteData.component ? () => {
		let preLoadCommonsWrappedRenderer = this.props.preLoadCommons ? () => {
			this.setState( {pre_loading: true} )
			this.getAppContext().getApiStoreService().fire(typeof this.props.preLoadCommons == 'object' ? this.props.preLoadCommons.apiId : this.props.preLoadCommons, {
				...(this.props.preLoadCommons.params ? this.props.preLoadCommons.params : {}),
				___onSuccess: (status, data) => {
					let _TMP = {};
					_TMP[this.props.preLoadCommons.apiId || this.props.preLoadCommons] = data;
					this.setState({preloaded_data: _TMP}, () => {
						this.setState( {pre_loading: false} )
						if (this.onComponentDidMount) this.onComponentDidMount();
					})
				},
				___onError: (status, response) => {
					console.error(" :: _ReactOBaseComponent _ render [ERROR PRE LOADING COMMONS] - for:");
					console.error(this.getAppContext().activeRouteData);
				}
			})
		} : (this.onComponentDidMount ? this.onComponentDidMount.bind(this) : null)
		
		if (preLoadCommonsWrappedRenderer) {preLoadCommonsWrappedRenderer()}
	}
	
	componentWillUnmount() {
	//	console.log("DEBUG :: _ReactOBaseComponent -> componentWillUnmount");
		if (this.props.shareContextWithName) {
			this.context.app.unregisterContext(this.sharedContextID);
		}
		if (this.onComponentWillUnmount) this.onComponentWillUnmount();
	}
	
	render() {
	//	console.log("DEBUG :: _ReactOBaseComponent -> render");
	//	console.log("         " + this.context.app.app_ready)
	//	console.log("         " + this.props.shareContextWithName)
		if (this.context.app.app_ready && this.onAppMounted && !this._on_ready_called) {
			this.onAppMounted();
			this._on_ready_called = true;
		}

		let base_component_props = {
			title: this.props.title || "",
			...(this.props.on_click ? {onClick: () => {
				if (this.props.on_click.type == 'action') {
					this.getAppContext().getActionsShelfService().do(...this.props.on_click.params);
				}
			}} : {})
		};

		/*<span
			{...(this.props.on_click ? {onClick: () => {
				if (this.props.on_click.type == 'action') {
					this.getAppContext().getActionsShelfService().do(...this.props.on_click.params);
				}
			}} : {})}
		>
			{this.onRender()}
		</span>*/

		return this.onRender(base_component_props);
		
	}
	
	getAppContext() {
		return this.context.app;
	}
	
	getSharedContext(context_ref) {
		return this.context.app.getContext(context_ref);
	}
	
	renderSubroute(with_props) {
		return this.getAppContext().activeRouteData.subroutes ? Object.keys(this.getAppContext().activeRouteData.subroutes).map((_C_routePath, _C_idx) => {
			let routeData = this.getAppContext().activeRouteData.subroutes[_C_routePath];
			let CMP = with_props ? {render: () => {
				return (
					<routeData.component
						{...with_props}
					/>
				);
			}} : {component: routeData.component};
			if (Array.isArray(routeData.component)) {
				CMP = {render: () => {return this.context.app.iterateChildren(routeData.component)}}
			}
			
			
			return (
				<Route key={_C_idx} path={_C_routePath} exact={routeData.exact} {...CMP}/>
			)
			
			// TODO
			
		//	if (routeData.component instanceof Array) {
		//		CMP = {render: () => {return this.iterateChildren(routeData.component)}}
		//	}
		
		//	let routeData = this.ROUTES.public[_C_routePath];
		//	var CMP = {component: routeData.component};
		//	if (routeData.component instanceof Array) {
		//		CMP = {render: () => {return this.iterateChildren(routeData.component)}}
		//	}
		//	return (
		//		<Route key={_C_idx} path={(this._ROUTE_PREFIX || "") + _C_routePath} exact={routeData.exact} {...CMP}/>
		//	)
																	
			//////////////////////////
			
			
			
			
		}) : null;
	}
}

export const withDefinedContext = (Component, ctx_id, exposed_methods, define_prompter_context) => {
	if (ctx_id) {
		Component = contextProvider(
			Component,
			{
				define: [ctx_id]
			}
		);
		if (!Component.prototype.ctx_definitions) {
			Component.prototype.ctx_definitions = [];
		}
		Component.prototype.ctx_definitions.push({
			ctx_id: ctx_id,
			exposed_methods: exposed_methods
		});
		if (define_prompter_context) {
			Component.prototype.ctx_definitions.push({
				ctx_id: '_Prompter',
				exposed_methods: ['definePrompter', 'deleteDefinedPrompter', 'checkPrompt']
			});
		}
	}
	return define_prompter_context ? contextProvider(Component, {define: ['_Prompter']}) : Component;
};

export default contextProvider(
	_ReactOBaseComponent,
	{
		retrieve: ['app']
	}
);

