import React, { Component } from 'react';

import _ReactOBaseComponent from '../../_L0_components/_ReactOBaseComponent';

import { BarLoader as Loader, PulseLoader } from 'react-spinners';

export class LoadingSpin extends _ReactOBaseComponent {
	constructor(props) {
		super(props);
		this.defaultColor = "#666";
		this.defaultSize = "medium";
		
		this._widthFactorsForOverlayCenter = {
			big: 3.4,
			medium: 2.8,
			small: 3.5
		}
		this._heightFactorsForOverlayCenter = {
			big: 0,
			medium: 1,
			small: 1.5
		}
		
		this.color = this.props.color ? this.props.color : this.defaultColor;
		this.size = this.props.size ? this.props.size : this.defaultSize;
		this.sizeInt = this.size === 'big' ? 16 : (this.size === 'medium' ? 12 : (this.size === 'small' ? 8 : 8));
	}
	
	onComponentDidMount() {
		if (this.props._setMarginsForOC) {
			this.props._setMarginsForOC((this.sizeInt * this._heightFactorsForOverlayCenter[this.size]), (this.sizeInt * this._widthFactorsForOverlayCenter[this.size]));
		}
	}
	
	onRender() {
		let sizePx = this.sizeInt;
		return(
			<div style={this.props.style} className={'loading-spin ' + (this.props.className ? this.props.className : '') + (this.props.bounceSpinner ? ' bounce-loader' : '')}>
				{this.props.loadingPlug ? (this.props.bounceSpinner ? <PulseLoader color={this.getAppContext().colors.primary || this.color} size={8} margin="5px"/> : <Loader color={this.getAppContext().colors.primary || this.color} size={sizePx} margin="5px"/>) : null}
			</div>
		);
	}
}

export class _LoadingWrapper extends _ReactOBaseComponent {
	constructor(props) {
		super(props);
		this._EXTENDER = "_LoadingWrapper";
		this.state = {
			_marginTopForOC: 0,
			_marginLeftForOC: 0			
		}
		this._setMarginsForOC = this._setMarginsForOC.bind(this);
	}
	
	_setMarginsForOC(top, left) {
		this.setState({
			_marginTopForOC: top,
			_marginLeftForOC: left
		});
	}
	
	onRender() {
		let children = (this.props.hideOnLoading || (this.props.showOverlay !== undefined && !this.props.showOverlay)) ? React.Children.map(this.props.children, child => {
			return child ? React.cloneElement(!/object|function/.test(typeof child) ? (<span>{child}</span>) : child, {
				className: 'loading-kit-hideing-style' + (child.props && child.props.className ? ' ' + child.props.className : ''),
				style: {
					...(child.props && child.props.style ? child.props.style : {}),
					opacity: !this.props.loadingPlug ? ((this.props.hideOnLoading || this.props.showOverlay === false) ? '1' : '0.8') : '0',
					...(this.props.hideOnLoadingNoDisplay ? {
						display: !this.props.loadingPlug ? 'inherit' : 'none'
					} : {
						visibility: !this.props.loadingPlug ? 'inherit' : 'hidden'
					})
				}
			}) : null;
		}) : this.props.children
		
		let wrapClass = 'loading-wrapper h-100 ' + (this.props.fullscreen ? ' fullscreen' : '') + (this.props.minHeight ? ' min-height' : '');
		return(
			<div style={this.props.style} id={this.props.id} className={wrapClass}>
				{children}
				<LoadingOverlay showOverlay={this.props.showOverlay} style={this.props.overlayStyle} loadingPlug={this.props.loadingPlug} hideOnLoading={this.props.hideOnLoading} _marginTopForOC={this.state._marginTopForOC} _marginLeftForOC={this.state._marginLeftForOC} _setMarginsForOC={this._setMarginsForOC}/>
			</div>
		);
	}
}

class LoadingOverlay extends Component {
	render() {
		let spinStyle = {
			marginTop: '-' + this.props._marginTopForOC + 'px',
			marginLeft: '-' + this.props._marginLeftForOC + 'px',
			opacity: this.props.loadingPlug ? ((this.props.hideOnLoading || (this.props.showOverlay !== undefined && !this.props.showOverlay)) ? '1' : '0.8') : '0',
			visibility: this.props.loadingPlug ? 'visible' : 'hidden'
		}
		
		let overlayStyle = {opacity: this.props.loadingPlug ? ((this.props.hideOnLoading || (this.props.showOverlay !== undefined && !this.props.showOverlay)) ? '1' : '0.5') : '0', visibility: this.props.loadingPlug ? 'visible' : 'hidden'};
		if (this.props.style) {
			for ( let key in this.props.style ) {
				overlayStyle[key] = this.props.style[key];
			}
		}

		let loadingSpin = (<LoadingSpin style={spinStyle} loadingPlug={this.props.loadingPlug} size={this.props.style && this.props.style.spinSize ? this.props.style.spinSize : 'big'} _setMarginsForOC={this.props._setMarginsForOC} />);
		let loadingOverlay = this.props.showOverlay === undefined || this.props.showOverlay ? (<div className='loading-overlay' style={overlayStyle} />) : '';
		

		return(
			<div 
				style={{
					visibility: this.props.loadingPlug ? 'visible' : 'hidden'
				}} className='loading-overlay-container'>
				{loadingOverlay}
				{loadingSpin}				
			</div>
		);
	}
}