import React from 'react';
import contextProvider from '../../contextProvider';

import _ReactOBaseComponent from '../../_L0_components/_ReactOBaseComponent';

import { Parallax } from 'react-parallax';

//import '../.._L1_styles/Layout/section.scss';

class Section extends _ReactOBaseComponent {
	constructor(props) {
		super(props);
		this.state = {}
	}
	
	onComponentDidMount() {
	//	console.log("DEBUG :: Section -> onComponentDidMount");
	}
	
	parseImagePath(img_path) {
		if ( /^imgs\./.test(img_path) ) {
			return this.getAppContext().getImagesStoreService().get(img_path);
		} else {
			return img_path;
		}
	}
	
	onRender() {
		let _elemPerRow = this.props.elementsPerRow ? this.props.elementsPerRow : 3;
		let _elems = React.Children.toArray(this.props.children).filter((value) => {return value});
		let _elemCount = _elems.length;
		if (this.props.layoutManager == "grid" && this.props.gridSpacing) {
			for (var i = 0; i < ((_elemCount % _elemPerRow) > 0 ? (_elemPerRow - (_elemCount % _elemPerRow)) : 0); i++) {
				_elems.push('_FAKE_');
			}
		}
	//	let _calculatedGridSpacing = this.props.gridSpacing ? (this.props.gridSpacing > 1 ? (this.props.gridSpacing / 2) : this.props.gridSpacing) : null;
		
		let _mainSection = [];
		let row = [];
		let _C2 = -1;
		_elems.forEach((_C_child, _C_idx) => {
			_C2 = _C2 + (_C_child ? 1 : 0);
			let _isFirstRow = _C2 < _elemPerRow;
			let _isLastRow = (_C2 + 1) > (_elemCount - ((_elemCount % _elemPerRow) == 0 ? _elemPerRow : (_elemCount % _elemPerRow))) || _elemCount <= _elemPerRow;
			let _isFirstCol = (_C2 % _elemPerRow) == 0;
			let _isLastCol = (_C2 % _elemPerRow) == (_elemPerRow - 1);
			
			if (_C_child) {
				(this.props.layoutManager == "grid" ? row : _mainSection).push((() => {
					let _child = _C_child != '_FAKE_' ? React.cloneElement(_C_child, {
						..._C_child.props
					}) : null;
					if (/grid|columns/.test(this.props.layoutManager)) {
						return (
							<div key={_C_idx}
								className='col'
							>
								{_child}
							</div>
						)
					} else {
						return [_child];
					}
				})());
				
				if (this.props.layoutManager == "columns" || this.props.layoutManager == "grid") {
					if (this.props.gridSpacing && (this.props.layoutManager == "columns" || !_isLastCol)) {
						(this.props.layoutManager == "grid" ? row : _mainSection).push((
							<div key={_C_idx + '.1'}
								className={"aaaa " + _C2}
								style={{
									width: this.props.gridSpacing + 'px',
									backgroundColor: _C_child != '_FAKE_' && !(_elemCount < _elemPerRow && _C2 + 1 == _elemCount) && this.props.gridSpacingColor ? this.props.gridSpacingColor : 'transparent'
								}}
							/>
						))
					}
				}
				
				if (this.props.layoutManager == "grid") {
					if (_isLastCol) {
						_mainSection.push((
							<div
								key={_C_idx + '.2'}
								className="row no-gutters"
								style={!_isFirstRow ? {
									borderTop: this.props.gridSpacing + 'px solid ' + (this.props.gridSpacingColor ? this.props.gridSpacingColor : 'transparent'),
								} : {}}
							>
								{row}
							</div>
						));
						row = [];
					}
				}
			}
			
		});
		
		if (this.props.layoutManager == "columns") {
			_mainSection = (
				<div
					className="row no-gutters"
				>
					{_mainSection}
				</div>
			);
		}
		
		return this.props.backgroundStyle == 'parallax' ? (
			<Parallax
				className={"section " + (this.props.className || '')}
				style={{
					height: this.props.height ? this.props.height + 'px' : 'auto',
					...(this.props.designType != "wide" ? {maxWidth: '980px', margin: '0 auto'} : {}),
					...(this.props.style || {})
				}}
				blur={this.props.parallaxBlur || 0}
				bgImage={this.parseImagePath(this.props.backgroundImage)}
				bgImageAlt="the cat"
			//	bgWidth="auto"
			//	bgHeight="100%"
				strength={this.props.parallaxStrenght || 200}
			>
				{_mainSection}
			</Parallax>
		) : (
			<section
				id={this.props.id || ""}
				className={"section " + (this.props.className || '') + (this.props.backgroundImage ? ' bg-cover' : '') + (this.props.backgroundStyle == 'fixed' ? ' -fixed' : '')}
				style={{
					height: this.props.height ? this.props.height + 'px' : 'auto',
					...(this.props.designType != "wide" ? {maxWidth: '980px', margin: '0 auto'} : {}),
					...(this.props.backgroundImage ? {background: (this.props.backgroundColor ? (this.props.backgroundColor + " ") : "") + "url(" + this.parseImagePath(this.props.backgroundImage) + ") no-repeat " + (this.props.backgroundHAlign || "center") + " " + (this.props.backgroundVAlign || "top")} : {}),
					...(this.props.backgroundColor ? {backgroundColor: this.props.backgroundColor} : {}),
					...(this.props.style || {})
				}}
				onClick={this.props.onClick}
			>
				{_mainSection}
			</section>
		);
	}
}

export default contextProvider(
	Section,
	{
		define: null,
		retrieve: null
	}
);
