import React from 'react';
import contextProvider from '../../contextProvider';

import _ReactOBaseComponent from '../../_L0_components/_ReactOBaseComponent';

import { ButtonToolbar } from 'reactstrap';

class ReactOActionBar extends _ReactOBaseComponent {
	constructor(props) {
		var initState = {};
		super(props, initState);
	}
	
	onComponentDidMount() {
		//console.log("DEBUG :: ReactOActionBar -> onComponentDidMount");
	}
	
	onRender() {
		return (
			<ButtonToolbar
				className={(this.props.className || "") + " action-bar w-100 d-flex " + (this.props.align == 'right' ? " justify-content-end" : "") + (this.props.alignV ? " align-items-" + this.props.alignV : "")}
				style={{
					backgroundColor: this.props.backgroundColor,
					padding: this.props.padding ? this.props.padding : '10px',
					...(this.props.borderRadius ? {borderRadius: this.props.borderRadius} : {}),
//					...(this.props.align == 'right' ? {justifyContent: 'flex-end'} : (this.props.align == 'center' ? {justifyContent: 'center'} : {}))
				}}
			>
				{React.Children.map(this.props.children, (_C_child, _C_idx) => {
					return React.cloneElement(_C_child, { className: (_C_idx != (this.props.children.length || 1) - 1 ? "mr-2 " : "") + (_C_child.props.className ? _C_child.props.className : "") });
				})}
			</ButtonToolbar>
		);
	}
}

export default contextProvider(
	ReactOActionBar,
	{
		retrieve: []
	}
);
