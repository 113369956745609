class _ImagesStoreService {
	constructor(props) {
		this.props = props;
		
		this._IMAGES_DATA = window.WEBAPP_IMAGES;
		
	//	this.props.apiStoreService.fire('settings', {
	//		___onSuccess: (status, response) => {
	//			this._IMAGES_DATA = response.settingsData
	//			if (this.props.onLoaded) this.props.onLoaded();
	//		}
	//	});
		if (this.props.onLoaded) this.props.onLoaded();
	}
	
	getEntry(entry_path) {
		var BreakException = {};
		let e_path_array = entry_path.split('.');
		let _TMP = this._IMAGES_DATA;
		let ret = true;
		try {
			e_path_array.forEach((entry, idx) => {
				if (_TMP[entry] === undefined) {
					throw BreakException;
				}
				_TMP = _TMP[entry];
				ret = _TMP;
			});
		} catch(e) {
			if (e !== BreakException) throw e;
			ret = false;
		}
		return ret;
	}
	
	get(entry_path) {
		return this.props.imagesRepository + this.getEntry(entry_path);
	}
}

export default _ImagesStoreService;