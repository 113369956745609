import React from 'react';
import contextProvider from '../../contextProvider';

import _ReactOBaseComponent, { withDefinedContext } from '../_ReactOBaseComponent';

const context_exposed_methods = [
];

class _LayoutBody extends _ReactOBaseComponent {
	constructor(props) {
		super(props);
		this.state = {}
	}
	
	/////////////////////////
	//	Lifecycle methods
	//
	
	onAppMounted() {
	}
	
	onComponentDidMount() {
	}
	
	onComponentWillUnmount() {
	}
	
	onRender() {
		return (
			<div
				className="_layout-body"
				style={{
					marginTop: this.context._LayoutPage.getSafeBoundaries().top + 'px',
					marginLeft: this.context._LayoutPage.getSafeBoundaries().left + 'px',
					paddingBottom: (this.getAppContext().isBrowserEdge() ? this.context._LayoutPage.getSafeBoundaries().top : 0) + 'px'
				}}
			>
				<div		// Fix - per Window Edge
					style={{
						marginBottom: (this.getAppContext().isBrowserEdge() ? this.context._LayoutPage.getSafeBoundaries().top : 0) + 'px'
					}}
				>
					{React.Children.map(this.props.children, (_C_child, _C_idx) => {
						return _C_child ? React.cloneElement(_C_child, {  }) : null;
					})}
				</div>
			</div>
		);
	}
}

export default contextProvider(
	withDefinedContext(_LayoutBody, '_LayoutBody', context_exposed_methods),
	{
		retrieve: ['_LayoutPage']
	}
);