import React from 'react';
import contextProvider from '../../contextProvider';

import _ReactOBaseComponent from '../../_L0_components/_ReactOBaseComponent';
import { _LoadingWrapper } from '../../_L0_components/Widgets/_LoadingKit';

import { ButtonDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import ReactOButton from './ReactOButton';

class _ReactODropdown extends _ReactOBaseComponent {
	constructor(props) {
		var initState = {
			opened: false
		};
		super(props, initState);
	}
	
	toggle() {
		this.setState({
			opened: !this.state.opened
		});
	}
	
	
	//
	//	Lifecycle methods
	//
	
	onAppMounted() {
	}
	
	onComponentDidMount() {
	}
	
	onComponentWillUnmount() {
	}
	
	onRender(base_component_props) {
		let _DD_class = this.props.grouped || this.props.withAction ? ButtonDropdown : Dropdown
		let toggler_icon_elem = this.props.icon ? (
			<span className={"icon-" + this.props.icon}
				style={{
					marginRight: '8px'
				}}
			/>
		) : null;
		return (
			
			<_LoadingWrapper
				loadingPlug={this.props.loadingPlug !== undefined && this.props.loadingPlug}
			>	
				<_DD_class
					{...base_component_props}
					className={"reacto-dropdown " + (this.props.className || "") + (this.props.noStyle ? ' no-style' : '')}
					size={this.props.size}
					isOpen={this.state.opened}
					toggle={this.toggle.bind(this)}
					direction="down"
				>
					{this.props.withAction ? (
						<ReactOButton
							tag="div"
							color={this.props.color}
							outline={this.props.outline || this.props.text}
							disabled={this.props.disabled}
						>
							{typeof this.props.withAction == 'function' ? this.props.withAction() : this.props.withAction}
						</ReactOButton>
					) : null}
					
					<DropdownToggle
						color={this.props.color}
						onClick={() => {if (this.props.onOpen && !this.state.opened) {this.props.onOpen()}}}
						outline={this.props.outline || this.props.text}
						disabled={this.props.disabled}
						caret	// By default perchè venga applicata la classe .dropdown-toggle (necessaria per dropdows in button groups)
					>
						<div className="d-flex">
							<div className="flex-grow-1 text-truncate">{toggler_icon_elem}{typeof this.props.toggler == 'function' ? this.props.toggler() : this.props.toggler}</div>
							<div>{this.props.caret || this.props.caret === undefined ? (<span className={"caret icon-caret-down"}/>) : null}</div>
						</div>
					</DropdownToggle>
					
					<DropdownMenu
						className={"shadow" + (this.props.scrollable ? ' scroll' : '')}
						modifiers={{
							shift: { 
								enabled: true,
								fn: (a, b, c, d, e) => {
									a.offsets.popper.left = 0;
									a.offsets.reference.left = 0;
									return a;
								}
							}
						}}
					>
					
						{(this.props.options || []).map((_CURR_opt, _CURR_idx) => {
							let icon_elem = _CURR_opt.icon ? (
								<span className={"icon-" + _CURR_opt.icon}
									style={{
										...(_CURR_opt.iconRight ? {marginLeft: '8px'} : {marginRight: '8px'})
									}}
								/>
							) : null;
							return typeof _CURR_opt == 'function' ? _CURR_opt(_CURR_idx) : _CURR_opt == 'divider' ? (
								<DropdownItem key={_CURR_idx} divider/>
							) : (
								<DropdownItem
									key={_CURR_idx}
									active={_CURR_opt.active}
									onClick={_CURR_opt.onClick}
								>
									{!_CURR_opt.iconRight ? icon_elem : null}{_CURR_opt.render ? _CURR_opt.render() : (_CURR_opt.name ? _CURR_opt.name : null)}{_CURR_opt.iconRight ? icon_elem : null}
								</DropdownItem>
							)
						})}
	
					</DropdownMenu>
				</_DD_class>
			</_LoadingWrapper>
		);
	}
}

export default contextProvider(
	_ReactODropdown,
	{
		retrieve: []
	}
);


