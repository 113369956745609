import React from 'react';
import contextProvider from 'core/contextProvider';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import _ReactOBaseComponent from 'core/_L0_components/_ReactOBaseComponent';
import { _LoadingWrapper } from 'core/_L0_components/Widgets/_LoadingKit';

import Section from 'core/_L1_components/Layout/Section';

import SectionNEW from 'core/_L1_components/Layout/SectionNEW';

class Test extends _ReactOBaseComponent {
	constructor(props) {
		var initState = {
			loading: false,
			data: {}
		};
		super(props, initState);
	}
	
	/////////////////////////
	//	Lifecycle methods
	//
	
	onAppMounted() {
	}
	
	onComponentDidMount() {
//		console.log("DEBUG :: Test _ onComponentDidMount");
		this.apiStore = this.getAppContext().getApiStoreService();
	//	this.loadProbeUsers();
	}
	
	onComponentWillUnmount() {
	}
	
	onRender() {
		return (
			<div>
			
			
				<SectionNEW style={{height: '1000px'}}>
				</SectionNEW>
				
			
				<SectionNEW
					designType="wide"
					className="mt-4 p-5"
					layoutManager="grid"
					elementsPerRow={5}
					gridSpacing={20}
				//	gridSpacingColor={this.getAppContext().colors.cyan}
					backgroundStyle='parallax'
					//parallaxBlur={{min:10, max:-19}}
					parallaxStrenght={500}
					backgroundImage="images/services/bg1.jpg"
				>
					<div
						className="d-flex flex-column text-center h-100"
						style={{
							backgroundColor: this.getAppContext().colors.light
						}}
					>
						<SectionNEW
							height={200}
							designType="wide"
							backgroundImage="images/services/pic1.jpg"
							backgroundVAlign="top"
						/>
						<SectionNEW
							designType="wide"
							backgroundColor={this.getAppContext().colors.primary}
							style={{
								color: this.getAppContext().colors.light,
								padding: '20px',
								fontSize: '20px'
							}}
						>
							<strong>AUTUNNO ALLE PORTE</strong>
						</SectionNEW>
						<div
							className="text-center align-self-stretch"
							style={{
								padding: '20px'
							}}
						>
							<div>
								Gestione totale dei sinistri per sollevare i Clienti da ogni onere burocratico ed economico, ove necessario rivolgendoci a Studi preposti specializzati nella gestione delle pratiche più complesse
							</div>
						</div>
					</div>
					
					
					<div
						className="d-flex flex-column text-center h-100"
						style={{
							backgroundColor: this.getAppContext().colors.light
						}}
					>
						<SectionNEW
							height={200}
							designType="wide"
							backgroundImage="images/services/pic2.jpg"
							backgroundVAlign="top"
						/>
						<SectionNEW
							designType="wide"
							backgroundColor={this.getAppContext().colors.primary}
							style={{
								color: this.getAppContext().colors.light,
								padding: '20px',
								fontSize: '20px'
							}}
						>
							<strong>VIAGGI</strong>
						</SectionNEW>
						<div
							className="text-center"
							style={{
								padding: '20px'
							}}
						>
							<div>
								Al cliente viene rilasciato gratuitamente il preventivo della riparazione, formulato analiticamente con indicazione delle lavorazioni, delle garanzie rilasciate e del periodo di validità

Possiamo fornire inoltre preventivi online previo invio documentazione fotografica dei danni subiti o provocati a terzi per una consulenza immediata
							</div>
						</div>
					</div>
					
					<div
						className="d-flex flex-column text-center h-100"
						style={{
							backgroundColor: this.getAppContext().colors.light
						}}
					>
						<SectionNEW
							height={200}
							designType="wide"
							backgroundImage="images/services/pic1.jpg"
							backgroundVAlign="top"
						/>
						<SectionNEW
							designType="wide"
							backgroundColor={this.getAppContext().colors.primary}
							style={{
								color: this.getAppContext().colors.light,
								padding: '20px',
								fontSize: '20px'
							}}
						>
							<strong>AUTUNNO ALLE PORTE</strong>
						</SectionNEW>
						<div
							className="text-center align-self-stretch"
							style={{
								padding: '20px'
							}}
						>
							<div>
								Gestione totale dei sinistri per sollevare i Clienti da ogni onere burocratico ed economico, ove necessario rivolgendoci a Studi preposti specializzati nella gestione delle pratiche più complesse
							</div>
						</div>
					</div>
					
					
					<div
						className="d-flex flex-column text-center h-100"
						style={{
							backgroundColor: this.getAppContext().colors.light
						}}
					>
						<SectionNEW
							height={200}
							designType="wide"
							backgroundImage="images/services/pic2.jpg"
							backgroundVAlign="top"
						/>
						<SectionNEW
							designType="wide"
							backgroundColor={this.getAppContext().colors.primary}
							style={{
								color: this.getAppContext().colors.light,
								padding: '20px',
								fontSize: '20px'
							}}
						>
							<strong>VIAGGI</strong>
						</SectionNEW>
						<div
							className="text-center align-self-stretch"
							style={{
								padding: '20px'
							}}
						>
							<div>
								Al cliente viene rilasciato gratuitamente il preventivo della riparazione, formulato analiticamente con indicazione delle lavorazioni, delle garanzie rilasciate e del periodo di validità

Possiamo fornire inoltre preventivi online previo invio documentazione fotografica dei danni subiti o provocati a terzi per una consulenza immediata
							</div>
						</div>
					</div>
					
					<div
						className="d-flex flex-column text-center h-100"
						style={{
							backgroundColor: this.getAppContext().colors.light
						}}
					>
						<SectionNEW
							height={200}
							designType="wide"
							backgroundImage="images/services/pic1.jpg"
							backgroundVAlign="top"
						/>
						<SectionNEW
							designType="wide"
							backgroundColor={this.getAppContext().colors.primary}
							style={{
								color: this.getAppContext().colors.light,
								padding: '20px',
								fontSize: '20px'
							}}
						>
							<strong>AUTUNNO ALLE PORTE</strong>
						</SectionNEW>
						<div
							className="text-center align-self-stretch"
							style={{
								padding: '20px'
							}}
						>
							<div>
								Gestione totale dei sinistri per sollevare i Clienti da ogni onere burocratico ed economico, ove necessario rivolgendoci a Studi preposti specializzati nella gestione delle pratiche più complesse
							</div>
						</div>
					</div>
					
					
					<div
						className="d-flex flex-column text-center h-100"
						style={{
							backgroundColor: this.getAppContext().colors.light
						}}
					>
						<SectionNEW
							height={200}
							designType="wide"
							backgroundImage="images/services/pic2.jpg"
							backgroundVAlign="top"
						/>
						<SectionNEW
							designType="wide"
							backgroundColor={this.getAppContext().colors.primary}
							style={{
								color: this.getAppContext().colors.light,
								padding: '20px',
								fontSize: '20px'
							}}
						>
							<strong>VIAGGI</strong>
						</SectionNEW>
						<div
							className="text-center"
							style={{
								padding: '20px'
							}}
						>
							<div>
								Al cliente viene rilasciato gratuitamente il preventivo della riparazione, formulato analiticamente con indicazione delle lavorazioni, delle garanzie rilasciate e del periodo di validità

Possiamo fornire inoltre preventivi online previo invio documentazione fotografica dei danni subiti o provocati a terzi per una consulenza immediata
							</div>
						</div>
					</div>
					
					<div
						className="d-flex flex-column text-center h-100"
						style={{
							backgroundColor: this.getAppContext().colors.light
						}}
					>
						<SectionNEW
							height={200}
							designType="wide"
							backgroundImage="images/services/pic1.jpg"
							backgroundVAlign="top"
						/>
						<SectionNEW
							designType="wide"
							backgroundColor={this.getAppContext().colors.primary}
							style={{
								color: this.getAppContext().colors.light,
								padding: '20px',
								fontSize: '20px'
							}}
						>
							<strong>AUTUNNO ALLE PORTE</strong>
						</SectionNEW>
						<div
							className="text-center align-self-stretch"
							style={{
								padding: '20px'
							}}
						>
							<div>
								Gestione totale dei sinistri per sollevare i Clienti da ogni onere burocratico ed economico, ove necessario rivolgendoci a Studi preposti specializzati nella gestione delle pratiche più complesse
							</div>
						</div>
					</div>
					
					
					<div
						className="d-flex flex-column text-center h-100"
						style={{
							backgroundColor: this.getAppContext().colors.light
						}}
					>
						<SectionNEW
							height={200}
							designType="wide"
							backgroundImage="images/services/pic2.jpg"
							backgroundVAlign="top"
						/>
						<SectionNEW
							designType="wide"
							backgroundColor={this.getAppContext().colors.primary}
							style={{
								color: this.getAppContext().colors.light,
								padding: '20px',
								fontSize: '20px'
							}}
						>
							<strong>VIAGGI</strong>
						</SectionNEW>
						<div
							className="text-center align-self-stretch"
							style={{
								padding: '20px'
							}}
						>
							<div>
								Al cliente viene rilasciato gratuitamente il preventivo della riparazione, formulato analiticamente con indicazione delle lavorazioni, delle garanzie rilasciate e del periodo di validità

Possiamo fornire inoltre preventivi online previo invio documentazione fotografica dei danni subiti o provocati a terzi per una consulenza immediata
							</div>
						</div>
					</div>
					
					<div
						className="d-flex flex-column text-center h-100"
						style={{
							backgroundColor: this.getAppContext().colors.light
						}}
					>
						<SectionNEW
							height={200}
							designType="wide"
							backgroundImage="images/services/pic1.jpg"
							backgroundVAlign="top"
						/>
						<SectionNEW
							designType="wide"
							backgroundColor={this.getAppContext().colors.primary}
							style={{
								color: this.getAppContext().colors.light,
								padding: '20px',
								fontSize: '20px'
							}}
						>
							<strong>AUTUNNO ALLE PORTE</strong>
						</SectionNEW>
						<div
							className="text-center align-self-stretch"
							style={{
								padding: '20px'
							}}
						>
							<div>
								Gestione totale dei sinistri per sollevare i Clienti da ogni onere burocratico ed economico, ove necessario rivolgendoci a Studi preposti specializzati nella gestione delle pratiche più complesse
							</div>
						</div>
					</div>
					
					
					<div
						className="d-flex flex-column text-center h-100"
						style={{
							backgroundColor: this.getAppContext().colors.light
						}}
					>
						<SectionNEW
							height={200}
							designType="wide"
							backgroundImage="images/services/pic2.jpg"
							backgroundVAlign="top"
						/>
						<SectionNEW
							designType="wide"
							backgroundColor={this.getAppContext().colors.primary}
							style={{
								color: this.getAppContext().colors.light,
								padding: '20px',
								fontSize: '20px'
							}}
						>
							<strong>VIAGGI</strong>
						</SectionNEW>
						<div
							className="text-center"
							style={{
								padding: '20px'
							}}
						>
							<div>
								Al cliente viene rilasciato gratuitamente il preventivo della riparazione, formulato analiticamente con indicazione delle lavorazioni, delle garanzie rilasciate e del periodo di validità

Possiamo fornire inoltre preventivi online previo invio documentazione fotografica dei danni subiti o provocati a terzi per una consulenza immediata
							</div>
						</div>
					</div>
					
					<div
						className="d-flex flex-column text-center h-100"
						style={{
							backgroundColor: this.getAppContext().colors.light
						}}
					>
						<SectionNEW
							height={200}
							designType="wide"
							backgroundImage="images/services/pic1.jpg"
							backgroundVAlign="top"
						/>
						<SectionNEW
							designType="wide"
							backgroundColor={this.getAppContext().colors.primary}
							style={{
								color: this.getAppContext().colors.light,
								padding: '20px',
								fontSize: '20px'
							}}
						>
							<strong>AUTUNNO ALLE PORTE</strong>
						</SectionNEW>
						<div
							className="text-center align-self-stretch"
							style={{
								padding: '20px'
							}}
						>
							<div>
								Gestione totale dei sinistri per sollevare i Clienti da ogni onere burocratico ed economico, ove necessario rivolgendoci a Studi preposti specializzati nella gestione delle pratiche più complesse
							</div>
						</div>
					</div>
					
					
					<div
						className="d-flex flex-column text-center h-100"
						style={{
							backgroundColor: this.getAppContext().colors.light
						}}
					>
						<SectionNEW
							height={200}
							designType="wide"
							backgroundImage="images/services/pic2.jpg"
							backgroundVAlign="top"
						/>
						<SectionNEW
							designType="wide"
							backgroundColor={this.getAppContext().colors.primary}
							style={{
								color: this.getAppContext().colors.light,
								padding: '20px',
								fontSize: '20px'
							}}
						>
							<strong>VIAGGI</strong>
						</SectionNEW>
						<div
							className="text-center align-self-stretch"
							style={{
								padding: '20px'
							}}
						>
							<div>
								Al cliente viene rilasciato gratuitamente il preventivo della riparazione, formulato analiticamente con indicazione delle lavorazioni, delle garanzie rilasciate e del periodo di validità

Possiamo fornire inoltre preventivi online previo invio documentazione fotografica dei danni subiti o provocati a terzi per una consulenza immediata
							</div>
						</div>
					</div>
					
				</SectionNEW>
			
							
				<SectionNEW style={{height: '1000px'}}>
				</SectionNEW>
				
				
			</div>
		);
	}
}

export default contextProvider(
	withRouter(Test),
//	withDefinedContext(Test, 'Test', context_exposed_methods),
	{
		define: null
	}
);
