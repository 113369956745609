import React from 'react';
import Recaptcha from 'react-grecaptcha';
import contextProvider from 'core/contextProvider';

import _ReactOBaseComponent, { withDefinedContext } from 'core/_L0_components/_ReactOBaseComponent';

import { LoadingSpin } from 'core/_L0_components/Widgets/_LoadingKit';

const context_exposed_methods = [
];

class ContactForm extends _ReactOBaseComponent {
	constructor(props) {
		var initState = {
			recaptchaCheck: null,
			form: {
				valid: false,
				datastore: {
					u_name: "",
					u_email: "",
					u_subject: "",
					u_message: "",
					rc_response_token: "",
					rc_ready: false
				},
				validations: {
					u_name: /.+/,
					u_email: /^.+\@.+\..{2,}$/,
					u_subject: /.+/,
					u_message: /.+/,
					rc_response_token: /.+/
				}
			}
		};
		super(props, initState);
		
		this.recaptchaInstance = null;
		
		this.onloadCallback = this.onloadCallback.bind(this);
		this.recaptchaVerifyCallback = this.recaptchaVerifyCallback.bind(this);
		this.recaptchaExpiredCallback = this.recaptchaExpiredCallback.bind(this);
		
		if (this.props.onControllerReady) {
			this.props.onControllerReady({
				sendMessage: this.sendMessage.bind(this),
				isValid: () => {return this.state.form.valid;}
			});
		}
		
	//	this.RH = null;
	}
	
	
	//
	//	Lifecycle methods
	//
	
	onAppMounted() {
	}
	
	onComponentDidMount() {
		this.apiStore = this.getAppContext().getApiStoreService();
		if (this.props.preformattedMessage) {
			this.handleFormChange('u_message', this.props.preformattedMessage);
		}
	}
	
	onComponentWillUnmount() {
	}
	
	onGetChildContext() {
		return {};
	}
	
	sendMessage(cb) {
		this.setState({loading: true});
		this.handleFormChange('rc_response_token', false);
		this.apiStore.fire('send_contact_msg', {
			data: {
				...this.state.form.datastore,
				...{template: this.props.template ? this.props.template : 'contacts'}
			}, 
			___onSuccess: (status, data) => {
				window.grecaptcha.reset();
				document.getElementById("privacy_check").checked = false;
				this.setState((prev_state) => {
					prev_state.loading = false;
					prev_state.form.datastore = {
						privacy_check: false,
						u_name: "",
						u_email: "",
						u_subject: "",
						u_message: "",
						rc_response_token: "",
						rc_ready: false
					};
					return prev_state;
				}, () => {
					if (cb) {cb()}
				});
			}
		});
	//	this.RH.doRequest({
	//		authenticated: true,
	//		url: this.getAppContext().conf.API_URL + '/send_contact_msg',
	//		method: 'POST',
	//		data: this.state.form.datastore,
	//		___onSuccess: (status, data) => {
	//		},
	//		___onUnauthorized: (error) => {
	//			//window.location = '#/login';
	//		},
	//		___onError: (status, data) => {
	//			//window.location = '#/error';
	//		},
	//		___onEnd: () => {
	//			//this.recaptchaInstance.reset();
	//			window.grecaptcha.reset();
	//			document.getElementById("privacy_check").checked = false;
	//			this.setState((prev_state) => {
	//				prev_state.loading = false;
	//				prev_state.form.datastore = {
	//					privacy_check: false,
	//					u_name: "",
	//					u_email: "",
	//					u_subject: "",
	//					u_message: "",
	//					rc_response_token: "",
	//					rc_ready: false
	//				};
	//				return prev_state;
	//			});
	//		}
	//	});
	}
	
	onloadCallback() {
		this.handleFormChange('rc_ready', true);
	}
	
	recaptchaVerifyCallback(response) {
		this.handleFormChange('rc_response_token', response);
	}
	
	recaptchaExpiredCallback() {
		this.handleFormChange('rc_response_token', false);
	}
	
	handleFormChange(entry_path, value) {
		let e_path_array = entry_path.split('.');
		this.setState((prev_state) => {
			let _DATASTORE = prev_state.form.datastore;
			e_path_array.forEach((entry, idx) => {
				if (!_DATASTORE[entry]) {
					_DATASTORE[entry] = {};
				}
				if (idx == e_path_array.length-1) {
					if (value === false) {
						delete(_DATASTORE[entry]);
					} else {
						_DATASTORE[entry] = value;
					}
				}
				_DATASTORE = _DATASTORE[entry];
			});
		//	prevState.form.valid[entry_path] = this.state.form.validations[entry_path].test(value);
		    return prev_state;
		}, () => {
			this.setState((prev_state) => {
				prev_state.form.valid = this.checkForm();
				return prev_state;
			}, this.props.onValidChanged ? () => {
				this.props.onValidChanged(this.state.form.valid)
			} : null); 
		});
	}
	
	getFormEntry(entry_path) {
		let e_path_array = entry_path.split('.');
		let _DATASTORE = this.state.form.datastore;
		let _RETURN_VALUE = null;
		e_path_array.forEach((entry, idx) => {
			if (_DATASTORE[entry] === undefined) {
				_RETURN_VALUE = null;
				return false;
			}
			_DATASTORE = _DATASTORE[entry];
			_RETURN_VALUE = _DATASTORE;
		});
		return _RETURN_VALUE || "";
	}
	
	checkForm() {
		if (
			(this.state.form.validations['u_name'].test(this.getFormEntry('u_name'))) && 
			(this.state.form.validations['u_email'].test(this.getFormEntry('u_email'))) && 
		//	(this.state.form.validations['u_subject'].test(this.getFormEntry('u_subject'))) && 
			(this.state.form.validations['u_message'].test(this.getFormEntry('u_message'))) &&
			(this.getFormEntry('privacy_check')) &&
		//	(this.getFormEntry('rc_ready')) &&
			(this.state.form.validations['rc_response_token'].test(this.getFormEntry('rc_response_token')))
		) {
			return true;
		} else {
			return false;
		}
	}
	
	onRender() {
		return (
			<div>
				<form>
					<div className="form-group">
						<input
							id="contact_name"
							type="text"
							className="form-control"
							aria-describedby="contactName"
							placeholder="Nome *"
							value={this.getFormEntry('u_name')}
							onChange={(e) => { this.handleFormChange('u_name', e.target.value); }}
						/>
					</div>
					<div className="form-group">
						<input
							type="email"
							className="form-control"
							aria-describedby="contactEmail"
							placeholder="Email, per essere ricontattato *"
							value={this.getFormEntry('u_email')}
							onChange={(e) => { this.handleFormChange('u_email', e.target.value); }}
						/>
					</div>
					{/*
					<div className="form-group">
						<input
							type="text"
							className="form-control"
							aria-describedby="contactSubject"
							placeholder="Oggetto"
							value={this.getFormEntry('u_subject')}
							onChange={(e) => { this.handleFormChange('u_subject', e.target.value); }}
						/>
					</div>
					*/}
					<div className="form-group">
						<textarea
							className="form-control"
							rows={this.props.messageRows ? this.props.messageRows : "4"}
							placeholder="Messaggio"
							value={this.getFormEntry('u_message')}
							onChange={(e) => { this.handleFormChange('u_message', e.target.value); }}
						/>
					</div>
					<div className="form-group form-check">
						<input className="form-check-input" type="checkbox" value={this.getFormEntry('privacy_check')} id="privacy_check" onClick={(e) => {
							this.handleFormChange('privacy_check', e.target.checked);
						}}/>
						<span className="form-check-label" htmlFor="privacy_check">
							Acconsento al trattamento dei dati secondo la <a style={{color: 'inherit', textDecoration: 'underline'}} href="/privacy">normativa sulla privacy</a>
						</span>
					</div>
					<div className="form-group">
						<div
							style={{
								float: 'left',
								marginBottom: '1rem'
							}}
						>
							<Recaptcha
							//	ref={e => this.recaptchaInstance = e}
								locale="it"
								data-theme={this.props.darkRecaptcha ? "dark" : "light"}
								sitekey="6Lcgc1cUAAAAAOYWNUBQRdLb6VrdotjifWej4rMm"
								render="explicit"
							//	onloadCallback={this.onloadCallback}
								callback={this.recaptchaVerifyCallback}
								expiredCallback={this.recaptchaExpiredCallback}
							/>
						</div>
						{this.props.sendButton === undefined || this.props.sendButton ? (
							<div
								className="text-right"
								style={{
									float: 'right'
								}}
							>
								<button
									type="button"
									className="btn btn-light"
									disabled={!this.state.form.valid}
									onClick={() => {
										this.sendMessage();
									}}
								>{this.state.loading ? (
									<LoadingSpin
										loadingPlug={true}
										size='big'
										bounceSpinner={true}
										style={{
											float: 'left',
											marginTop: '5px',
											marginRight: '5px'
										}}
									/>
								) : null}Invia</button>
							</div>
						) : null}
						<div className="clearfix"/>
					</div>
				</form>
			</div>
		);
	}
}

export default contextProvider(
	ContactForm,
	{
		retrieve: ['_LayoutPage']
	}
);


