import React from 'react';
import contextProvider from 'core/contextProvider';
import { Link } from 'react-router-dom';
import { Row, Col, Label } from 'reactstrap';

import _ReactOBaseComponent, { withDefinedContext } from 'core/_L0_components/_ReactOBaseComponent';
import { _LoadingWrapper } from 'core/_L0_components/Widgets/_LoadingKit';

import _AnchorPoint from 'core/_L0_components/Layout/_AnchorPoint';
import Section from 'core/_L1_components/Layout/Section';
import ReactOButton from 'core/_L1_components/Elements/ReactOButton';
import ReactODropdown from 'core/_L1_components/Elements/ReactODropdown';
import ReactOActionBar from 'core/_L1_components/Elements/ReactOActionBar';
import ReactOModal from 'core/_L1_components/Elements/ReactOModal';
import Panel from 'core/_L1_components/Layout/Panel';
import Gallery from 'core/_L1_components/Widgets/Gallery';
import GoogleMaps from 'core/_L1_components/Widgets/GoogleMaps';
import ContactForm from 'core/_L1_components/Widgets/ContactForm';

import conv_amissima from 'app/imgs/conv/Amissima.png';
import conv_ariscom from 'app/imgs/conv/Ariscom.png';
import conv_authoitalis from 'app/imgs/conv/AuthoItalia.png';
import conv_autoprestobene from 'app/imgs/conv/AutoPrestoBene.png';
import conv_leaseplan from 'app/imgs/conv/LeasePlan.png';
import conv_nobis from 'app/imgs/conv/Nobis.png';
import conv_promise from 'app/imgs/conv/Promise.png';
import conv_unipol from 'app/imgs/conv/Unipol.png';

const context_exposed_methods = [
];

class Home extends _ReactOBaseComponent {
	constructor(props) {
		var initState = {
			loading: false,
			data: null,
		};
		super(props, initState);

		this.apiStore = null;
		this.actionsShelf = null;
	}
	
	/////////////////////////
	//	Lifecycle methods
	//
	
	onAppMounted() {
		this.apiStore = this.getAppContext().getApiStoreService();
		this.actionsShelf = this.getAppContext().getActionsShelfService();

		this.callsSet = this.apiStore.newCallsSet({
			state_setter: this._getStateSetter(),
			calls_set: {
				products: {
					api_id: 'products'
				}
			}
		});
		
		this.callsSet.call('products', {
			on_success: (response_status, response_data) => {
				
			}
		});
	}
	
	onComponentDidMount() {
	}
	
	onComponentWillUnmount() {
	}
	
	onRender() {
		
		let galleryImages = [
			"INCIDENTE1.jpg", "INCIDENTE2.jpg", "INCIDENTE3.jpg", "INCIDENTE4.jpg", "INCIDENTE5.jpg", "INCIDENTE6.jpg", "INCIDENTE7.jpg", "INCIDENTE8.jpg", "INCIDENTE9.jpg", "INCIDENTE10.jpg", "INCIDENTE11.jpg", 
			"LOTUS1.jpg", "LOTUS2.jpg", "LOTUS3.jpg", "LOTUS4.jpg", "LOTUS5.jpg", "LOTUS6.jpg", 
			"AUTO-STORICA1.jpg", "AUTO-STORICA2.jpg", "AUTO-STORICA3.jpg", "AUTO-STORICA4.jpg", 
			"MOTO1.jpg", "MOTO2.jpg", "MOTO3.jpg", "MOTO4.jpg",
			"CRISTALLI1.jpg", "CRISTALLI2.jpg", "CRISTALLI3.jpg", "CRISTALLI4.jpg", 
			"GRANDINE1.jpg", "GRANDINE2.jpg", "GRANDINE3.jpg", "GRANDINE4.jpg",
			"ATTO-VAND1.jpg", "ATTO-VAND2.jpg", "ATTO-VAND3.jpg", "ATTO-VAND4.jpg", "ATTO-VAND5.jpg", "ATTO-VAND6.jpg"
		];
		
		let coverImages = {
			officina: this.getAppContext().getImage('covers/officina2.jpg'),
			chi_siamo: this.getAppContext().getImage('covers/chi_siamo.jpg'),
			servizi1: this.getAppContext().getImage('covers/servizi1.jpg'),
			servizi2: this.getAppContext().getImage('covers/servizi2.jpg')
		}
		
		let serviceImages = {
			preventivi_gratuiti: this.getAppContext().getImage('services/preventivi_gratuiti.jpg'),
			auto_cortesia: this.getAppContext().getImage('services/auto_cortesia.jpg'),
			cristalli: this.getAppContext().getImage('services/cristalli.jpg'),
			mini_dime: this.getAppContext().getImage('services/mini_dime.jpg'),
			presa_in_consegna: this.getAppContext().getImage('services/presa_in_consegna.jpg'),
			gestione_sinistro: this.getAppContext().getImage('services/gestione_sinistro2.jpg')
		}
		
		let servizi = [
			{
				titolo: "RIPARAZIONI IN GIORNATA",
				foto: serviceImages.cristalli,
				testo: ((this.context.app.app_ready && this.context.app.settingsData.servizi.riparazioni_in_giornata) || "")
			},
			{
				titolo: "TECNOLOGIE AVANZATE",
				foto: serviceImages.mini_dime,
				testo: ((this.context.app.app_ready && this.context.app.settingsData.servizi.tecnologie_avanzate) || "")
			},
			{
				titolo: "GESTIONE APERTURA SINISTRO",
				foto: serviceImages.gestione_sinistro,
				testo: ((this.context.app.app_ready && this.context.app.settingsData.servizi.gestione_pratiche) || "")
			},
			{
				titolo: "PREVENTIVI GRATUITI",
				foto: serviceImages.preventivi_gratuiti,
				testo: ((this.context.app.app_ready && this.context.app.settingsData.servizi.preventivi_gratuiti) || "")
			},
			{
				titolo: "PRESA E CONSEGNA",
				foto: serviceImages.presa_in_consegna,
				testo: ((this.context.app.app_ready && this.context.app.settingsData.servizi.presa_e_consegna) || "")
			},
			{
				titolo: "AUTO DI CORTESIA",
				foto: serviceImages.auto_cortesia,
				testo: ((this.context.app.app_ready && this.context.app.settingsData.servizi.auto_cortesia) || "")
			}
		];
		
		return (
			<div>
				<_AnchorPoint anchorId="home"/>
				<Section
					id="home"
					designType="wide"
					className="text-center"
					backgroundImage={coverImages.officina}
					backgroundVAlign="center"
					style={{
						color: this.getAppContext().colors.light
					}}
				>
					<Section
						designType="wide"
						backgroundColor="rgba(0, 0, 0, 0.3)"
					>
						<div
							style={{
							//	fontSize: '60px',
								padding: '100px 0 50px 0',
								fontWeight: '600',
							//	lineHeight: '1'
							}}
						>
							<h1 className="d-none d-sm-block display-3" style={{fontWeight: '600'}}>AUTOCARROZZERIA<br/>PIER DELLA FRANCESCA</h1>
							<h1 className="d-sm-none d-md-none d-lg-none d-xl-none" style={{fontWeight: '600'}}>AUTOCARROZZERIA<br/>PIER DELLA FRANCESCA</h1>
						</div>
{/*
						<Section
						//	layoutManager="columns"
							className="text-center"
						//	backgroundColor="rgba(0, 0, 0, 0.5)"
							style={{
								color: '#FFF',
								padding: '0 0 30px 0',
								width: '600px',
								margin: '0 auto',
							}}
						>
							
						</Section>
*/}

						<Section
							style={{
								fontSize: '25px',
							}}
						>
							<div
								style={{
									padding: '25px 30px',
								}}
							>
								<strong>Passione</strong>, <strong>Professionalità</strong>, <strong>Qualità</strong>
							</div>
						</Section>

						<div
							style={{
								height: '8px',
								width: '300px',
								margin: '0 auto',
								backgroundColor: this.getAppContext().colors.primary
							}}
						/>
						
						<Section
							style={{
								padding: '25px 0 30px 0'
							}}
						>
							<div style={{fontSize: '50px'}} className="icon-circle-down" onClick={() => {
								this.getAppContext().getActionsShelfService().do('navigationMenu', 'triggerNavMenuItem', '/home#chisiamo')
								//this.getAppContext()._REGISTERED_ACTIONS.triggerNavMenuItem.call("/home#chisiamo");
							}}/>
							<div>Scopri di più!</div>
						</Section>

{/*
						<h1>HOME</h1>
						<button className="btn btn-success"
							onClick={() => {
								console.log("Sign in");
								this.context.app.authHandler.login({
									user: 'neelvio',
									password: 'test'
								});
							}}
						>
							Sign in
						</button>
						<button className="btn btn-success"
							onClick={() => {
								console.log("Sign in");
								this.context.app.authHandler.logout();
							}}
						>
							Sign out
						</button>
						<button className="btn btn-success"
							onClick={() => {
								this.context.app._REGISTERED_ACTIONS.test.call();
							}}
						>
							Test
						</button>
						<div>
							{this.context.app._DEBUG_data ? JSON.stringify(this.context.app._DEBUG_data) : ''}
						</div>

						<button className="btn btn-success"
							onClick={() => {
								this.context.app.testLoad();
							}}
						>
							Test Load
						</button>
*/}
					</Section>
				
				
					
				</Section>
				
				<Section
					designType="wide"
				//	backgroundStyle='parallax'
				//	parallaxStrenght={300}
				//	backgroundImage={}
				//	backgroundFixed={true}
					backgroundColor={this.getAppContext().colors.primary}
					style={{
						color: this.getAppContext().colors.light,
						padding: '20px',
					//	borderBottom: '8px solid ' + this.getAppContext().colors.light,
					}}
				>
					<Section
						layoutManager="grid"
						elementsPerRow={this.state.isMobile ? 1 : 3}
						gridSpacing={1}
						gridSpacingColor={this.getAppContext().colors.light}
					>
						<Section
							style={{
								padding: this.state.isMobile ? '0 20px 20px 20px' : '0 20px'
							}}
						>
							<strong><span className="icon-clock-outline" style={{marginRight: '8px'}}/> ORARI</strong>
							<div style={{color: '#d8d8d8'}}>dal Lunedì al Venerdì <br/> 8.30-12.30 / 14.00-19.00</div>
						</Section>
						<Section
							style={{
								padding: this.state.isMobile ? '20px' : '0 20px',
							}}
						>
							<strong><span className="icon-location" style={{marginRight: '8px'}}/> VIENI A TROVARCI</strong>
							<div style={{color: '#d8d8d8'}}><a style={{color: 'inherit', textDecoration: 'underline'}} href="https://www.google.com/maps/dir//Carrozzeria+Pier+Della+Francesca+Snc+Di+Stomboli+E+Zotti,+Via+Balangero,+26,+10149+Torino+TO/@45.0909047,7.6536694,16.38z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47886c4d6c5c989f:0xcf17fffea067255a!2m2!1d7.6574698!2d45.0907277!3e3" target="_blank">Via Balangero 26/A 10149 TORINO</a></div>
						</Section>
						<Section
							style={{
								padding: this.state.isMobile ? '20px 20px 0 20px' : '0 20px'
							}}
						>
							<strong><span className="icon-phone" style={{marginRight: '8px'}}/> CHIAMACI</strong>
							<div style={{color: '#d8d8d8'}}>Tel./Fax: <a style={{color: '#d8d8d8', textDecoration: 'underline'}} href="tel:011 77.70.108">011 77.70.108</a></div>
						</Section>
					</Section>
				</Section>
				
				<_AnchorPoint anchorId="chisiamo"/>
				{/*
				<Section
					designType="wide"
				//	backgroundStyle='parallax'
				//	parallaxStrenght={300}
				//	backgroundImage={}
					backgroundColor={this.getAppContext().colors.primary}
					style={{
						height: '8px'
					}}
				/>								
				*/}
				<Section
					id="chisiamo"
					designType="wide"
					backgroundColor={this.getAppContext().colors.dark}
				>
					<Section
						designType="wide"
						layoutManager="columns"
						backgroundStyle='parallax'
					//	parallaxBlur={5}
						parallaxStrenght={200}
						backgroundImage={coverImages.chi_siamo}
						backgroundVAlign="center"
					//	className="bg-dark"
					>
						
						<Section
						//	backgroundStyle='parallax'
						//	parallaxStrenght={300}
						//	backgroundImage={}
							backgroundColor="rgba(0, 0, 0, 0.6)"
							className="text-right"
							style={{
								color: this.getAppContext().colors.light
							}}
						>
							<div className="row no-gutters">
								<div className="col-12 col-sm-10 col-md-8 offset-sm-2 offset-md-4">		{/* offset-sm-2 offset-md-4 */}
									<div
										style={{
											padding: '30px 40px'
										}}
									>
										<Section
											designType="wide"
											style={{
												height: 'auto',
												padding: '20px 0',
												fontSize: '40px',
											}}
										>
											<div
												style={{
													padding: '20px 0',
												}}
											>
												<strong className="d-none d-sm-block">CHI SIAMO</strong>
												<strong className="d-sm-none d-md-none d-lg-none d-xl-none" style={{fontSize: '30px'}}>CHI SIAMO</strong>
											</div>
										</Section>
										<div
											style={{
												height: '8px',
												width: '300px',
												margin: '0 0 0 auto',
												backgroundColor: this.getAppContext().colors.primary
											}}
										/>
										<Section
											designType="wide"
											style={{
												padding: '30px 0'
											}}
										>
											<div dangerouslySetInnerHTML={{__html: ((this.context.app.app_ready && this.context.app.settingsData.chi_siamo.bio) || "")}}/>
										</Section>
									</div>
								</div>
							</div>
						</Section>
						<Section 
							className="text-right " // bg-dark
							VStretch={true}
						//	layoutManager="columns"
						//	backgroundStyle='parallax'
						//	parallaxStrenght={200}
						//	backgroundImage={coverImages.officina2}
						//	backgroundFixed={true}
						//	backgroundColor="rgba(255, 255, 255, 0.6)"
							style={{
								color: '#FFF',
								//padding: '150px 50px',
								//maxWidth: '980px'
							}}
						>
							<div className="" style={{
								padding: '50px'
							}}>
								<Section
									VStretch={true}
								//	backgroundImage={}
									style={{
										
									}}
								>
								</Section>
							</div>
						</Section>
					</Section>
				</Section>
				<Section
					designType="wide"
				//	backgroundStyle='parallax'
				//	parallaxStrenght={300}
				//	backgroundImage={}
					backgroundColor={this.getAppContext().colors.light}
					className="text-center"
				>
					<Section
						style={{
							padding: '20px 0',
							fontSize: '25px',
						}}
					>
						<div
							style={{
								padding: '20px 30px',
							}}
						>
							<strong><div dangerouslySetInnerHTML={{__html: ((this.context.app.app_ready && this.context.app.settingsData.chi_siamo.slogan) || "")}}/></strong>
						</div>
					</Section>
					<div
						style={{
							height: '8px',
							width: '300px',
							margin: '0 auto',
							backgroundColor: this.getAppContext().colors.primary
						}}
					/>
					<Section
						style={{
							padding: '30px'
						}}
					>
						<div dangerouslySetInnerHTML={{__html: ((this.context.app.app_ready && this.context.app.settingsData.chi_siamo.slogan_desc) || "")}}/>
					</Section>
				</Section>
				
				
				
				<_AnchorPoint anchorId="servizi"/>
				<Section
					id="servizi"
					designType="wide"
				//	backgroundStyle='parallax'
				//	parallaxStrenght={300}
				//	backgroundImage={}
					style={{
						position: 'relative'
					}}
				>
				
					<Section
						VStretch={true}
						designType="wide"
					//	backgroundColor={this.getAppContext().colors.dark}
						backgroundStyle='parallax'
					//	parallaxBlur={5}
						parallaxStrenght={300}
						backgroundFixed="fixed"
						backgroundImage={coverImages.servizi2}
						backgroundVAlign="center"
						style={{
							position: 'absolute',
							zIndex: '10',
							top: '0',
							height: '500px',
							left: '0',
							width: '100%'
						}}
					>
						<Section designType="wide" className="h-100" backgroundColor="rgba(0, 0, 0, 0.3)"/>
					</Section>
					<Section
						designType="wide"
						backgroundColor={this.getAppContext().colors.dark}
					//	backgroundStyle='parallax'
					//	parallaxStrenght={300}
					//	backgroundImage={}
						style={{
							position: 'absolute',
							zIndex: '10',
							top: '500px',
							bottom: '0',
							left: '0',
							width: '100%'
						}}
					/>
					
					
					<Section
						designType="wide"
					//	backgroundColor="rgba(0, 0, 0, 0.3)"
						style={{
							position: 'relative',
							zIndex: '20'
						}}
					>
						<Section
							className="text-center"
							style={{
								padding: '20px 0'
							}}
						>
							<div
								style={{
									color: this.getAppContext().colors.light,
									padding: '20px 30px',
									fontSize: '40px'
								}}
							>
								<strong className="d-none d-sm-block">SERVIZI</strong>
								<strong className="d-sm-none d-md-none d-lg-none d-xl-none" style={{fontSize: '30px'}}>SERVIZI</strong>
							</div>
						</Section>
						<div
							style={{
								height: '8px',
								maxWidth: '300px',
								margin: '0 auto',
								backgroundColor: this.getAppContext().colors.primary
							}}
						/>
						<Section
							designType="wide"
						>
						
							<Section
								layoutManager="grid"
								elementsPerRow={this.state.isMobile ? 1 : 2}
								gridSpacing={this.state.isMobile ? 30 : 10}
								style={{
									marginTop: '30px'
								}}
							>
								<Section
									className="text-center h-100 d-flex flex-column rounded"
									style={{
										backgroundColor: this.getAppContext().colors.light
									}}
								>
									<Section
										height={200}
										className="rounded-top"
										designType="wide"
									//	backgroundStyle="parallax"
										backgroundImage={servizi[0].foto}
										backgroundVAlign="top"
									/>
									<Section
										designType="wide"
										backgroundColor={this.getAppContext().colors.primary}
										style={{
											color: this.getAppContext().colors.light,
											padding: '20px',
											fontSize: '20px'
										}}
									>
										<strong>{servizi[0].titolo}</strong>
									</Section>
									<Section
										className="text-center align-self-stretch"
										style={{
											padding: '20px'
										}}
									>
										<div dangerouslySetInnerHTML={{__html: ((servizi[0].testo) || "")}}/>
									</Section>
								</Section>
								<Section
									className="text-center h-100 d-flex flex-column rounded"
									style={{
										backgroundColor: this.getAppContext().colors.light
									}}
								>
									<Section
										height={200}
										className="rounded-top"
										designType="wide"
										backgroundImage={servizi[1].foto}
										backgroundVAlign="top"
									/>
									<Section
										designType="wide"
										backgroundColor={this.getAppContext().colors.primary}
										style={{
											color: this.getAppContext().colors.light,
											padding: '20px',
											fontSize: '20px'
										}}
									>
										<strong>{servizi[1].titolo}</strong>
									</Section>
									<Section
										className="text-center align-self-stretch"
										style={{
											padding: '20px'
										}}
									>
										<div dangerouslySetInnerHTML={{__html: ((servizi[1].testo) || "")}}/>
									</Section>
								</Section>
							</Section>
							
							
							
							<Section
								layoutManager="grid"
								elementsPerRow={this.state.isMobile ? 1 : 2}
								gridSpacing={this.state.isMobile ? 30 : 10}
								style={{
									marginTop: '30px'
								}}
							>
								<Section
									className="text-center h-100 d-flex flex-column rounded"
									style={{
										backgroundColor: this.getAppContext().colors.light
									}}
								>
									<Section
										height={200}
										className="rounded-top"
										designType="wide"
										backgroundImage={servizi[2].foto}
										backgroundVAlign="center"
									/>
									<Section
										designType="wide"
										backgroundColor={this.getAppContext().colors.primary}
										style={{
											color: this.getAppContext().colors.light,
											padding: '20px',
											fontSize: '20px'
										}}
									>
										<strong>{servizi[2].titolo}</strong>
									</Section>
									<Section
										className="text-center align-self-stretch"
										style={{
											padding: '20px'
										}}
									>
										<div dangerouslySetInnerHTML={{__html: ((servizi[2].testo) || "")}}/>
									</Section>
								</Section>
								<Section
									className="text-center h-100 d-flex flex-column rounded"
									style={{
										backgroundColor: this.getAppContext().colors.light
									}}
								>
									<Section
										height={200}
										className="rounded-top"
										designType="wide"
										backgroundImage={servizi[3].foto}
										backgroundVAlign="top"
									/>
									<Section
										designType="wide"
										backgroundColor={this.getAppContext().colors.primary}
										style={{
											color: this.getAppContext().colors.light,
											padding: '20px',
											fontSize: '20px'
										}}
									>
										<strong>{servizi[3].titolo}</strong>
									</Section>
									<Section
										className="text-center align-self-stretch"
										style={{
											padding: '20px'
										}}
									>
										<div dangerouslySetInnerHTML={{__html: ((servizi[3].testo) || "")}}/>
									</Section>
								</Section>
							</Section>
							
							
							
							<Section
								layoutManager="grid"
								elementsPerRow={this.state.isMobile ? 1 : 2}
								gridSpacing={this.state.isMobile ? 30 : 10}
								style={{
									marginTop: '30px'
								}}
							>
								<Section
									className="text-center h-100 d-flex flex-column rounded"
									style={{
										backgroundColor: this.getAppContext().colors.light
									}}
								>
									<Section
										height={200}
										className="rounded-top"
										designType="wide"
										backgroundImage={servizi[4].foto}
										backgroundVAlign="bottom"
									/>
									<Section
										designType="wide"
										backgroundColor={this.getAppContext().colors.primary}
										style={{
											color: this.getAppContext().colors.light,
											padding: '20px',
											fontSize: '20px'
										}}
									>
										<strong>{servizi[4].titolo}</strong>
									</Section>
									<Section
										className="text-center align-self-stretch"
										style={{
											padding: '20px'
										}}
									>
										<div dangerouslySetInnerHTML={{__html: ((servizi[4].testo) || "")}}/>
									</Section>
								</Section>
								<Section
									className="text-center h-100 d-flex flex-column rounded"
									style={{
										backgroundColor: this.getAppContext().colors.light
									}}
								>
									<Section
										height={200}
										className="rounded-top"
										designType="wide"
										backgroundImage={servizi[5].foto}
										backgroundVAlign="center"
									/>
									<Section
										designType="wide"
										backgroundColor={this.getAppContext().colors.primary}
										style={{
											color: this.getAppContext().colors.light,
											padding: '20px',
											fontSize: '20px'
										}}
									>
										<strong>{servizi[5].titolo}</strong>
									</Section>
									<Section
										className="text-center align-self-stretch"
										style={{
											padding: '20px'
										}}
									>
										<div dangerouslySetInnerHTML={{__html: ((servizi[5].testo) || "")}}/>
									</Section>
								</Section>
							</Section>
							
							
						</Section>
						
						<Section
							className="mt-4"
							designType="wide"
						//	backgroundColor={this.getAppContext().colors.light}
						>
							<Section
								
								className="text-left"
								style={{
									color: this.getAppContext().colors.light,
								}}
							>
								<Section
									style={{
										padding: '20px',
										fontSize: '20px'
									}}
								>
									<strong>STRUTTURA</strong>
								</Section>
								<Section
									style={{
										padding: '20px'
									}}
								>											
									<div dangerouslySetInnerHTML={{__html: ((this.context.app.app_ready && this.context.app.settingsData.servizi.struttura) || "")}}/>
								</Section>
							</Section>
							
							<Section
								className="text-right"
								style={{
									color: this.getAppContext().colors.light,
								}}
							>
								<Section
									style={{
										padding: '20px',
										fontSize: '20px'
									}}
								>
									<strong>GARANZIE</strong>
								</Section>
								<Section
									style={{
										padding: '20px'
									}}
								>
									<div dangerouslySetInnerHTML={{__html: ((this.context.app.app_ready && this.context.app.settingsData.servizi.garanzie) || "")}}/>
								</Section>
							</Section>
						</Section>
					</Section>
				</Section>











{/*
				<Section
					designType="wide"
					layoutManager="columns"
					height={200}
				>
					<Section
						height={200}
						backgroundImage={}
					>
					</Section>
					<Section
						height={200}
					>
					</Section>
				</Section>
				<Section
					designType="wide"
					layoutManager="columns"
					height={200}
				>
					<Section
						height={200}
					>
					</Section>
					<Section
						height={200}
						backgroundImage={}
					>
					</Section>
				</Section>
				<Section
					designType="wide"
					layoutManager="columns"
					height={200}
				>
					<Section
						height={200}
						backgroundImage={}
					>
					</Section>
					<Section
						height={200}
					>
					</Section>
				</Section>
				<Section
					designType="wide"
					layoutManager="columns"
					height={200}
				>
					<Section
						height={200}
					>
					</Section>
					<Section
						height={200}
						backgroundImage={}
					>
					</Section>
				</Section>
				<Section
					designType="wide"
					layoutManager="columns"
					height={200}
				>
					<Section
						height={200}
						backgroundImage={}
					>
					</Section>
					<Section
						height={200}
					>
					</Section>
				</Section>
				<Section
					designType="wide"
					layoutManager="columns"
					height={200}
				>
					<Section
						height={200}
					>
					</Section>
					<Section
						height={200}
						backgroundImage={}
					>
					</Section>
				</Section>
*/}
				<_AnchorPoint anchorId="gallery"/>
				<Section
					id="gallery"
					designType="wide"
					backgroundColor={this.getAppContext().colors.dark}
				>
					<Section
						className="text-center"
						style={{
							padding: '20px 0'
						}}
					>
						<div
							style={{
								color: this.getAppContext().colors.light,
								padding: '20px 30px',
								fontSize: '40px'
							}}
						>
							<strong className="d-none d-sm-block">I NOSTRI LAVORI</strong>
							<strong className="d-sm-none d-md-none d-lg-none d-xl-none" style={{fontSize: '30px'}}>I NOSTRI LAVORI</strong>
						</div>
					</Section>
					<div
						style={{
							height: '8px',
							maxWidth: '300px',
							margin: '0 auto',
							backgroundColor: this.getAppContext().colors.primary
						}}
					/>
					<Section
					//	designType="wide"
						style={{
							padding: '30px 0'
						}}
					>
						<Gallery
							carouselTitle="I nostri lavori"
							images={galleryImages}
						/>
					</Section>
				</Section>
				
				<_AnchorPoint anchorId="convenzioni"/>
				<Section
					id="convenzioni"
					designType="wide"
				//	backgroundStyle='parallax'
				//	parallaxStrenght={300}
					className="bg-light text-center"
				>
					<Section
						style={{
							padding: '20px 0'
						}}
					>
						<div
							style={{
								padding: '20px 30px',
								fontSize: '40px'
							}}
						>
							<strong className="d-none d-sm-block">FIDUCIARI CONVENZIONATI</strong>
							<strong className="d-sm-none d-md-none d-lg-none d-xl-none" style={{fontSize: '30px'}}>FIDUCIARI CONVENZIONATI</strong>
						</div>
					</Section>
					<div
						style={{
							height: '8px',
							maxWidth: '300px',
							margin: '0 auto',
							backgroundColor: this.getAppContext().colors.primary
						}}
					/>
					<Section
						style={{
							padding: '30px 30px'
						}}
					>
						<div>
							L&rsquo;autocarrozzeria è concordataria con tutte le società di assicurazione e fiduciaria con compagnie quali
						</div>
					</Section>
					
					<div style={{
						padding: '0 30px',
						maxWidth: '750px',
						margin: '0 auto'
					}}>
					
						<div className="row ">
							<div className="col-6 col-sm-4 col-md-3">
								<img src={conv_amissima} width="100%"/>
							</div>
							<div className="col-6 col-sm-4 col-md-3">
								<img src={conv_ariscom} width="100%"/>
							</div>
							<div className="col-6 col-sm-4 col-md-3">
								<img src={conv_authoitalis} width="100%"/>
							</div>
							<div className="col-6 col-sm-4 col-md-3">
								<img src={conv_autoprestobene} width="100%"/>
							</div>
							<div className="col-6 col-sm-4 col-md-3">
								<img src={conv_leaseplan} width="100%"/>
							</div>
							<div className="col-6 col-sm-4 col-md-3">
								<img src={conv_nobis} width="100%"/>
							</div>
							<div className="col-6 col-sm-4 col-md-3">
								<img src={conv_promise} width="100%"/>
							</div>
							<div className="col-6 col-sm-4 col-md-3">
								<img src={conv_unipol} width="100%"/>
							</div>
						</div>

					</div>
				</Section>
				
				<_AnchorPoint anchorId="usato"/>
				<Section
					id="usato"
					designType="wide"
				//	backgroundStyle='parallax'
				//	parallaxStrenght={300}
					className="bg-light text-center"
				>
					<Section
						style={{
							padding: '20px 0'
						}}
					>
						<div
							style={{
								padding: '20px 30px',
								fontSize: '40px'
							}}
						>
							<strong className="d-none d-sm-block">IL NOSTRO USATO</strong>
							<strong className="d-sm-none d-md-none d-lg-none d-xl-none" style={{fontSize: '30px'}}>IL NOSTRO USATO</strong>
						</div>
					</Section>
					<div
						style={{
							height: '8px',
							maxWidth: '300px',
							margin: '0 auto',
							backgroundColor: this.getAppContext().colors.primary
						}}
					/>
					{/*
					<Section
						style={{
							padding: '30px 30px'
						}}
					>
						<div>
							I nostri usati sono belli e garantiti perchè siamo super fighi e bravi! ^_^
						</div>
					</Section>
					*/}
					<Section className="mt-4 mb-3">
						<_LoadingWrapper
							minHeight
							loadingPlug={this.state.loading_products}
						>
							<Section>
								{(this.state.products || []).map((_C_product, _C_idx) => {
									return (
										<Section
											key={_C_idx}
											className="text-left h-100 d-flex flex-column mb-3 rounded"
											style={{
												backgroundColor: this.getAppContext().colors.dark
											}}
										>
											<Section
												className="p-3 rounded-top"
												designType="wide"
												backgroundColor={this.getAppContext().colors.primary}
												style={{
													color: this.getAppContext().colors.light,
													fontSize: '20px'
												}}
											>
												<div>
													<strong>{_C_product.title}</strong>
												</div>
												<div>
													<small className="text-light">{_C_product.subtitle}</small>
												</div>
											</Section>
											
											<div className="p-3">
												<Row>
													<Col xs={12} sm={6}>
														<Gallery
															absPath
															elementsPerRow={4}
															carouselTitle={_C_product.title}
															max={8}
															images={_C_product.photos.map((_C_photo) => {return this.getAppContext().conf.API_URL + '/utils/photostream/' + _C_photo.filename + '/1'})}
														/>
														
														<div
															className="text-light mt-3 mb-3"
														>
															<div dangerouslySetInnerHTML={{__html: ((_C_product.description.replace(/\n/g, '<br/>')) || "")}}/>
														</div>
													</Col>
													<Col xs={12} sm={6}>
														<Row className="mb-2">
															<Col className="text-light">
																<strong><span className="icon-calendar mr-2"/>ANNO</strong>
																<div>
																	{_C_product.year || '-'}
																</div>
															</Col>
															<Col className="text-light">
																<strong><span className="icon-water-drop mr-2"/>ALIMENTAZIONE</strong>
																<div>
																	{_C_product.fuel || '-'}
																</div>
															</Col>
														</Row>
														<Row>
															<Col className="text-light">
																<strong><span className="icon-road mr-2"/>CHILOMETRI</strong>
																<div>
																	{_C_product.kilometres || '-'} Km
																</div>
															</Col>
															<Col className="text-light">
																<strong><span className="icon-euro mr-2 align-middle"/>PREZZO</strong>
																<div>
																	{_C_product.price || '-'} €
																</div>
															</Col>
														</Row>
														
														{_C_product.equipments && _C_product.equipments.length ? (
															<div className="mt-3 text-light">
																<div className="mb-2">
																	<strong>EQUIPAGGIAMENTI</strong>
																</div>
																<Section
																	designType="wide"
																	layoutManager="grid"
																	elementsPerRow={2}
																	gridSpacing={10}
																>
																	{_C_product.equipments.map((_C_equipment, _C_idx) => {
																		return (
																			<div key={_C_idx}>
																				<span className="icon-list-dot mr-2 text-light" style={{fontSize: '60%'}}/>{_C_equipment}
																			</div>
																		)
																	})}
																</Section>
															</div>
														) : null}
													</Col>
												</Row>
												
												<Row className="mt-4">
													<Col>
														<ReactOActionBar align="right" padding="0px">
															<ReactOButton onClick={() => {this._product_info_modal.open(_C_product)}} icon="question-circle" color="primary">
																Richiedi informazioni
															</ReactOButton>
														</ReactOActionBar>
													</Col>
												</Row>
											</div>
											
											
										</Section>
									)
								})}
							</Section>
						</_LoadingWrapper>
					</Section>
				</Section>
				
				<_AnchorPoint anchorId="contatti"/>
				<Section
					id="contatti"
					designType="wide"
					backgroundColor={this.getAppContext().colors.dark}
					style={{
						color: this.getAppContext().colors.light
					}}
				>
					<Section
						className="text-center"
						style={{
							padding: '20px 0'
						}}
					>
						<div
							style={{
								padding: '20px 0',
								fontSize: '40px'
							}}
						>
							<strong className="d-none d-sm-block">CONTATTI</strong>
							<strong className="d-sm-none d-md-none d-lg-none d-xl-none" style={{fontSize: '30px'}}>CONTATTI</strong>
						</div>
					</Section>
					<div
						style={{
							height: '8px',
							maxWidth: '300px',
							margin: '0 auto',
							backgroundColor: this.getAppContext().colors.primary
						}}
					/>
					<Section
						designType="wide"
						className="text-center"
						style={{
							padding: '30px 0 0 0'
						}}
					>
						{/*
							<a href="https://maps.google.com/maps?ll=44.975653,7.712758&z=14&t=m&hl=en&gl=US&mapclient=embed&daddr=Via%20Fortunato%20Postiglione%20Inrete%20%2C%2029%2010024%20Moncalieri%20TO@44.97554,7.713629999999999" target="_blank">
								<img src="../../images/page_images/come-raggiungerci.jpg" alt="Find us"/>
							</a>
						*/}
						
						
						<GoogleMaps
							containerHeight={400}
							centerToSelectedMarker
										
							markerPopupRender={(overMarker) => {
								return (
									<div
										className="shadow"
									>
										<Panel
											className={"rounded border-0" + (!overMarker.hide ? ' d-block' : ' d-none')}
										>
											<div>
												<h4 className="text-primary">{overMarker.title}</h4>
												<u><a className="text-dark" href="https://www.google.com/maps/dir//Carrozzeria+Pier+Della+Francesca+Snc+Di+Stomboli+E+Zotti,+Via+Balangero,+26,+10149+Torino+TO/@45.0909047,7.6536694,16.38z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47886c4d6c5c989f:0xcf17fffea067255a!2m2!1d7.6574698!2d45.0907277!3e3" target="_blank"><span>Via Balangero 26/A 10149 TORINO</span></a></u>
											</div>
										</Panel>
									</div>
								)
							}}
							
							markers={[
								{
									data: "Via Balangero 26/A 10149 TORINO",
									title: "Siamo qui!",
								//	defaultIcon: _probeData.sync_status == 0 ? 'error' : 'success',
									animation: false,
									keepSelected: true,
									position: {
										lat: 45.09075,
										lng: 7.657487600000001
									},
									onClick: (googleMaps, map, marker) => {
									//	console.log("MMMMM CLICKED: " + marker.title);
									//	console.log(marker);
									//	console.log(map);
										window.open("https://www.google.com/maps/dir//Carrozzeria+Pier+Della+Francesca+Snc+Di+Stomboli+E+Zotti,+Via+Balangero,+26,+10149+Torino+TO/@45.0909047,7.6536694,16.38z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47886c4d6c5c989f:0xcf17fffea067255a!2m2!1d7.6574698!2d45.0907277!3e3");
									},
									onMouseOver: (googleMaps, map, marker) => {
									//	console.log("MMMMM OVER: " + marker.title);
									//	console.log(marker);
									//	console.log(map);
									},
									onLoad: (googleMaps, map, marker) => {
									//	console.log("MMMMM LOADED: " + marker.title);
									//	console.log(marker);
									//	console.log(map);
									//	marker.setCustomIcon(marker.data.sync_status == 0 ? 'error' : 'success');
									}
								}
							]}
							onControllerReady={(_maps_controller) => {
								this._maps_controller = _maps_controller;
							}}
						/>
						
						<Section
							designType="wide"
							backgroundColor="rgba(0, 0, 0, 0.8)"
							style={{
								position: 'relative',
								height: '14px',
								marginTop: '-14px',
								marginLeft: 'auto',
								width: '403px'
							}}
						/>
					</Section>
					<Section
						designType="wide"
					//	backgroundStyle='parallax'
					//	parallaxStrenght={300}
					//	backgroundImage={}
						backgroundColor={this.getAppContext().colors.primary}
						style={{
							height: '8px'
						}}
					/>
					<Section
						layoutManager="grid"
						elementsPerRow={this.state.isMobile ? 1 : 2}
						style={{
							padding: '30px 0'
						}}
					>
						<Section
							className="text-left"
							style={{
								padding: '10px 25px'
							}}
						>
							<div>
								<p>
									<strong>AUTOCARROZZERIA PIER DELLA FRANCESCA</strong>
								</p>
								<p>
									<span className="icon-location" style={{marginRight: '8px'}}/> <a style={{color: 'inherit', textDecoration: 'underline'}} href="https://www.google.com/maps/dir//Carrozzeria+Pier+Della+Francesca+Snc+Di+Stomboli+E+Zotti,+Via+Balangero,+26,+10149+Torino+TO/@45.0909047,7.6536694,16.38z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47886c4d6c5c989f:0xcf17fffea067255a!2m2!1d7.6574698!2d45.0907277!3e3" target="_blank">Via Balangero 26/A 10149 TORINO</a>
								</p>
								<p>
									<span className="icon-phone" style={{marginRight: '8px'}}/> <a style={{color: 'inherit', textDecoration: 'underline'}} href="tel:011 77.70.108">011 77.70.108</a>
								</p>
								<p>
									<span className="icon-envelop" style={{marginRight: '8px'}}/> <a style={{color: 'inherit', textDecoration: 'underline'}} href="mailto:carrozzeria.pdf@gmail.com">carrozzeria.pdf@gmail.com</a>
								</p>
								<p>
									<span className="icon-clock" style={{marginRight: '8px'}}/> dal Lunedì al Venerdì  |  8.30-12.30 / 14.00-19.00
								</p>
								
							</div>
						</Section>
						<Section
							className="contact-form-section p-2"
							style={{
							}}
						>
							<ContactForm darkRecaptcha/>
						</Section>
					</Section>
				</Section>
				
				
				<Section
					className="text-center"
					designType="wide"
					backgroundColor={this.getAppContext().colors.dark}
					style={{
						color: '#777',
						fontSize: '0.9rem',
						padding: '10px 0'
					}}
				>
					<div>Carrozzeria Pier della Francesca S.R.L. | Sede legale Via Balangero 26/A 10149 - 10128 TORINO | Capitale sociale: € 10.000,00 i.v. | P.IVA: 07576860014 | <a style={{color: 'inherit', textDecoration: 'underline'}} href="/privacy">Privacy e cookie</a></div>
				</Section>
				
				
				
				
				
				<ReactOModal
					modalId="productInformation"
					size="lg"
					loading={this.state.loading_product_info}
					onControllerReady={(modal_context) => {
						this._product_info_modal = modal_context;
					}}
					onModalMounted={(data) => {
					}}
					onModalUnmounted={() => {
					}}
					actions={[
						{
							text: 'Chiudi',
							color: 'light',
							action: 'close'
						},
						(data) => {
							return {
								text: 'Invia',
								color: 'primary',
								icon: 'send',
								disabled: !this.state.contact_form_valid,
								action: () => {
									this._product_info_contact_form.sendMessage(() => {
										this._product_info_modal.close();
										this._product_info_confirm_modal.open();
									});
								}
							}
						}
					]}
					header={(data) => {return data.title}}
					body={(data) => {
						return data.photos ? (
							<div>
								<Panel rounded className="mb-3">
									<Row>
										<Col xs={12} sm={6}>
											<Gallery
												absPath
												elementsPerRow={3}
												carouselTitle={data.title}
												max={6}
												images={data.photos.map((_C_photo) => {return this.getAppContext().conf.API_URL + '/utils/photostream/' + _C_photo.filename + '/1'})}
											/>
											
											<div
												className="mt-3 mb-3"
											>
												<div dangerouslySetInnerHTML={{__html: ((data.description.replace(/\n/g, '<br/>')) || "")}}/>
											</div>
										</Col>
										<Col xs={12} sm={6}>
											<Row className="mb-2">
												<Col className="">
													<strong><span className="icon-calendar mr-2"/>ANNO</strong>
													<div>
														{data.year || '-'}
													</div>
												</Col>
												<Col className="">
													<strong><span className="icon-water-drop mr-2"/>ALIMENTAZIONE</strong>
													<div>
														{data.fuel || '-'}
													</div>
												</Col>
											</Row>
											<Row>
												<Col className="">
													<strong><span className="icon-road mr-2"/>CHILOMETRI</strong>
													<div>
														{data.kilometres || '-'} Km
													</div>
												</Col>
												<Col className="">
													<strong><span className="icon-euro mr-2 align-middle"/>PREZZO</strong>
													<div>
														{data.price || '-'} €
													</div>
												</Col>
											</Row>
											
											{data.equipments && data.equipments.length ? (
												<div className="mt-3">
													<div className="mb-2">
														<strong>EQUIPAGGIAMENTI</strong>
													</div>
													<Section
														designType="wide"
														layoutManager="grid"
														elementsPerRow={2}
														gridSpacing={10}
													>
														{data.equipments.map((_C_equipment, _C_idx) => {
															return (
																<div key={_C_idx}>
																	<span className="icon-list-dot mr-2" style={{fontSize: '60%'}}/>{_C_equipment}
																</div>
															)
														})}
													</Section>
												</div>
											) : null}
										</Col>
									</Row>
								</Panel>
								<ContactForm
									sendButton={false}
									messageRows={10}
									template='product_info'
									preformattedMessage={"Buongiorno,\nvorrei avere maggiori informazioni riguardo:\n\n" + data.title + "\nANNO: " + data.year + "\nALIMENTAZIONE: " + data.fuel + "\nCHILOMETRI: " + data.kilometres + "\nPRICE: " + data.price + "\n\n"}
									onValidChanged={(is_valid) => {
										this.setState({
											contact_form_valid: is_valid
										})
									}}
									onControllerReady={(contact_form_context) => {
										this._product_info_contact_form = contact_form_context;
									}}
								/>
							</div>
						) : null;
					}}
				/>
				
				<ReactOModal
					modalId="productInformationConfirm"
					size="md"
					onControllerReady={(modal_context) => {
						this._product_info_confirm_modal = modal_context;
					}}
					onModalMounted={(data) => {
					}}
					onModalUnmounted={() => {
					}}
					actions={[
						{
							text: 'Chiudi',
							color: 'light',
							action: 'close'
						}
					]}
					header="Mail inviata"
					body={(data) => {
						return (
							<div>
								<strong>Grazie!</strong>
								<div>Abbiamo ricevuto la tua mail.<br/>Ti contatteremo il prima possibile.</div>
							</div>
						)
					}}
				/>
				
			</div>
		);
	}
}

export default contextProvider(
	Home,
//	withDefinedContext(Home, 'Home', context_exposed_methods),
//	{
//		retrieve: ['_LayoutSidebar']
//	}
);
