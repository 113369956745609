import React from 'react';
import contextProvider from 'core/contextProvider';

import _ReactOBaseComponent, { withDefinedContext } from 'core/_L0_components/_ReactOBaseComponent';
import Section from 'core/_L1_components/Layout/Section';

class Privacy extends _ReactOBaseComponent {
	constructor(props) {
		super(props);
		this.state = {
		}
	}
	
	render() {
		return (
			<Section
				designType="wide"
				backgroundColor="#f8f9fa"
				style={{
				}}
			>
				<Section
					className="text-center"
					style={{
						padding: '20px 0'
					}}
				>
					<div
						style={{
							padding: '20px 30px',
							fontSize: '40px'
						}}
					>
						<strong className="d-none d-sm-block">PRIVACY E COOKIE</strong>
						<strong className="d-sm-none d-md-none d-lg-none d-xl-none" style={{fontSize: '30px'}}>PRIVACY E COOKIE</strong>
					</div>
				</Section>
				<div
					style={{
						height: '8px',
						maxWidth: '300px',
						margin: '0 auto',
						backgroundColor: "#a90b0b"
					}}
				/>
				<Section
					style={{
						padding: '30px 10px'
					}}
				>
				
				
					
				
				
				
					<div align="justify">
						<p>
					        <strong>INFORMATIVA WEB PRIVACY POLICY</strong>
					    </p>
					    
					    <p>
					    Si forniscono di seguito alcune informazioni ed avvertenze sulle modalità di gestione del sito www.carrozzeriapierdellafrancesca.it, in riferimento al trattamento dei dati personali. Si tratta di una informativa resa ai sensi dell’art. 13 del D. Lgs. 196/2003 – Codice in materia di protezione dei dati personali – a coloro che si collegano al presente sito. I visitatori dovranno leggere attentamente la presente Privacy Policy prima di inoltrare qualsiasi tipo di informazione personale e/o compilare qualunque modulo elettronico presente sul sito stesso.
						</p>
					    <p>
					        1. <strong>Finalità del trattamento</strong>
					        <br/> Il trattamento dei dati forniti è finalizzato unicamente alla corretta e completa esecuzione del servizio richiesto.
					    </p>
					    <p>
					        2. <strong>Trattamento dei dati personali</strong>
					        
					        
					        
					        <br/> Il trattamento sarà effettuato su supporto informatico. Il conferimento dei dati è facoltativo, l’eventuale rifiuto di fornire tali dati potrebbe comportare la mancata o parziale esecuzione del servizio. La titolarità dei dati, conferiti volontariamente, e trattati a seguito della consultazione del sito, è attribuita a Carrozzeria Pier della Francesca S.R.L. con sede legale in Via Balangero 26/A 10149 - 10128 TORINO (TO), che tratta i medesimi dati, oltre che per l&rsquo;ottimizzazione dell&rsquo;uso del sito, per le seguenti finalità:
					    </p>
					        <ul>
						        <li>invio di circolari informative, tecniche e/o commerciali sia in via telematica che cartacea</li>
						        <li>attività promozionali e/o di marketing in via cartacea e/o telematica</li>
						        <li>invio di listini, cataloghi, istruzioni tecniche, campionature, ecc…</li>
						        <li>compimento di ricerche di mercato</li>
						        <li>stesura budget e statistiche.</li>
						    </ul>
						<p>
					        I trattamenti vengono svolti dal titolare con modalità cartacee, informatiche, telematiche sui sistemi informatici e server nella propria disponibilità, dal personale (interno ed esterno) incaricato, tra cui il personale Carrozzeria Pier della Francesca S.R.L. che tratta i dati al fine di gestire nel modo migliore il sito con modalità informatiche e telematiche. Carrozzeria Pier della Francesca S.R.L. raccoglie i dati relativi agli accessi ed alla navigazione all&rsquo;interno dei siti per permettere il funzionamento dei servizi e delle sezioni che richiedono l&rsquo;identificazione dell&rsquo;utente e utilizza le informazioni raccolte per l&rsquo;amministrazione tecnica del sito e per eventuali analisi statistiche circa l&rsquo;utilizzo del sito stesso. Carrozzeria Pier della Francesca S.R.L. utilizza dati statistici globali sul tipo di traffico ed altre informazioni correlate al sito, senza diffondere o comunicare a terzi dati relativi alla singola utenza. Viene esclusa ogni forma di diffusione o comunicazione a terzi (salvo prestatori di servizi incaricati, nell&rsquo;ambito delle finalità dichiarate).
					    </p>
					    <p>
					        3. <strong>Diritti dell’interessato</strong>
					        <br/> I soggetti cui si riferiscono i dati personali hanno il diritto, in ogni momento, di verificare i dati trattati ed esercitare tutti i diritti previsti dall&rsquo;art . 7 del D.Lgs. n. 196/2003 (tra cui quelli di rettifica, aggiornamento e cancellazione) inviando una mail a carrozzeria.pdf@gmail.com.
					    </p>
					    <p>
					        4. <strong>Cookies</strong>
					        <br/> In questo sito non si utilizzano i c.d. “cookies” persistenti di alcun tipo, né si tracciano gli indirizzi IP, né ci si avvale di altri sistemi analoghi di tracciamento duraturo degli utenti. L’uso dei cookies di sessione (cioè temporanei che decadono con la chiusura della sessione del browser) è strettamente funzionale all&rsquo;ottimizzazione della fruizione del sito, e quindi a garantire la migliore navigazione nell&rsquo;ambito del sito. Altri siti su cui questo sito può eventualmente “linkare” potrebbero contenere sistemi di tracciamento cui il titolare del sito è estraneo. Non si garantisce che tali siti esterni siano dotati di idonei sistemi di sicurezza diretti a tutelare i dati trattati e per prevenire danni (ad es. da virus informatici).
					    </p>
					    <p>
					        5. <strong>Consenso ed informative specifiche</strong>
					        <br/> Nel sito, per particolari finalità di trattamento o per determinate tipologie di dati, sono presenti apposite informative e, ove necessario, anche richieste espresse di consenso.
					    </p>
					    <p>
					         6. <strong>Misure di sicurezza del sito</strong>
					        <br/> Per la gestione del sito sono state adottate specifiche misure di sicurezza, dirette a garantire l&rsquo;accesso sicuro ed a tutelare le informazioni contenute nello stesso da rischi di perdita o distruzione anche accidentale. Carrozzeria Pier della Francesca S.R.L. pur garantendo al suo interno l&rsquo;adozione di appositi sistemi Antivirus, ricorda che, oltre ad essere un obbligo di legge, è opportuno per l&rsquo;utente dotare la propria stazione di lavoro di un sistema di prevenzione e scansione contro l&rsquo;attacco di virus. Per l&rsquo;accesso alla parte riservata del sito viene assegnato agli utenti un codice identificativo ed una password; tali password sono generate su proposta degli stessi utenti. L&rsquo;utente è tenuto a custodire la password in modo riservato. Per navigare nelle aree riservate dei siti è necessario utilizzare un browser idoneo (es. Microsoft Internet Explorer 5.5 sp2 o superiore oppure Netscape Navigator 6 o superiore).
					    </p>
					    <p>
					        7. <strong>Precisazioni</strong>
					        <br/> Si precisa che il presente documento costituisce la "Privacy Policy" di questo sito che sarà soggetta ad aggiornamenti.
					    </p>
				
					</div>
					
					
					<br/>
					<p>
				        <strong>COOKIE</strong>
				    </p>

					<div align="justify">

						<strong>Cosa sono i Cookies</strong>
						<p>					    
						    I cookies sono una sorta di "promemoria", brevi "righe di testo" contenenti informazioni che possono essere salvate sul computer di un utente quando il browser (es. Chrome o InternetExplorer) richiama un determinato sito web. Grazie ai cookies il server invia informazioni che verranno rilette e aggiornate ogni volta che l&rsquo;utente tornerà su quel sito. In questo modo il sito web potrà adattarsi automaticamente all&rsquo;utente migliorandone l&rsquo;esperienza di navigazione.
						</p>
						<strong>A cosa servono i Cookies</strong>
						<p>					    
						    I cookies sono principalmente utilizzati per:
						</p>
					    <ul>
					        <li>Migliorare l&rsquo;esperienza di navigazione dell&rsquo;utente all&rsquo;interno del sito.</li>
					        <li>Fornire al gestore del sito informazioni in forma anonima sulla navigazione degli utenti anche per ottenere dati statistici sull&rsquo;uso del sito e migliorare la navigazione.</li>
					        <li>I cookies possono anche veicolare messaggi pubblicitari.</li>
					    </ul>
						<strong>I cookies tecnici e di navigazione/sessione</strong>
						<p>					    
						    Per l&rsquo;installazione dei cookies tecnici (di navigazione/sessione; di tipo "Analytics" e di funzionalità) non è richiesto il consenso dell&rsquo;utente, ma solo l&rsquo;obbligo di informativa. Questi cookies sono fondamentali per navigare all&rsquo;interno del sito utilizzando tutte le sue funzionalità, come ad esempio l&rsquo;accesso alle aree riservate (Area Clienti). Senza questi cookies non sarebbe possibile fornire i servizi richiesti. Questi cookies non raccolgono informazioni da utilizzare per fini commerciali. Rientrano in questa categoria i cookies impostati dalle piattaforme a supporto dell&rsquo;infrastruttura di sicurezza della società.
						</p>
						<strong>Come disabilitare questo tipo di cookie</strong>
						<p>
						    Disabilitabile da Impostazioni Browser*
						</p>
						<strong>Avvertenze in caso di blocco</strong>
						<p>
						    Blocco di funzioni quali l&rsquo;autentificazione, la validazione, la gestione di una sessione di navigazione e la prevenzione delle frodi è fortemente sconsigliato il loro blocco.
					    </p>
					    <strong>I cookies di profilazione</strong>
					    <p>
						    Sono volti a creare profili relativi all&rsquo;utente e vengono utilizzati al fine di inviare messaggi pubblicitari mirati, in linea con le preferenze manifestate dallo stesso nell&rsquo;ambito della navigazione in rete. Prevedono che l&rsquo;utente esprima il suo consenso. L&rsquo;utente può in qualunque momento negare il consenso e/o modificare le sue opzioni avvalendosi delle apposite sezioni di questa informativa estesa.
					    </p>
					    <strong>I cookies Analytics</strong>
						<p>
						    Raccolgono e analizzano in forma anonima e/o aggregata le informazioni sull&rsquo;uso del sito da parte dei visitatori (pagine visitate, etc) per fornire all&rsquo;utente una migliore esperienza di navigazione. Questi cookies non raccolgono informazioni che possono identificare in qualche modo l&rsquo;utente. Rientrano in questa categoria i cookies impostati dalle seguenti soluzioni tecnologiche: &gt; Adobe Site Catalyst &gt; DoubleClick &gt; Mediamind &gt; Google Analytic
					    </p>
					    <strong>Come disabilitare questo tipo di cookie</strong>
						<p>					    
						    Disabilitabile da Impostazioni Browser*
					    </p>
					    <strong>Avvertenze in caso di blocco</strong>
						<p>					    
						    Esclusione dal computo statistico dei parametri di navigazione che permettono analisi volte all&rsquo;ottimizzazione del sito
					    </p>
					    <strong>I cookies di funzionalità</strong>
						<p>					    
						    Permettono al sito di "ricordare" le scelte effettuate dall&rsquo;utente e personalizzare il sito migliorandone i contenuti. Le informazioni raccolte da questo tipo di cookies sono in forma anonima. Rientrano in questa categoria i cookies impostati dalle sequenti soluzioni tecnologiche: Adobe Test &amp; Target
					    </p>
					    <strong>Come disabilitare questo tipo di cookie</strong>
						<p>					    
						    Disabilitabile da Impostazioni Browser*
					    </p>
					    <strong>Avvertenze in caso di blocco</strong>
						<p>					    
						    Blocco di funzionalità di testing e di conseguenza visualizzazione del sito non ottimizzata. &nbsp;
						</p>
						<strong>Cookies di Terze parti</strong>
						<p>
						    <strong>Questo sito utilizza cookies di terze parti </strong>accuratamente selezionate e controllate che potrebbero installare cookie sul terminale dell&rsquo;utente tramite questo sito web (c.d. "Terze parti"). Tra queste figurano, ad esempio, agenzie pubblicitarie, ad server, fornitori di tecnologia, provider di contenuti sponsorizzati e società di ricerca esterne. Le Terze parti sono direttamente responsabili, in qualità di Titolari del trattamento, dei cookies da loro installati per il tramite di questo sito, così come dei trattamenti che ne fanno.
						    <br/>Pertanto, di seguito ti proponiamo i link alle Terze parti affinchè tu possa prendere atto della loro informativa privacy ed effettuare le tue scelte nell&rsquo;utilizzo dei cookies:
						    <br/>
						</p>
					    <ul>
					        <li><a href="http://www.google.com/intl/en/policies/privacy/" target="_blank">Google Ireland</a></li>
					        <li><a href="http://www.facebook.com/help/cookies/" target="_blank">Facebook</a></li>
					        <li><a href="http://support.twitter.com/articles/20170519-uso-dei-cookie-e-di-altre-tecnologie-simili-da-parte-di-twitter/" target="_blank">Twitter</a></li>
					        <li><a href="http://instagram.com/legal/cookies/" target="_blank">Instagram</a></li>
					        <li><a href="http://about.pinterest.com/it/privacy-policy/" target="_blank">Pinterest</a></li>
					    </ul>
						<p>
						    <strong>FACILITY:</strong> Puoi avere un elenco di alcuni provider che lavorano con i gestori dei siti web per raccogliere e utilizzare informazioni utili alla fruizione della pubblicità comportamentale andando al link <a href="http://www.youronlinechoices.com/it/le-tue-scelte" target="_blank">http://www.youronlinechoices.com/it/le-tue-scelte</a>. Potrai avere maggiori informazioni su ognuno di essi e bloccare/attivare ogni singolo operatore.
						</p>
						<strong>Come disabilitare i cookies tramite il tuo browser</strong>
						<p>
						    Questo sito web funziona in modo ottimale se i cookies sono abilitati. Puoi comunque decidere di non consentire l&rsquo;impostazione dei cookies sul tuo computer; per farlo accedi alle "Preferenze" del tuo browser. Per informazioni su come modificare le impostazioni dei cookies seleziona qui sotto il browser che stai utilizzando: <strong><a href="https://support.google.com/chrome/answer/95647?hl=it&amp;topic=14666&amp;ctx=topic" target="_blank">Chrome</a> - <a href="http://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie" target="_blank">Firefox</a> - <a href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies" target="_blank">Internet Explorer</a> - <a href="http://www.opera.com/help/tutorials/security/privacy/" target="_blank">Opera</a> - <a href="http://www.apple.com/support/?path=Safari/5.0/en/11471.html" target="_blank">Safari</a></strong> Se disattivi i cookies, potresti disabilitare alcune funzionalità del sito. Anche con tutti i cookies disabilitati, il tuo browser continuerà a memorizzare una piccola quantità di informazioni, necessarie per le funzionalità di base del sito.
						</p>
					
					</div>
				</Section>
			</Section>
		);
	}
}

export default contextProvider(
	Privacy,
	{
		define: null,
		retrieve: ['_LayoutPage']
	}
);
