import React from 'react';
import systemContextsDefs from '../contexts.defs.sys.js';
import appContextsDefs from '../contexts.defs.app.js';

const contextProvider = (Component, conf) => {
	if (!conf) {return Component;}
	let contextsDefs = {...systemContextsDefs, ...appContextsDefs};
	if (conf.retrieve) {
		var contextTypes = {};
		conf.retrieve.forEach((context, idx) => {
			if(contextsDefs[context]) { contextTypes[context] = contextsDefs[context] };
		});
		Component.contextTypes = { ...Component.contextTypes, ...contextTypes };
	}
	if (conf.define) {
		var childContextTypes = {};
		conf.define.forEach((context, idx) => {
			if(contextsDefs[context]) { childContextTypes[context] = contextsDefs[context] };
		});
		Component.childContextTypes = { ...Component.childContextTypes, ...childContextTypes };
	}
	return Component;
};

export default contextProvider;