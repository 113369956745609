import 'react-app-polyfill/ie11';
import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import APP_CONF from './core/app.conf';
import './include/jquery';
import './include/js-storage';
import './index.scss';
//import 'bootstrap';
import modernizr from './modernizr.js';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
//import LocalizedStrings from 'react-localization';

/*
let strings = new LocalizedStrings({
 en:{
   how:"How do you want your egg today?",
   boiledEgg:"Boiled egg",
   softBoiledEgg:"Soft-boiled egg",
   choice:"How to choose the egg"
 },
 it: {
   how:"Come vuoi il tuo uovo oggi?",
   boiledEgg:"Uovo sodo",
   softBoiledEgg:"Uovo alla coque",
   choice:"Come scegliere l'uovo"
 }
});
*/
if (/MSIE\s(8|9|10)/.test(navigator.userAgent)) {
	document.body.innerHTML = "<div>BROWSER NOT SUPPORTED</div>";
} else {
	let style = getComputedStyle(document.body);
	
	APP_CONF.APP_COLORS = {
		primary: style.getPropertyValue('--primary'),
		secondary: style.getPropertyValue('--secondary'),
		success: style.getPropertyValue('--success'),
		info: style.getPropertyValue('--info'),
		warning: style.getPropertyValue('--warning'),
		danger: style.getPropertyValue('--danger'),
		light: style.getPropertyValue('--light'),
		dark: style.getPropertyValue('--dark'),
		blue: style.getPropertyValue('--blue'),
		indigo: style.getPropertyValue('--indigo'),
		purple: style.getPropertyValue('--purple'),
		pink: style.getPropertyValue('--pink'),
		red: style.getPropertyValue('--red'),
		orange: style.getPropertyValue('--orange'),
		yellow: style.getPropertyValue('--yellow'),
		green: style.getPropertyValue('--green'),
		teal: style.getPropertyValue('--teal'),
		cyan: style.getPropertyValue('--cyan'),
		white: style.getPropertyValue('--white'),
		gray: style.getPropertyValue('--gray'),
		gray150: style.getPropertyValue('--gray150'),
		gray200: style.getPropertyValue('--gray200'),
		gray300: style.getPropertyValue('--gray300'),
		gray400: style.getPropertyValue('--gray400'),
		gray500: style.getPropertyValue('--gray500'),
		gray600: style.getPropertyValue('--gray600'),
		gray700: style.getPropertyValue('--gray700'),
		gray800: style.getPropertyValue('--gray800'),
		grayDark: style.getPropertyValue('--gray-dark')
	}
	
	//console.log(window.$.localStorage);
	ReactDOM.render((
		<BrowserRouter basename={APP_CONF.BASENAME}>
			<App  />
		</BrowserRouter>
	), document.getElementById('root'));
	serviceWorker.unregister();
}
