import React from 'react';
import APP_CONF from '../app.conf';
import contextProvider from '../contextProvider';
import { matchPath, Redirect, Switch, Route, Prompt } from 'react-router-dom';

import { _LoadingWrapper } from '../_L0_components/Widgets/_LoadingKit';
import _LayoutPage from '../_L0_components/Layout/_LayoutPage';
import _LayoutHeader from '../_L0_components/Layout/_LayoutHeader';
import _LayoutSidebar from '../_L0_components/Layout/_LayoutSidebar';
import _LayoutBody from '../_L0_components/Layout/_LayoutBody';
import _NavigationMenu from '../_L0_components/Layout/_NavigationMenu';

import _AuthenticationHandler from './Utilities/_AuthenticationHandler';
import _ActionsShelfService from './Services/_ActionsShelfService';
import _ApiStoreService from './Services/_ApiStoreService';
import _TextsStoreService from './Services/_TextsStoreService';
import _ImagesStoreService from './Services/_ImagesStoreService';

import _AnchorPoint from '../_L0_components/Layout/_AnchorPoint';
import Header from '../_L1_components/Layout/Header';
import Section from '../_L1_components/Layout/Section';
import Sidebar from '../_L1_components/Layout/Sidebar';
import ReactOTextCell from '../_L1_components/Elements/ReactOTextCell';
import ReactOButton from '../_L1_components/Elements/ReactOButton';

const $ = window.jQuery;
class _ReactOApp extends React.Component {
	constructor(props, initState) {
		super(props);
		this.state = {
			app_ready: false,
			system_ready: false,
			scroll_position: window.scrollY,
			texts_store_loading: true,
			images_store_loading: true,
			...(initState ? initState : {})
		};
		
		this._CMP_CLASS = {
			'cmp._AnchorPoint': _AnchorPoint,
			'cmp.Header': Header,
			'cmp.Sidebar': Sidebar,
			'cmp.Section': Section,
			'cmp.ReactOTextCell': ReactOTextCell,
			'cmp.ReactOButton': ReactOButton
		}

		this._CONTEXTS = {};
		this._PROMPTS = [];
		this.APP_CONF = APP_CONF;
		
		this.actionsShelfService = new _ActionsShelfService();
		
		this.apiStoreService = new _ApiStoreService({
			apiUrl: APP_CONF.API_URL,
			appContext: this.getChildContext().app
		});
		
		this.authHandler = new _AuthenticationHandler({
			apiStoreService: this.apiStoreService,
			actionsShelfService: this.actionsShelfService,
			onLoginSuccess: (accountData) => {
				this.setState({
					accountData: accountData
				});
			},
			onLogoutSuccess: () => {
				this.redirectTo("/login")
			}
		});
		
	//	this.requestHandler = new _RequestHandler({
	//		authHandler: this.authHandler
	//	});
		
		this.apiStoreService.setAuthHandler(this.authHandler);

		this.textsStoreService = new _TextsStoreService({
			apiStoreService: this.apiStoreService,
			onLoaded: (data) => {
				this.setState({
					texts_store_loading: false,
					texts: data
				});
			}
		});
		
		this.imagesStoreService = new _ImagesStoreService({
			apiStoreService: this.apiStoreService,
			imagesRepository: this.APP_CONF.PUBLIC_PATH + '/images',
			onLoaded: () => {
				this.setState({
					images_store_loading: false
				});
			}
		});
		
		this._getContext = this._getContext.bind(this);
		this._registerContext = this._registerContext.bind(this);
		this._unregisterContext = this._unregisterContext.bind(this);
		this.redirectTo = this.redirectTo.bind(this);
		this.defineRedirectionPrompter = this.defineRedirectionPrompter.bind(this);
		this.checkRedirectionPrompts = this.checkRedirectionPrompts.bind(this);
	}
	
	componentDidCatch(error, info) {
		console.log("REACTO BASE APP componentDidCatch");
		console.log(error);
		console.log(info);
		if (this.onComponentDidCatch) {
			this.onComponentDidCatch(error, info);
		}
	}
	
	defineRedirectionPrompter(action) {
		this._PROMPTS.push(action);
	}
	
	checkRedirectionPrompts(location, action) {
		if (this._PROMPTS.length > 0) {
			let _prompts_ok = 0;
			this._PROMPTS.forEach((_CURR_prompt, _CURR_idx) => {
				let _TMP = _CURR_prompt(() => {
					this.redirectTo(location);
				});
				_prompts_ok = _TMP ? (_prompts_ok + 1) : _prompts_ok;
			});
			if (_prompts_ok == this._PROMPTS.length) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}
	
	// Redirector
	redirectTo(to, do_replace) {
	//	console.log("DEBUG :: _ReactOApp -> redirectTo (" + to + ")");
		let routePrefixMatch = null;
		if (this._ROUTE_PREFIX) {
			routePrefixMatch = matchPath(this.props.location.pathname, {path: this._ROUTE_PREFIX});
		}
		if (to == '_HOME_') {
			to = this.ROUTES.home;
		}
		console.warn("Redirecting to " + (routePrefixMatch ? '/' + routePrefixMatch.params.webtoken + to : to));
		if (do_replace) {
			this.props.history.replace(routePrefixMatch ? '/' + routePrefixMatch.params.webtoken + to : to);
		} else {
			this.props.history.push(routePrefixMatch ? '/' + routePrefixMatch.params.webtoken + to : to);
		}
	}
	
	// Context sharing service
	_makeid() {
		var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		for (var i = 0; i < 5; i++)
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		return text;
	}
	
	_registerContext(context_ref, ctx) {
		var new_id = this._makeid();
		this._CONTEXTS[context_ref + '___' + new_id] = ctx[Object.keys(ctx)[0]];
		return context_ref + '___' + new_id;
	}
	
	_unregisterContext(context_ref) {
		delete(this._CONTEXTS[context_ref]);
	}
	
	_getContext(context_ref) {
		var found_context_ref = null;
		Object.keys(this._CONTEXTS).forEach((entry, idx) => {
			var re = new RegExp(context_ref + '___.*$', "g");
			if (re.test(entry)) {
				found_context_ref = entry;
				return false;
			}
		});
		return this._CONTEXTS[found_context_ref];
	}
	
	_validatePath(path) {
	//	console.log("DEBUG :: _ReactOApp _ _validatePath");
		return (this._PREFIX_ROUTE_DATA ? "/" + this._PREFIX_ROUTE_DATA.match.params.webtoken : "") + path;
	}
	
	setPostLoginRedirect(to) {
		this._POST_LOGIN_REDIRECT_TO = to;
	}
	
	getPostLoginRedirect() {
		return this._POST_LOGIN_REDIRECT_TO;
	}
	
	// App context retrieving
	getChildContext() {
		return {app: {
			jQuery: $,
			conf: this.APP_CONF,
			redirectTo: this.redirectTo.bind(this),
			setPostLoginRedirect: this.setPostLoginRedirect.bind(this),
			getPostLoginRedirect: this.getPostLoginRedirect.bind(this),
			registerContext: this._registerContext,
			unregisterContext: this._unregisterContext,
			getContext: this._getContext,
			app_ready: this.state.app_ready,
			system_ready: this.state.system_ready,
			scroll_position: this.state.scroll_position,
			activeRouteData: this._ACTIVE_ROUTE_DATA,
			prefixRouteData: this._PREFIX_ROUTE_DATA,
			loginRoute: this.ROUTES ? (this.ROUTES.login || '/') : '/',
			homeRoute: this.ROUTES ? (this.ROUTES.home || '/') : '/',
			defineRedirectionPrompter: this.defineRedirectionPrompter,
			
			getImage: this.getImage.bind(this),

			getApiStoreService: () => {return this.apiStoreService},
			getActionsShelfService: () => {return this.actionsShelfService},
			getTextsStoreService: () => {return this.textsStoreService},
			getImagesStoreService: () => {return this.imagesStoreService},
		//	getRequestHandler: () => {return this.requestHandler},
			getLocalStorageService: () => {return window.$.localStorage},
			
			isBrowserEdge: () => {
				if (document.documentMode || /Edge/.test(navigator.userAgent)) {
					return true;
				} else {
					return false;
				}
			},
			isBrowserIE11: () => {
				if (/(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:)(\d+)/.test(navigator.userAgent)) {
					return true;
				} else {
					return false;
				}
			},
			isBrowserFirefox: () => {
				if (/Firefox/.test(navigator.userAgent)) {
					return true;
				} else {
					return false;
				}
			},
			
			authHandler: this.authHandler,
			
			validatePath: this._validatePath.bind(this),
			
			colors: APP_CONF.APP_COLORS,
			router: {
				location: this.props.location,
				history: this.props.history,
				match: this.props.match
			},
			iterateChildren: this.iterateChildren,
			...(this.onGetChildContext ? this.onGetChildContext() : {})
		}};
	}
	
	iterateChildren(children, with_props) {
	//	console.log("DEBUG :: _ReactOApp _ iterateChildren");
		return children.map((_C_entry, _C_idx) => {
			let _TMP_cmp = null;
			if (_C_entry.component) {
				if ( /^cmp\./.test(_C_entry.component) && this._CMP_CLASS[_C_entry.component] ) {
					_TMP_cmp = this._CMP_CLASS[_C_entry.component];
				} else {
					_TMP_cmp = _C_entry.component
				}
			}
			return _TMP_cmp ? (
				<_TMP_cmp key={_C_idx} {..._C_entry.props} {...(with_props ? with_props : {})} children={_C_entry.children ? this.iterateChildren(_C_entry.children, with_props) : null}/>
			) : <span key={_C_idx}>{_C_entry}</span>;
		});
	}
	
	getImage(filename) {
		return APP_CONF.PUBLIC_PATH + 'images/' + filename;
	}
	
	/////////////////////////
	//	Lifecycle methods
	//

	componentDidMount() {
	//	console.log("DEBUG :: _ReactOApp -> componentDidMount");
		let continuous = false;
		window.addEventListener("scroll", (ev) => {
			if ( ((continuous ? true : this.state.scroll_position >= 40) && ev.target.scrollingElement.scrollTop < 40) || (this.state.scroll_position <= 40 && ev.target.scrollingElement.scrollTop > 40) ) {
				this.setState({
					scroll_position: ev.target.scrollingElement.scrollTop
				});
			}
		});
		this.setState({
			system_ready: true
		}, () => {
			if (this.onComponentDidMount) this.onComponentDidMount();
		})
	}
	
	componentWillUnmount() {
	//	console.log("DEBUG :: _ReactOApp -> componentWillUnmount");
		if (this.onComponentWillUnmount) this.onComponentWillUnmount();
	}
	
	shouldComponentUpdate(nextProps) {
	//	console.log("DEBUG :: _ReactOApp -> shouldComponentUpdate");
		if (this._PREFIX_ROUTE_DATA && this._PREFIX_ROUTE_DATA.match) {
			let _REG = new RegExp('^/' + this._PREFIX_ROUTE_DATA.match.params.webtoken);
			if (_REG.exec(nextProps.location.pathname)) {
				return true;
			} else {
				console.warn("Incorrect prefix path, replacing with: /" + this._PREFIX_ROUTE_DATA.match.params.webtoken + nextProps.location.pathname + nextProps.location.hash);
				this.redirectTo('/' + this._PREFIX_ROUTE_DATA.match.params.webtoken + nextProps.location.pathname + nextProps.location.hash);
				return false;
			}
		} else {
			return true;
		}
	}
	
	render() {
	//	console.log("DEBUG :: _ReactOApp -> render");
		
		let routePrefixMatch = null;
		if (this._ROUTE_PREFIX) {
			routePrefixMatch = matchPath(this.props.location.pathname, {path: this._ROUTE_PREFIX});
		}
		this._PREFIX_ROUTE_DATA = routePrefixMatch ? {
			match: routePrefixMatch
		} : null;
		
		let activeRouteData = null;
		
		if (this.ROUTES) {
			Object.keys(this.ROUTES.reserved).forEach((_C_path, _C_idx) => {
				const match = matchPath(this.props.location.pathname, {
					path: ((this._ROUTE_PREFIX || "") + _C_path),
					exact: this.ROUTES.reserved[_C_path].exact,
					strict: this.ROUTES.reserved[_C_path].strict
				});
				if (match) {
					activeRouteData = this.ROUTES.reserved[(routePrefixMatch ? match.path.replace(routePrefixMatch.path, '') : match.path)];
					activeRouteData.isReserved = true;
					activeRouteData.match = match;
					activeRouteData.match.params = {...activeRouteData.match.params, ...(routePrefixMatch ? routePrefixMatch.params : {})};
					return;
				}
			});
			if (!activeRouteData) {
				Object.keys(this.ROUTES.public).forEach((_C_path, _C_idx) => {
					const match = matchPath(this.props.location.pathname, {
						path: ((this._ROUTE_PREFIX || "") + _C_path),
						exact: this.ROUTES.public[_C_path].exact,
						strict: this.ROUTES.public[_C_path].strict
					});
					if (match) {
						activeRouteData = this.ROUTES.public[(routePrefixMatch ? match.path.replace(routePrefixMatch.path, '') : match.path)];
						activeRouteData.match = match;
						activeRouteData.match.params = {...activeRouteData.match.params, ...(routePrefixMatch ? routePrefixMatch.params : {})};
						return;
					}
				});
			}
			
			if (activeRouteData) {
				activeRouteData.location = this.props.location;
				this._ACTIVE_ROUTE_DATA = activeRouteData;
				if (this.state.system_ready && this.state.app_ready) {
					if (activeRouteData.isReserved && !this.authHandler.isAuthorized()) {
						this.redirectTo(this.ROUTES.login);
					} else if (activeRouteData.privileges == 'ADMIN' && this.state.accountData.type != 'ADMIN') {
						this.redirectTo(this.ROUTES.home);
					}
				}
			
//				console.log("FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF");
//				console.log(this._ACTIVE_ROUTE_DATA.sidebarMenu);
			
				this._ACTIVE_ROUTE_DATA._COMPILED_sidebarMenu = undefined;
				if (this._ACTIVE_ROUTE_DATA.sidebarMenu && this._ACTIVE_ROUTE_DATA.layoutType == 'page-admin') {
					
					let _stringified_menu = JSON.stringify(this._ACTIVE_ROUTE_DATA.sidebarMenu);
//					console.log("STRINGY: " + _stringified_menu);
					
					let re = RegExp('___-(.[^-]*)-___', 'g');
					let match = true;
					while (match) {
						match = re.exec(_stringified_menu);
//						console.log("MATCH:");
//						console.log(match);
						if (match) {
							let matchedRouteParam = this._ACTIVE_ROUTE_DATA.match.params[match[1]];
							if (matchedRouteParam) {
								_stringified_menu = _stringified_menu.replace(new RegExp(match[0], 'g'), matchedRouteParam);
							} else {
								console.error(" :: Sidebar _ render [MISSING MATCHING ROUTE PARAM WHILE FORMATTING PATH] -- needed: ");
								console.error(match[1]);
							}
						}
					}
					
//					console.log("STRINGY OUT: " + _stringified_menu);
					this._ACTIVE_ROUTE_DATA._COMPILED_sidebarMenu = JSON.parse(_stringified_menu);
					
					this._ACTIVE_ROUTE_DATA.sidebarMenu.items.forEach((_C_item, _C_idx1) => {
						Object.keys(_C_item).forEach((_C_entry, _C_idx2) => {
							if (typeof _C_item[_C_entry] == 'function') {
								this._ACTIVE_ROUTE_DATA._COMPILED_sidebarMenu.items[_C_idx1][_C_entry] = _C_item[_C_entry];
							}
						});
					})
					
					if (this._ACTIVE_ROUTE_DATA.sidebarMenu.title && this._ACTIVE_ROUTE_DATA.sidebarMenu.title.output) {
						this._ACTIVE_ROUTE_DATA._COMPILED_sidebarMenu.title.output = this._ACTIVE_ROUTE_DATA.sidebarMenu.title.output;
					}
				}
				
				
				
			} else {
				console.warn(routePrefixMatch ? "OK" : "KO");
				console.warn("NO ACTIVE ROUTE");
				this.redirectTo(this.ROUTES.home);
				return null;
			}
		}
		return (
			
			<div>
				<Prompt
					key="1"
					when={true}
					message={(location, action) => {
						return this.checkRedirectionPrompts(location, action);
					}}
				/>
				<_LoadingWrapper
					loadingPlug={!this.state.system_ready || !this.state.app_ready}
					hideOnLoading={true}
					fullscreen={true} >
						{/*this.state.system_ready && this.state.app_ready && */}
						{(!activeRouteData.isReserved || (activeRouteData.isReserved && this.authHandler.isAuthorized())) ? this.onRender(
							this.ROUTES ? (
								<_LayoutPage
									className="_base"
									backgroundImage={this.ROUTES.pageBackground}
									backgroundColor={this.ROUTES.pageColor}
								>
									{this._ACTIVE_ROUTE_DATA.layoutType == 'page' || this._ACTIVE_ROUTE_DATA.layoutType == 'page-admin' ? (
										<_LayoutHeader
											positionFixed={true}
											shareContextWithName="context__header"
										>
											{(/(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:)(\d+)/.test(navigator.userAgent)) ? (
												<div className="bg-warning text-center py-2">
													This browser is not supported by this web application. Please use a modern browser like Chrome, Safari, Firefox or Microsoft Edge for a better experience
												</div>
											) : null}
											<Header>
												<_NavigationMenu className={"navbar-nav nav-left"} menu={this._ACTIVE_ROUTE_DATA.leftHeaderMenu}/>
												<_NavigationMenu className={"navbar-nav nav-right"} menu={this._ACTIVE_ROUTE_DATA.rightHeaderMenu}/>
											</Header>
										</_LayoutHeader>
									) : null}
									
									<_LayoutSidebar
										visible={this._ACTIVE_ROUTE_DATA.layoutType == 'page' || this._ACTIVE_ROUTE_DATA.layoutType == 'page-admin'}
										forceClosed={!(this._ACTIVE_ROUTE_DATA.layoutType == 'page-admin')}
										shareContextWithName="context__sidebar"
									>
										<Sidebar>
											{this._ACTIVE_ROUTE_DATA.layoutType == 'page-admin' ? (
												<_NavigationMenu className={"navbar-nav nav-left"} menu={this._ACTIVE_ROUTE_DATA._COMPILED_sidebarMenu} sidebar/>
											) : null}
										</Sidebar>
										{/*<Sidebar>
											<_NavigationMenu className={"navbar-nav nav-left"} menu={this._ACTIVE_ROUTE_DATA.sidebarMenu2} sidebar/>
										</Sidebar>*/}
									</_LayoutSidebar>
					
									<_LayoutBody>
										{/*<Switch>
											<Route path={this._ROUTE_PREFIX} render={() => {
												return this.ROUTES ? (
													<span>*/}
													{/*<TransitionGroup>
														<CSSTransition key={this._ACTIVE_ROUTE_DATA.location.pathname} timeout={{ enter: 500, exit: 500 }} classNames='rag-fadeInLeft' exit={false}>*/}
															<Switch>
																{Object.keys(this.ROUTES.reserved).map((_C_routePath, _C_idx) => {
																	let routeData = this.ROUTES.reserved[_C_routePath];
																	var CMP = {component: routeData.component};
																	if (routeData.component instanceof Array) {
																		CMP = {render: () => {return this.iterateChildren(routeData.component)}}
																	}
																	return (
																		<Route key={_C_idx} path={(this._ROUTE_PREFIX || "") + _C_routePath} exact={routeData.exact} {...CMP}/>
																	)
																})}
																{Object.keys(this.ROUTES.public).map((_C_routePath, _C_idx) => {
																	let routeData = this.ROUTES.public[_C_routePath];
																	var CMP = {component: routeData.component};
																	if (routeData.component instanceof Array) {
																		CMP = {render: () => {return this.iterateChildren(routeData.component)}}
																	}
																	return (
																		<Route key={_C_idx} path={(this._ROUTE_PREFIX || "") + _C_routePath} exact={routeData.exact} {...CMP}/>
																	)
																})}
																<Redirect to={this.ROUTES.home}/>
															</Switch>
															
														{/*</CSSTransition>
													</TransitionGroup>*/}
													{/*</span>
												) : null;
											}}/>
										</Switch>*/}
									</_LayoutBody>

								</_LayoutPage>
							) : null
						) : null}
				</_LoadingWrapper>
			</div>
		)
	}
}

export default contextProvider(
	_ReactOApp,
	{
		define: ['app']
	}
);

