import React from 'react';
import contextProvider from 'core/contextProvider';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap';

import _ReactOBaseComponent, { withDefinedContext } from 'core/_L0_components/_ReactOBaseComponent';
import ReactOModal from 'core/_L1_components/Elements/ReactOModal';

const context_exposed_methods = [
	"open",
	"close"
];

class ReactOCarousel extends _ReactOBaseComponent {
	constructor(props) {
		var initState = {
			activeIndex: 0
		};
		super(props, initState);
		
		this._items = [];
		
		this.open = this.open.bind(this);
		this.close = this.close.bind(this);
		this.onExiting = this.onExiting.bind(this);
		this.onExited = this.onExited.bind(this);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
		this.goToIndex = this.goToIndex.bind(this);
		
		if (this.props.onControllerReady) {
			this.props.onControllerReady(this.getContext().ReactOCarousel);
		}
	}
	
	open(items, active) {
		this.setState({
			data: items,
			...(active !== undefined ? {activeIndex: active} : {})
		}, () => {
			this._carousel_modal.open(this.state.data)
		});
	}
	
	close(data) {
		this.setState({
			opened: false
		});
	}
	
	onExiting() {
		this.animating = true;
	}

	onExited() {
		this.animating = false;
	}

	next() {
		if (this.animating) return;
		const nextIndex = this.state.activeIndex === this._items.length - 1 ? 0 : this.state.activeIndex + 1;
		this.setState({ activeIndex: nextIndex });
	}

	previous() {
		if (this.animating) return;
		const nextIndex = this.state.activeIndex === 0 ? this._items.length - 1 : this.state.activeIndex - 1;
		this.setState({ activeIndex: nextIndex });
	}

	goToIndex(newIndex) {
		if (this.animating) return;
		this.setState({ activeIndex: newIndex });
	}
	
	//
	//	Lifecycle methods
	//
	
	onAppMounted() {
	}
	
	onComponentDidMount() {
//		console.log("DEBUG :: ReactOCarousel _ onComponentDidMount");
	}
	
	onComponentWillUnmount() {
	}
	
	onGetChildContext() {
		return {};
	}
	
	onRender() {
		this._items = this.props.modal === true ? (this.state.data ? this.state.data : []) : (this.props.items ? this.props.items : []);
		let _carousel = (
			<Carousel
				className="rounded-bottom bg-dark h-50"
				activeIndex={this.state.activeIndex}
				interval={false}
				next={this.next}
				previous={this.previous}
			>
				<CarouselIndicators items={this._items} activeIndex={this.state.activeIndex} onClickHandler={this.goToIndex} />
				{this._items.map((item) => {
					return (
						<CarouselItem
							onExiting={this.onExiting}
							onExited={this.onExited}
							key={item.src}
							className="text-center"
						>
							<div className="h-100 d-flex align-items-center">
								<img className="w-100" src={item.src} alt={item.altText || ''} />
								{item.caption ? (<CarouselCaption captionText={item.caption} captionHeader={item.caption} />) : null}
							</div>
						</CarouselItem>
					);
				})}
				<CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
				<CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
			</Carousel>
		);
		return (
			<div>
				{this.props.modal === true ? (
					<ReactOModal
						modalId="carouselModal"
						size="lg"
						bodyClassName="p-0"
						centered={this.props.modalCentered}
						onControllerReady={(modal_context) => {
							this._carousel_modal = modal_context;
						}}
						header={this.props.modalTitle || undefined}
						body={(data) => {
							return _carousel;
						}}
					/>
				) : _carousel}
			</div>
		);
	}
}

export default contextProvider(
//	ReactOCarousel,
	withDefinedContext(ReactOCarousel, 'ReactOCarousel', context_exposed_methods),
	{
		retrieve: null
	}
);

