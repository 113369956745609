import React from 'react';

import _ReactOBaseComponent, { withDefinedContext } from '../_ReactOBaseComponent';

const context_exposed_methods = [
	'getSafeBoundaries',
	'_updateBoundaries',
	'getRegisteredAnchorPoints',
	'_registerAnchorPoint',
	'getRegisteredModals',
	'_registerModal'
];

class _LayoutPage extends _ReactOBaseComponent {
	constructor(props) {
		super(props);
		this.state = {
			_safe_boundaries: {
				top: 0,
				right: 0,
				bottom: 0,
				left: 0
			},
			_registered_anchor_points: {},
			_registered_modals: {}
		}
	}	
	
	onComponentDidMount() {
	}

	_updateBoundaries(new_boundaries) {
		this.setState((prev_state) => {
			Object.keys(new_boundaries).forEach((key) => {
				prev_state._safe_boundaries[key] = new_boundaries[key];
			});
			return prev_state;
		});
	}
	
	getSafeBoundaries() {
		return this.state._safe_boundaries;
	}
	
	_registerAnchorPoint(anchor_point) {
		this.setState((prev_state) => {
			prev_state._registered_anchor_points[anchor_point.id] = anchor_point;
			return prev_state;
		});
	}
	
	getRegisteredAnchorPoints() {
		return this.state._registered_anchor_points;
	}
	
	_registerModal(modal) {
		this.setState((prev_state) => {
			prev_state._registered_modals[modal.id] = modal;
			return prev_state;
		});
	}
	
	getRegisteredModals() {
		return this.state._registered_modals;
	}
	
	render() {
		return (
			<div
				className={this.props.className + " _layout-page"}
			>
				<div
					className="bg-cover"
					style={{
						width: '100%',
						height: '100%',
						top: '0',
						left: '0',
						...(this.props.backgroundImage ? {background: "url(" + this.props.backgroundImage + ") no-repeat center top"} : {}),
						backgroundColor: this.props.backgroundColor || null,
						position: 'fixed',
						zIndex: '-1'
					}}
				/>
				{React.Children.map(this.props.children, (_C_child, _C_idx) => {
					return _C_child ? React.cloneElement(_C_child, {  }) : null;
				})}
			</div>
		);
	}
	
}

export default withDefinedContext(_LayoutPage, '_LayoutPage', context_exposed_methods);
