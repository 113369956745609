import React from 'react';
import ResizeDetector from 'react-resize-detector';
import contextProvider from '../../contextProvider';
import { Scrollbars } from 'react-custom-scrollbars';

import _ReactOBaseComponent, { withDefinedContext } from '../_ReactOBaseComponent';

const context_exposed_methods = [
	'toggle', 
	'close', 
	'open'
];

class _LayoutSidebar extends _ReactOBaseComponent {
	constructor(props) {
	//	console.log("DEBUG :: _LayoutSidebar _ constructor");
		var initState = {
			opened: true,
			positionFixed: true,
			sidebarVisible: 0
		};
		super(props, initState);
		this.width = 0;
		this.height = 0;
		this._FORCED_CLOSED = true;
	}
	
	/////////////////////////
	//	Lifecycle methods
	//
	
	onAppMounted() {
//		console.log("DEBUG :: _LayoutSidebar _ onAppMounted");	
	}
	
	onComponentDidMount() {
//		console.log("DEBUG :: _LayoutSidebar _ onComponentDidMount");
		this.ActionsShelfService = this.getAppContext().getActionsShelfService();
		this._actionShelf = this.ActionsShelfService.createNewShelf('_LayoutSidebar');
		this._actionShelf.registerAction({
			key: 'toggle_sidebar',
			call: this.toggle.bind(this)
		});
		this._actionShelf.registerAction({
			key: 'set_sidebar_visible',
			call: this.setSidebarVisible.bind(this)
		});
	}
	
	onComponentWillUnmount() {
		this.width = 0;
		this.height = 0;
		this._notifyOccupationToDocker();
	}
	
	onGetChildContext() {
		return {
			state: this.state,
			setSidebarVisible: this.setSidebarVisible,
			isOpened: () => {
				return this.state.opened;
			}
		};
	}
	
	_notifyOccupationToDocker() {
		this.context._LayoutPage._updateBoundaries({
			left: !this.props.forceClosed && this.state.opened ? this.width : 0
		});
	}
	
	setSidebarVisible() {
//		console.log("DEBUG :: _LayoutSidebar _ setSidebarVisible");
		this.setState((prev_state) => {
			prev_state.sidebarVisible = prev_state.sidebarVisible == 1 ? 0 : 1;
			return prev_state;
		}, () => {
			this._notifyOccupationToDocker();
		});
	}

	toggle() {
		this.setState((prev_state) => {
			prev_state.opened = !prev_state.opened;
			this.getAppContext().getLocalStorageService().set('sidebar_opened', prev_state.opened ? '1' : '0');
			return prev_state;
		}, () => {
			this._notifyOccupationToDocker();
		});
	}
	
	close() {
		this.getAppContext().getLocalStorageService().set('sidebar_opened', '0');
		this.setState({
			opened: false
		}, () => {
			this._notifyOccupationToDocker();
		});
	}
	
	open() {
		this.getAppContext().getLocalStorageService().set('sidebar_opened', '1');
		this.setState({
			opened: true
		}, () => {
			this._notifyOccupationToDocker();
		});
	}
	
	_updateGeometry(width, height) {
		this.width = width;
		this.height = height;
		this._notifyOccupationToDocker();
	}
	
	shouldComponentUpdate(nextProps, nextState) {
		return true;
	//	if (nextProps.forceClosed !== this.props.forceClosed || this.props.forceClosed != this._FORCED_CLOSED) {
	//		return true;
	//	} else {
	//		return false;
	//	}
	}
	
	onRender() {
		if (this.state.isMobile && (!this.getAppContext().getLocalStorageService().isSet('sidebar_opened_prev_screen') || this.getAppContext().getLocalStorageService().get('sidebar_opened_prev_screen') == 'desktop')) {
			this.getAppContext().getLocalStorageService().set('sidebar_opened_prev_screen', 'mobile');
			this.close()
		}
		if (!this.state.isMobile && (!this.getAppContext().getLocalStorageService().isSet('sidebar_opened_prev_screen') || this.getAppContext().getLocalStorageService().get('sidebar_opened_prev_screen') == 'mobile')) {
			this.getAppContext().getLocalStorageService().set('sidebar_opened_prev_screen', 'desktop');
			this.open()
		}
		if (this.getAppContext().getLocalStorageService().isSet('sidebar_opened')) {
			if (this.getAppContext().getLocalStorageService().get('sidebar_opened') == '1' && !this.state.opened) {
				this.open();
			} else if (this.getAppContext().getLocalStorageService().get('sidebar_opened') == '0' && this.state.opened) {
				this.close();
			}
		}
		if (this.props.forceClosed != this._FORCED_CLOSED) {
			this._FORCED_CLOSED = this.props.forceClosed;
			this._notifyOccupationToDocker();
		}

		return (
			<div
				className={"_layout-sidebar bg-light border-right border-gray300" + (this.state.positionFixed ? " -fixed" : "")}
				style={{
					visibility: this.props.visible === undefined || this.props.visible ? "visible" : "hidden",
					top: '0',
					left: !this.props.forceClosed && this.state.opened ? '0' : '-' + this.width + 'px',
					bottom: '0',
					marginTop: this.context._LayoutPage.getSafeBoundaries().top + 'px',
					width: "250px"
				}}
			>
				<ResizeDetector handleWidth handleHeight onResize={(width, height, el) => {
					this._updateGeometry(width + 1, height);
				}}/>
				<Scrollbars  className="-container" style={{width: ((this.props.children.length || 1) * 250) + "px", left: "-" + (250 * this.state.sidebarVisible) + "px"}}>
					{React.Children.map(this.props.children, (_C_child, _C_idx) => {
						return (
							<div className="-wrapper">
									{React.cloneElement(_C_child, {  })}
							</div>
						);
					})}
				</Scrollbars>
			</div>
		);
	}
}

export default contextProvider(
	withDefinedContext(_LayoutSidebar, '_LayoutSidebar', context_exposed_methods),
	{
		retrieve: ['_LayoutPage']
	}
);
