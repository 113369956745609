import React from 'react';
import contextProvider from '../../contextProvider';

import _ReactOBaseComponent from '../_ReactOBaseComponent';

class _AnchorPoint extends _ReactOBaseComponent {
	constructor(props) {
		super(props);
		this.state = {
		}
	}
	
	onComponentDidMount() {
		this.context._LayoutPage._registerAnchorPoint({id: this.props.anchorId});
	}
	
	render() {
		return (
			<div/>
		);
	}
	
}

export default contextProvider(
	_AnchorPoint,
	{
		retrieve: ['_LayoutPage']
	}
);