import React from 'react';
import contextProvider from 'core/contextProvider';

//import jump from 'jump.js';

import _ReactOBaseComponent from 'core/_L0_components/_ReactOBaseComponent';

import ReactOButton from 'core/_L1_components/Elements/ReactOButton';
import logo from 'app/imgs/logo_pdf.png';
import logo_short from 'app/imgs/logo_short.png';
import HeaderToggler from 'core/_L1_components/Layout/HeaderToggler';
import HeaderBrand from 'core/_L1_components/Layout/HeaderBrand';

//import '../../_L1_styles/Layout/header.scss';

class Header extends _ReactOBaseComponent {
	constructor(props) {
	//	console.log("DEBUG :: Header _ constructor");
		super(props);
		this.state = {
			lastHashPosition: null
		}
		this.expandAt = 'md';
		this.extendAlign = 2;				// 0: left | 1: center | 2: right //
		this.collapseAlign = 2;				// 0: left | 1: center | 2: right //
		this.collapseAsideWidth = 70;		// % percentage
		this.collapseAsideMaxWidth = 350;	// px
		this.collapseBackdrop = true;
		
		this._setLastHashPosition = this._setLastHashPosition.bind(this);
	}
	
	_setLastHashPosition(hash) {
		this.setState({
			lastHashPosition: hash
		});
	}
	
	onAppMounted() {
		//console.log("DEBUG :: Header -> onAppMounted");
	}
	
	onComponentDidMount() {
		//console.log("DEBUG :: Header -> onComponentDidMount");
	}
	
	onRender() {
		// TODO - Salvare altezza iniziale dell'header per menu allineato al centro.
		//		  Per evitare antiestetico ridimensionamento durante lo scroll
		//this.initialHeaderHeight = this.initialHeaderHeight == 0 ? this.context._LayoutHeader.height : this.initialHeaderHeight;
		let _shortLogo = !(this.getSharedContext('context__sidebar') && this.getSharedContext('context__sidebar').isOpened()) && !this.getAppContext().activeRouteData.navbarSetup.shortLogo === false;
		return (
			<header className="header d-flex bg-light">
				<HeaderBrand type='image' image={_shortLogo ? logo_short : logo} link={this.getAppContext().conf.BASENAME + this.getAppContext().homeRoute} height={60} width={_shortLogo ? 90 : 250} shortLogo={_shortLogo}/>
				<nav className={"navbar d-flex justify-content-end flex-grow-1 navbar-expand-" + this.expandAt + " navbar-light bg-light " + (this.getAppContext().scroll_position < 40 ? ' vp-in' : ' vp-out')}>

					{this.getAppContext().activeRouteData.layoutType != 'page' ? (
						<ReactOButton
							color="light"
							onClick={() => {
								this.getSharedContext('context__sidebar').toggle();
							}}
						>
							<span className="navbar-toggler-icon"></span>
						</ReactOButton>
					) : null}
					
					<HeaderToggler>
						<span className="navbar-toggler-icon"></span>
					</HeaderToggler>

					<div 
						className={
							"collapse navbar-collapse" + 
							(this.context._LayoutHeader.isOpened() ? " opened" : "") + 
							(this.collapseAlign == 1  ? " centered-collapse" : "")
						}
						style={{
							// TODO - Salvare altezza iniziale dell'header per menu allineato al centro.
							//		  Per evitare antiestetico ridimensionamento durante lo scroll
							//paddingTop: this.collapseAlign != 1 ? (this.context._LayoutPage.getSafeBoundaries().top + 'px') : (this.initialHeaderHeight + 'px'),
							paddingTop: this.context._LayoutPage.getSafeBoundaries().top + 'px',
							right: this.collapseAlign == 0 ? ('auto') : (this.collapseAlign == 1 ? ('0') : (this.context._LayoutHeader.isOpened() ? '0' : '-' + this.collapseAsideWidth + '%')),
							left: this.collapseAlign == 0 ? (this.context._LayoutHeader.isOpened() ? '0' : '-' + this.collapseAsideWidth + '%') : (this.collapseAlign == 1 ? ('0') : ('auto')),
							width: this.collapseAlign == 0 || this.collapseAlign == 2 ? (this.collapseAsideWidth + '%') : '100%',
							maxWidth: this.collapseAlign == 0 || this.collapseAlign == 2 ? (this.collapseAsideMaxWidth + 'px') : '100%',
							visibility: this.collapseAlign != 1 || (this.collapseAlign == 1 && this.context._LayoutHeader.isOpened()) ? 'visible' : 'hidden',
							opacity: this.collapseAlign != 1 || (this.collapseAlign == 1 && this.context._LayoutHeader.isOpened()) ? '1' : '0'
						}}
					>
						{this.props.children}
					</div>
					
					{this.collapseBackdrop ? (
						<div className={"backdrop" + (this.context._LayoutHeader.isOpened() ? " opened" : "")} onClick={() => {   this.context._LayoutHeader.toggle()   }} />
					) : ''}

				</nav>
				{/*<div
					style={{
						position: 'absolute',
						left: '0',
						top: this.context._LayoutHeader.state.height + 'px',
						width: '100%',
						height: '80px',
						backgroundColor: '#00F'
					}}
				>Outer element</div>*/}
			</header>
		);
	}
}

export default contextProvider(
	Header,
	{
		define: null,
		retrieve: ['_LayoutPage', '_LayoutHeader']
	}
);
