import React from 'react';
import contextProvider from 'core/contextProvider';
import GoogleMap from "react-google-map";
import ReactGoogleMapLoader from "react-google-maps-loader";

import _ReactOBaseComponent, { withDefinedContext } from 'core/_L0_components/_ReactOBaseComponent';

const context_exposed_methods = [
	'setMarkers',
	'getGoogleMapsCore',
	'goToSelectedMarker'
];

class GoogleMaps extends _ReactOBaseComponent {
	constructor(props) {
		var initState = {
			markers: []
		};
		super(props, initState);
		
		this.API_KEY = "AIzaSyAJJEKWqYEMuAF3y9tKumtcssTeCje3STE";
		this.MAP_STYLE = [
    {
        "featureType": "all",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "saturation": 36
            },
            {
                "color": "#000000"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#000000"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 17
            },
            {
                "weight": 1.2
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#c4c4c4"
            }
        ]
    },
    {
        "featureType": "administrative.neighborhood",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#707070"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 21
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi.business",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#763737"
            },
            {
                "lightness": "0"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "hue": "#ff000a"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 18
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#817070"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#2c2c2c"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#999999"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "saturation": "-52"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 19
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 17
            }
        ]
    }
];
	}
	
	getGoogleMapsCore() {
		return this._googleMaps_Core;
	}
	
	setMarkers(markers) {
		this.setState({
			markers: markers
		});
	}
	
	updateMarkerPopupPositionXY() {
		if ((this.state.selectedMarker && this.props.markerPopupRender) || (this.state.overMarker && this.props.markerOverPopupRender)) {
			let markerType = this.state.selectedMarker ? 'selectedMarker' : 'overMarker';
			this.setState((prev_state) => {
				if (prev_state[markerType]) {
					prev_state[markerType].position_xy = this.getMarkerPositionXY(this.state[markerType])
				}
				return prev_state;
			})
			if (this.props.onMarkerPopupUpdatePositionXY) {
				this.props.onMarkerPopupUpdatePositionXY(this.getMarkerPositionXY(this.state[markerType]));
			}
			if (!this._googleMaps_Map.getBounds().contains(this.state[markerType].getPosition())) {
			}
		}
	}
	
	getMarkerPositionXY(marker) {
		return this.overlayView.getProjection().fromLatLngToContainerPixel(marker.getPosition());
	}
	
	goToSelectedMarker() {
		if (this._selectedMarker) {
			this._googleMaps_Core.event.trigger(this._selectedMarker, 'click');
		}
	}
	/*
	_calculatePopupPosition(markerObject, popup_dom_id) {
		let borderMargin = 10;
		
		let gmaps_container = {
			width: document.getElementById("gmaps-container").offsetWidth,
			height: document.getElementById("gmaps-container").offsetHeight
		}
		let marker_popup = {
			width: document.getElementById(popup_dom_id) ? document.getElementById(popup_dom_id).offsetWidth : 400,
			height: document.getElementById(popup_dom_id) ? document.getElementById(popup_dom_id).offsetHeight : 400
		}
		
		let mirror_x = markerObject.position_xy.x > (gmaps_container.width / 1.6);
		let mirror_y = markerObject.position_xy.y > (gmaps_container.height / 1.6);
		
		let pos = {
			x: mirror_x ? markerObject.position_xy.x - 35 : markerObject.position_xy.x + 35,
			y: mirror_y ? markerObject.position_xy.y : markerObject.position_xy.y - 70
		}
		
		let left = 0;
		let top = 0;
		
		if (mirror_x) {
			left = (pos.x > gmaps_container.width ? gmaps_container.width - marker_popup.width - borderMargin : pos.x - marker_popup.width - borderMargin);
		} else {
			left = (pos.x < borderMargin ? borderMargin : pos.x);
		}
		if (mirror_y) {
			top = (pos.y > gmaps_container.height ? gmaps_container.height - marker_popup.height - borderMargin : pos.y - marker_popup.height - borderMargin);
		} else {
			top = (pos.y < borderMargin ? borderMargin : pos.y);
		}
		
		return {
			x: left,
			y: top
		}
	}
	*/
	_calculatePopupPosition(markerObject, popup_dom_id) {
		let borderMargin = 10;
		
		let gmaps_container = {
			width: document.getElementById("gmaps-container").offsetWidth,
			height: document.getElementById("gmaps-container").offsetHeight
		}
		let marker_popup = {
			width: document.getElementById(popup_dom_id) ? document.getElementById(popup_dom_id).offsetWidth : 400,
			height: document.getElementById(popup_dom_id) ? document.getElementById(popup_dom_id).offsetHeight : 400
		}
		
	//	let mirror_x = markerObject.position_xy.x > (gmaps_container.width / 1.6);
	//	let mirror_y = markerObject.position_xy.y > (gmaps_container.height / 1.6);
		let mirror_x = false;
		let mirror_y = false;
		
		let left = 0;
		let top = 0;
		
	//	let pos = {
	//		x: mirror_x ? markerObject.position_xy.x - 35 : markerObject.position_xy.x + 35,
	//		y: mirror_y ? markerObject.position_xy.y : markerObject.position_xy.y - 70
	//	}
		let pos = {
			x: markerObject.position_xy.x - (marker_popup.width / 2),
			y: markerObject.position_xy.y - marker_popup.height - 60
		}
		
		left = (pos.x < borderMargin ? borderMargin : (pos.x + marker_popup.width + borderMargin > gmaps_container.width ? gmaps_container.width - marker_popup.width - borderMargin : pos.x));
		
		if (mirror_y) {
			top = (pos.y > gmaps_container.height ? gmaps_container.height - marker_popup.height - borderMargin : pos.y - marker_popup.height - borderMargin);
		} else {
			top = (pos.y < borderMargin ? borderMargin : (pos.y + marker_popup.height + borderMargin > gmaps_container.height ? gmaps_container.height - marker_popup.height - borderMargin : pos.y));
		}
		
		return {
			x: left,
			y: top
		}
	}
	
	setSelectedMarker(googleMaps, map, marker) {
		if (this._selectedMarker) {
			this._selectedMarker.setAnimation(null);
			this._selectedMarker.setIcon(this._iconsSet[this._selectedMarker.defaultIcon]);
			this._selectedMarker.setZIndex(0);
			this._selectedMarker = null;
		}
	//	marker.setIcon(this._iconsSet.selected);
		marker.setZIndex(1000);
		marker.setIcon(this._iconsSet[marker._current_icon + '_selected']);
		if (marker.animation === undefined || marker.animation == true) {
			marker.setAnimation(googleMaps.Animation.BOUNCE);
		}
		if (this.props.centerToSelectedMarker) {
			this.setState((prev_state) => {
				if (prev_state.selectedMarker) {
					prev_state.selectedMarker.hide = true
				}
				return prev_state;
			})
			if (this.props.onPanningToMarkerStart) {
				this.props.onPanningToMarkerStart();
			}
			map.panTo(marker.position);
			this._panningToMarker = marker;
		} else {
			marker.position_xy = this.getMarkerPositionXY(marker);
			this._selectedMarker = marker;
			this.setState({
				selectedMarker: marker
			})
			if (this.props.onMarkerSelected) {
				this.props.onMarkerSelected(googleMaps, map, marker);
			}
		}
	}
	
	/////////////////////////
	//	Lifecycle methods
	//
	
	onAppMounted() {
	}
	
	onComponentDidMount() {
		
	}
	
	onComponentWillUnmount() {
	}
	
	onRender() {
		return (
			<ReactGoogleMapLoader
				params={{
					key: this.API_KEY, // Define your api key here
					libraries: "places,geometry", // To request multiple libraries, separate them with a comma
				}}
				render={(googleMaps) => {
					
					if (googleMaps) {
						this._iconsSet = {};
						if (this.props.iconsSet) {
							Object.keys(this.props.iconsSet).forEach((_C_icon, _C_idx) => {
								this._iconsSet[_C_icon] = {
									url: this.props.iconsSet[_C_icon].svg,
									...(this.props.iconsSet[_C_icon].size ? {scaledSize: new googleMaps.Size(this.props.iconsSet[_C_icon].size[0], this.props.iconsSet[_C_icon].size[1])} : {})
								}
								this._iconsSet[_C_icon + '_selected'] = {
									url: this.props.iconsSet[_C_icon].svg,
									...(this.props.iconsSet[_C_icon].size ? {scaledSize: new googleMaps.Size(this.props.iconsSet[_C_icon].size[0] * 1.3, this.props.iconsSet[_C_icon].size[1] * 1.3)} : {})
								}
							});
							 
						}
					
						return (
							<div id="gmaps-container" className=""
								style={{
									height: this.props.containerHeight ? this.props.containerHeight + 'px' : '500px',
									position: 'relative'
								}}
							>
							
								{this.state.selectedMarker && this.props.markerPopupRender ? (() => {
									let popup_position = this._calculatePopupPosition(this.state.selectedMarker, 'marker-popup');
									return (
										<div
											id="marker-popup"
											style={{
												position: 'absolute',
												left: popup_position.x + 'px',
												top: popup_position.y + 'px',
												zIndex: '1000'
											}}
										>
											{this.props.markerPopupRender(this.state.selectedMarker)}
										</div>
									);
								})() : null}
								
								{this.state.overMarker && this.props.markerOverPopupRender ? (() => {
									let popup_position = this._calculatePopupPosition(this.state.overMarker, 'marker-over-popup');
									return (
										<div
											id="marker-over-popup"
											style={{
												position: 'absolute',
												left: popup_position.x + 'px',
												top: popup_position.y + 'px',
												zIndex: '1000'
											}}
										>
											{this.props.markerOverPopupRender(this.state.overMarker)}
										</div>
									);
								})() : null}
								
								
								<GoogleMap
									autoFitBounds={true}
									boundsOffset={0.004}
									googleMaps={googleMaps}
									styles={this.MAP_STYLE}
									coordinates={this.props.markers.map((_C_marker, _C_idx) => {
										_C_marker.onLoaded = (googleMaps, map, marker) => {
											marker.setIcon(this._iconsSet.default);
											marker.setCustomIcon = (icon) => {
												marker.setIcon(this._iconsSet[icon]);
												marker._current_icon = icon;
											}
											googleMaps.event.addListener(marker, "click", (e) => {
												this.setSelectedMarker(googleMaps, map, marker);
												
										//		if (this._selectedMarker) {
										//			this._selectedMarker.setIcon(this.icon);
										//		}
										//		map.panTo(marker.position);
										//		marker.setIcon(this.iconHover);
										//	//	marker.posXY = this.overlayView.getProjection().fromLatLngToContainerPixel(marker.position);
										//		this._selectedMarker = marker;
												if (_C_marker.onClick) {
													_C_marker.onClick(googleMaps, map, marker);
												
												}
											})
											googleMaps.event.addListener(marker, "mouseover", (e) => {
												marker._prev_z_index = marker.getZIndex();
												marker.setZIndex(10000);
												marker.position_xy = this.getMarkerPositionXY(marker);
												this.setState({
													overMarker: marker
												})
											})
											googleMaps.event.addListener(marker, "mouseout", (e) => {
												marker.setZIndex(marker._prev_z_index);
												this.setState({
													overMarker: null
												})
											})
											this.setSelectedMarker(googleMaps, map, marker);
											
											if (_C_marker.onLoad) {
												_C_marker.onLoad(googleMaps, map, marker);
											
											}
										//	this.setSelectedMarker(marker);
										}
										return _C_marker;
									})}
									center={{lat: 44.975644, lng: 7.714183}}
									zoom={30}
									streetViewControl={true}
									mapTypeControl={true}
									fullscreenControl={true}
									fullscreenControlOptions={{
										position: googleMaps.ControlPosition.RIGHT_TOP
									}}
									onLoaded={(googleMaps, map) => {
										
										this._googleMaps_Core = googleMaps;
										this._googleMaps_Map = map;
										
										this.overlayView = new googleMaps.OverlayView();
										this.overlayView.draw = function() {};
										this.overlayView.setMap(map);
										
										map.addListener("click", (e) => {
											if (this._selectedMarker && !this._selectedMarker.keepSelected) {
												this._selectedMarker.setAnimation(null);
												this._selectedMarker.setIcon(this._iconsSet[this._selectedMarker.defaultIcon]);
												this._selectedMarker = null;
												this.setState({
													selectedMarker: null
												})
												if (this.props.onMarkerDeselected) {
													this.props.onMarkerDeselected();
												}
											}
										})
										
										map.addListener("drag", (e) => {
											this.updateMarkerPopupPositionXY();	
										})
										map.addListener("dragstart", (e) => {
											this._draggingMap = true;
										})
										map.addListener("dragend", (e) => {
											this.updateMarkerPopupPositionXY();
										})
										map.addListener("mousemove", (e) => {
											this.updateMarkerPopupPositionXY();
										})
										map.addListener("idle", (e) => {
											if (this._draggingMap) {
												this.updateMarkerPopupPositionXY();
											}
											if (this._panningToMarker) {
												this.setState((prev_state) => {
													if (prev_state.selectedMarker) {
														prev_state.selectedMarker.hide = false
													}
													return prev_state;
												})
												if (this.props.onPanningToMarkerEnd) {
													this.props.onPanningToMarkerEnd();
												}
												this._panningToMarker.position_xy = this.getMarkerPositionXY(this._panningToMarker);
												this._selectedMarker = this._panningToMarker;
												this.setState({
													selectedMarker: this._panningToMarker
												})
												if (this.props.onMarkerSelected) {
													this.props.onMarkerSelected(googleMaps, map, this._panningToMarker);
												}
												this._panningToMarker = null;
											}
										})
										
										if (this.props.onControllerReady) {
											this.props.onControllerReady(this.getContext().GoogleMaps);
										}
										
										
										
										
										
										
										
								//		map.setMapTypeId(googleMaps.MapTypeId.ROADMAP)
								//		this.icon.scaledSize = new googleMaps.Size(60, 60);
								//		this.iconHover.scaledSize = new googleMaps.Size(70, 70);
								//		this.props.onLoaded(googleMaps, map, this.markersObj);
								//		googleMaps.event.addListener(map, "click", (e) => {
								//			if (this.currentVisibleIW) {
								//				this.currentVisibleIW.close();
								//			}
								//			if (this.currentVisibleMarker) {
								//				this.currentVisibleMarker.setIcon(this.icon);
								//				this.props.onSelectedMarkerChange(null);
								//			}
								//		})
									}}
								/>
							</div>
						);
					} else {
						return null;
					}
				}}
			/>
		);
	}
}

export default contextProvider(
	withDefinedContext(GoogleMaps, 'GoogleMaps', context_exposed_methods),
	{
		define: null
	}
);
