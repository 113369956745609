import React from 'react';
import contextProvider from '../../contextProvider';

import _ReactOBaseComponent from '../../_L0_components/_ReactOBaseComponent';

import { Parallax } from 'react-parallax';

//import '../.._L1_styles/Layout/section.scss';

class SectionNEW extends _ReactOBaseComponent {
	constructor(props) {
		super(props);
		this.state = {}
	}
	
	onComponentDidMount() {
	//	console.log("DEBUG :: SectionNEW -> onComponentDidMount");
	}
	
	parseImagePath(img_path) {
		if ( /^imgs\./.test(img_path) ) {
			return this.getAppContext().getImagesStoreService().get(img_path);
		} else {
			return img_path;
		}
	}
	
	onRender() {
		let _elemPerRow = this.props.elementsPerRow ? this.props.elementsPerRow : 3;
		let _elems = React.Children.toArray(this.props.children).filter((value) => {return value});
		let _elemCount = _elems.length;
		if (this.props.layoutManager == "grid" && this.props.gridSpacing) {
			for (var i = 0; i < ((_elemCount % _elemPerRow) > 0 ? (_elemPerRow - (_elemCount % _elemPerRow)) : 0); i++) {
				_elems.push('_FAKE_');
			}
		}
	//	let _calculatedGridSpacing = this.props.gridSpacing ? (this.props.gridSpacing > 1 ? (this.props.gridSpacing / 2) : this.props.gridSpacing) : null;
		
		let _C2 = -1;
		let _mainSection = _elems.map((_C_child, _C_idx) => {
			_C2 = _C2 + (_C_child ? 1 : 0);
			let _isFirstRow = _C2 < _elemPerRow;
			let _isLastRow = (_C2 + 1) > (_elemCount - ((_elemCount % _elemPerRow) == 0 ? _elemPerRow : (_elemCount % _elemPerRow))) || _elemCount <= _elemPerRow;
			let _isFirstCol = (_C2 % _elemPerRow) == 0;
			let _isLastCol = (_C2 % _elemPerRow) == (_elemPerRow - 1);
			return _C_child ? [
				...(/grid|columns/.test(this.props.layoutManager) ? [(
					<div key={_C_idx}
						className='col'
					>
							{_C_child != '_FAKE_' ? React.cloneElement(_C_child, {
								..._C_child.props
							}) : null}
					</div>
				)] : [
					_C_child != '_FAKE_' ? React.cloneElement(_C_child, {
						..._C_child.props
					}) : null
				]),
				...(this.props.gridSpacing && !_isLastCol ? [(
					<div key={_C_idx + '.1'}
						className={"aaaa " + _C2}
						style={{
							width: this.props.gridSpacing + 'px',
							backgroundColor: _C_child != '_FAKE_' && !(_elemCount < _elemPerRow && _C2 + 1 == _elemCount) && this.props.gridSpacingColor ? this.props.gridSpacingColor : 'transparent'
						}}
					/>
				)] : []),
				...(_isLastCol && !_isLastRow ? [(
					<div key={_C_idx + '.2'}
						className="w-100"
						style={{
							height: this.props.gridSpacing + 'px',
							backgroundColor: this.props.gridSpacingColor ? this.props.gridSpacingColor : 'transparent'
						}}
					/>
				)] : [])
			] : null;
		});
		
		if (this.props.layoutManager == "columns" || this.props.layoutManager == "grid") {
			_mainSection = (
				<div className="row no-gutters">
					{_mainSection}
				</div>
			);
		}
		
		console.log(_mainSection);
		
		return this.props.backgroundStyle == 'parallax' ? (
			<Parallax
				className={"section " + (this.props.className || '')}
				style={{
					height: this.props.height ? this.props.height + 'px' : 'auto',
					...(this.props.designType != "wide" ? {maxWidth: '1280px', margin: '0 auto'} : {}),
					...(this.props.style || {})
				}}
				blur={this.props.parallaxBlur || 0}
				bgImage={this.parseImagePath(this.props.backgroundImage)}
				bgImageAlt="the cat"
			//	bgWidth="auto"
			//	bgHeight="100%"
				strength={this.props.parallaxStrenght || 200}
			>
				{_mainSection}
			</Parallax>
		) : (
			<section
				id={this.props.id || ""}
				className={"section " + (this.props.className || '') + (this.props.backgroundImage ? ' bg-cover' : '') + (this.props.backgroundStyle == 'fixed' ? ' -fixed' : '')}
				style={{
					height: this.props.height ? this.props.height + 'px' : 'auto',
					...(this.props.designType != "wide" ? {maxWidth: '1280px', margin: '0 auto'} : {}),
					...(this.props.backgroundImage ? {background: (this.props.backgroundColor ? (this.props.backgroundColor + " ") : "") + "url(" + this.parseImagePath(this.props.backgroundImage) + ") no-repeat " + (this.props.backgroundHAlign || "center") + " " + (this.props.backgroundVAlign || "top")} : {}),
					...(this.props.backgroundColor ? {backgroundColor: this.props.backgroundColor} : {}),
					...(this.props.style || {})
				}}
				onClick={this.props.onClick}
			>
				{_mainSection}
			</section>
		);
	}
}

export default contextProvider(
	SectionNEW,
	{
		define: null,
		retrieve: null
	}
);
