import React from 'react';
import contextProvider from '../../contextProvider';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import _ReactOBaseComponent, { withDefinedContext } from '../../_L0_components/_ReactOBaseComponent';
import { _LoadingWrapper } from '../../_L0_components/Widgets/_LoadingKit';

import ReactOButton from '../../_L1_components/Elements/ReactOButton';

const context_exposed_methods = [
	"open",
	"close",
	"toggle",
	"getData"
];

class ReactOModal extends _ReactOBaseComponent {
	constructor(props) {
		var initState = {
			data: {},
			opened: false
		};
		super(props, initState);
		
		this.toggle = this.toggle.bind(this);
		this.open = this.open.bind(this);
		
		if (this.props.onControllerReady) {
			this.props.onControllerReady(this.getContext().ReactOModal);
		}
	}
	
	
	//
	//	Lifecycle methods
	//
	
	onAppMounted() {
	}
	
	onComponentDidMount() {
	//	this.context._LayoutPage._registerModal({
	//		id: this.props.modalId,
	//		toggle: this.toggle,
	//		open: this.open,
	//		close: this.close
	//	});
		this.definePromptingFunction({
			name: 'close',
			func: () => {
				this.setState({
					opened: false
				});
			}
		})
	}
	
	onComponentWillUnmount() {
	}
	
	onGetChildContext() {
		return {
			opened: this.state.opened
		};
	}
	
	toggle(data) {
		if (this.state.opened) {
			this.close();
		} else {
			this.open(data);
		}
	}
	
	open(data) {
		this.setState({
			opened: true,
			data: data
		});
	}
	
	getData() {
		return this.state.data;
	}
	
	onRender() {
		return (
			<div>
				<Modal
					centered={this.props.centered}
					isOpen={this.state.opened}
					toggle={this.toggle}
					size={this.props.size}
					className={this.props.centered ? 'modal-dialog-centered' : ''}
				//	backdrop={!this.hasPrompts() || 'static'}
					modalTransition={{ timeout: 200 }}
					backdropTransition={{ timeout: 1 }}
					onOpened={() => {
						if (this.props.onModalMounted) this.props.onModalMounted(this.state.data)
					}}
					onClosed={() => {
						if (this.props.onModalUnmounted) this.props.onModalUnmounted(this.state.data)
					}}
				>
					<_LoadingWrapper
						loadingPlug={this.props.loading}
					>
						{this.props.header ? (
							<ModalHeader toggle={this.toggle}>
								{typeof this.props.header == "function" ? this.props.header(this.state.data) : this.props.header}
							</ModalHeader>
						) : null}
						{this.props.body ? (
							<ModalBody className={(this.props.bodyClassName ? this.props.bodyClassName : '') + (!this.props.actions ? ' no-footer' : '')}>
								{typeof this.props.body == "function" ? this.props.body(this.state.data) : this.props.body}
							</ModalBody>
						) : null}
						{this.props.actions ? (
							<ModalFooter>
									{(typeof this.props.actions == 'function' ? this.props.actions(this.state.data) : this.props.actions).map((_C_action, _C_idx) => {
										if (typeof _C_action == "function") {
											_C_action = _C_action(this.state.data);
										}
										if (!_C_action.action) {
											return null;
										} else {
											return typeof _C_action.action == "string" ? (
												_C_action.action == 'close' ? (
													<ReactOButton
														key={_C_idx}
														color={_C_action.color}
														icon={_C_action.icon}
														onClick={this.close}
													>
														{_C_action.text}
													</ReactOButton>
												) : null
											) : (
												<ReactOButton
													key={_C_idx}
													color={_C_action.color}
													icon={_C_action.icon}
													disabled={_C_action.disabled}
													onClick={() => {_C_action.action(this.state.data)}}
												>
													{_C_action.text}
												</ReactOButton>
											)
										}
									})}
							</ModalFooter>
						) : null}
					</_LoadingWrapper>
				</Modal>
			</div>
		);
	}
}

export default contextProvider(
	withDefinedContext(ReactOModal, 'ReactOModal', context_exposed_methods, true),
	{
		retrieve: ['_LayoutPage']
	}
);

