const _registerAction = Symbol('_registerAction');
const _unregisterShelf = Symbol('_unregisterShelf');

class _ActionsShelfService {
	constructor(props) {
		this.props = props;
		this._SHELFS = {};
		this._REGISTERED_ACTIONS = {};
		this.TEST = {}
	}
	
	_makeid() {
		var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		for (var i = 0; i < 5; i++)
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		return text;
	}
	
	createNewShelf(shelf_name) {
		this._SHELFS[shelf_name] = {
			id: shelf_name,
			registerAction: (action) => { this[_registerAction](shelf_name, action); },
			unregister: () => { this[_unregisterShelf](shelf_name); }
		};
		return this._SHELFS[shelf_name];
	}
	
	[_registerAction](shelf_id, action) {
		this._SHELFS[shelf_id][action.key] = action.call;
	}
	
	[_unregisterShelf](shelf_id) {
		delete(this._SHELFS[shelf_id]);
	}
	
	do(shelf_id, action_id, action_attrs) {
		if (this._SHELFS[shelf_id] && this._SHELFS[shelf_id][action_id] && this._SHELFS[shelf_id][action_id].call) {
			this._SHELFS[shelf_id][action_id](action_attrs);
		} else {
			console.warn("Action not found");
		}
	}
	
	getAction() {
		
	}
}

export default _ActionsShelfService;