import React from 'react';

import _ReactOBaseComponent from '../../_L0_components/_ReactOBaseComponent';

class ReactOTextCell extends _ReactOBaseComponent {
	constructor(props) {
		var initState = {
		};
		super(props, initState);
	}
	
	/////////////////////////
	//	Lifecycle methods
	//
	
	onComponentDidMount() {
		
	}
	
	onComponentWillUnmount() {
		
	}
	
	onGetChildContext() {
		return {};
	}
	
	onRender() {
		return (
			<span>
				<span
					style={{
						position: 'absolute'
					}}
				>
					
				</span>
				{this.props.from_texts_store ? (
					<span dangerouslySetInnerHTML={{__html: ((this.getAppContext().getTextsStoreService().get(this.props.from_texts_store)) || "")}}/>
				) : this.props.text ? (
					this.props.text
				) : this.props.children ? (
					this.props.children
				) : null}
			</span>
		)
	}
}

export default ReactOTextCell;