import React from 'react';
import contextProvider from '../../contextProvider';

class HeaderToggler extends React.Component {

	constructor(props) {
		super(props);
		this.state = {}
		
	}
	
	componentDidMount() {
	//	console.log("DEBUG :: HeaderToggler -> componentDidMount");
	}
	
	render() {
		
		/*	<button className={"navbar-toggler" + (this.props._layoutHeader.state.opened ? " opened" : "")} onClick={() => {   this.props._layoutHeader._toggle()   }}>
				{React.Children.map(this.props.children, (_C_child, _C_idx) => {
					return React.cloneElement(_C_child, { _layoutHeader: this });
				})}
			</button>*/
		return (
			<button className={(this.props.className || '') + " navbar-toggler"} onClick={() => {   this.context._LayoutHeader.toggle()   }}>
				{React.Children.map(this.props.children, (_C_child, _C_idx) => {
					return React.cloneElement(_C_child, {  });
				})}
			</button>
		);
	}
}

export default contextProvider(
	HeaderToggler,
	{
		retrieve: ['_LayoutHeader']
	}
);