import React from 'react';
import contextProvider from '../../contextProvider';

import _ReactOBaseComponent from '../../_L0_components/_ReactOBaseComponent';

class Panel extends _ReactOBaseComponent {
	constructor(props) {
		super(props);
		this.state = {}
		this._EXTENDER = "Panel";
	}
	
	onComponentDidMount() {
	//	console.log("DEBUG :: Section -> onComponentDidMount");
	}
	
	onRender() {
		return (
			<div id={this.props.id} className={"border border-gray200 " + (this.props.bgColor ? this.props.bgColor : "bg-light") + (this.props.rounded ? ' rounded' : '') + (this.props.className ? ' ' + this.props.className : '')} style={this.props.style}>
				{this.props.header ? (
					<div className={'px-3 pt-3 pb-2 border-bottom border-3 border-primary rounded-top' + (typeof this.props.header == 'string' ? ' text-uppercase' : '')}>
						{typeof this.props.header == 'function' ? this.props.header() : (<strong>{this.props.header}</strong>)}
					</div>
				) : null}
				<div className={this.props.bodyClassName ? this.props.bodyClassName : 'p-3'}>{this.props.children}</div>
				{this.props.footer ? (
					<div className={typeof this.props.footer == 'string' ? 'text-uppercase font-weight-bold bg-primary py-2 px-3 rounded-top' : ''}>{this.props.footer}</div>
				) : null}
			</div>
		);
	}
}

export default contextProvider(
	Panel,
	{
		define: null,
		retrieve: null
	}
);
