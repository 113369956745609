import React from 'react';
import contextProvider from '../../contextProvider';

class HeaderBrand extends React.Component {

	constructor(props) {
		super(props);
		this.state = {}
		
	}
	
	componentDidMount() {
	}
	
	render() {
		// border-right border-gray300
		return (
			<div className="brand text-center align-self-stretch d-flex justify-content-center" style={{width: (this.props.width ? (this.props.width) + 'px' : 'auto')}}>
				<a className="align-self-center" href={this.props.link || '/'}>
					{this.props.type == 'image' ? (
						<img src={this.props.image} style={{
						//	maxHeight: ((this.context._LayoutHeader.height / 100) * this.props.height) + 'px'
							...(this.props.height ? {maxHeight: (this.props.height) + 'px'} : {}),
							...(this.props.width ? {maxWidth: (this.props.width) + 'px'} : {})
						}} alt="logo" />
					) : (
						<span>{this.props.text}</span>
					)}
				</a>
			</div>
		);
	}
}

export default contextProvider(
	HeaderBrand,
	{
		retrieve: ['_LayoutHeader']
	}
);