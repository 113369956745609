import React from 'react';
import ResizeDetector from 'react-resize-detector';
import contextProvider from '../../contextProvider';

import _ReactOBaseComponent, { withDefinedContext } from '../_ReactOBaseComponent';

const context_exposed_methods = [
	'toggle', 
	'close', 
	'open',
	'isOpened'
];

class _LayoutHeader extends _ReactOBaseComponent {
	constructor(props) {
		super(props);
		this.width = 0;
		this.height = 0;
		this.state = {
			opened: false
		}
	}
	
	/////////////////////////
	//	Lifecycle methods
	//
	
	onAppMounted() {
		this.getAppContext().router.history.listen(() =>{
			if (this.state.opened) { this.close() }
		});
	}
	
	onComponentDidMount() {
		this.ActionsShelfService = this.getAppContext().getActionsShelfService();
		this._actionShelf = this.ActionsShelfService.createNewShelf('_LayoutHeader');
		this._actionShelf.registerAction({
			key: 'toggle_header',
			call: this.toggle
		});
	}
	
	onComponentWillUnmount() {
		this.width = 0;
		this.height = 0;
		this._notifyOccupationToDocker();
	}
	
	onGetChildContext() {
		return {
			height: this.height,
			state: this.state
		};
	}
	
	_notifyOccupationToDocker() {
		this.context._LayoutPage._updateBoundaries({
			top: this.height
		});
	}

	toggle() {
		this.setState((prev_state) => {
			prev_state.opened = !prev_state.opened;
			return prev_state;
		}, () => {
			this._notifyOccupationToDocker();
		});
	}
	
	close() {
		this.setState({
			opened: false
		}, () => {
			this._notifyOccupationToDocker();
		});
	}
	
	open() {
		this.setState({
			opened: true
		}, () => {
			this._notifyOccupationToDocker();
		});
	}
	
	isOpened() {
		return this.state.opened;
	}
	
	_updateGeometry(width, height) {
		this.width = width;
		this.height = height;
		this._notifyOccupationToDocker();
	}
	
	onRender() {
		return this.props.visible === undefined || this.props.visible ? (
			<div
				className={"_layout-header shadow-sm border-bottom border-gray300" + (this.props.collapsed ? "collapsed" : "")}
				style={{
					position: this.props.positionFixed ? 'fixed' : 'absolute',
				}}
			>
				<ResizeDetector handleWidth handleHeight onResize={(width, height, el) => {
					this._updateGeometry(width, height);
				}}/>
				<div className="-wrapper">
					{React.Children.map(this.props.children, (_C_child, _C_idx) => {
						return _C_child ? React.cloneElement(_C_child, { inViewport: this.state.waypoint }) : null;
					})}
				</div>
			</div>
		) : '';
	}
}

export default contextProvider(
	withDefinedContext(_LayoutHeader, '_LayoutHeader', context_exposed_methods),
	{
		retrieve: ['_LayoutPage']
	}
);

