import React from 'react';
import contextProvider from '../../contextProvider';

import _ReactOBaseComponent from '../../_L0_components/_ReactOBaseComponent';

import { Button } from 'reactstrap';

class _ReactOButton extends _ReactOBaseComponent {
	constructor(props) {
		super(props);
		this.state = {}
	}
	
	
	//
	//	Lifecycle methods
	//
	
	onAppMounted() {
	}
	
	onComponentDidMount() {
	}
	
	onComponentWillUnmount() {
	}
	
	onRender(base_component_props) {
		let icon_elem = this.props.icon ? (
			<span className={"icon-" + this.props.icon}
				style={{
					...(this.props.children ? (this.props.iconRight ? {marginLeft: '8px'} : {marginRight: '8px'}) : {})
				}}
			/>
		) : null;
		return (
			<Button
				{...base_component_props}
				type={this.props.type}
				tag={this.props.tag}
				size={this.props.size}
				color={this.props.color}
				outline={this.props.outline || this.props.text}
				active={this.props.active}
				disabled={this.props.disabled}
				block={this.props.block}
				href={this.props.href || null}
				className={"" +
					(this.props.className ? this.props.className + " " : "") + 
					(this.props.text ? "btn-rO-text" : "") + 
					(!this.props.straight && this.props.round ? " round" : "") +
					(this.props.straight ? " straight" : "") +
					(!this.props.focus ? " nofocus" : "")// +
				//	(!this.props.children ? " btn-rO-circle" : "")
				}
				onClick={(e) => {if (this.props.preventDefault) {e.preventDefault(); e.stopPropagation(); e.nativeEvent.stopImmediatePropagation();} if (this.props.onClick) {this.props.onClick(e)}}}
				style={this.props.style}
			>
				{!this.props.iconRight ? icon_elem : null}{!this.props.circle ? this.props.children : null}{this.props.iconRight ? icon_elem : null}
			</Button>
		);
	}
}

export default contextProvider(
	_ReactOButton,
	{
		retrieve: []
	}
);


